<template>
  <a-layout-content id="min-content">
    在里面创建一个A4纸比例大小的容器, 添加导出内容为word
  </a-layout-content>
  <a-layout-sider id="min-sider">
    这里能编辑word内容
  </a-layout-sider>
</template>

<script setup>

</script>

<script>
export default {
  name: "Minimalist"
}
</script>
<style scoped>
@import url('@/assets/css/format.css');

#min-content {
  flex: 3 !important;
  max-width: none !important;
  line-height: 30px;
  text-align: center;
  color: #333333;
}

#min-sider {
  flex: 2 !important;
  max-width: none !important;
  text-align: center;
  color: #333333;
  background-color: #ffffff;
  display: none;
}
</style>