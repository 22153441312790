<template>
  没有模板
</template>

<script setup>
import {inject} from "vue";

const RMenuCurrent = inject('RMenuCurrent');

RMenuCurrent.value = ['templates'];
</script>

<script>
export default {
  name: "Template"
}
</script>
<style scoped>

</style>