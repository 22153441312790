<template>
  <div class="help-main">
    <!-- 侧边栏目录 -->
    <div class="help-sidebar">
      <a-anchor
          :items="[
      {
        key: '1',
        href: '#developer-introduction',
        title: '开发者序',
      },
      {
        key: '2',
        href: '#function-introduction',
        title: '菜单介绍',
      },
      {
        key: '3',
        href: '#firstStart',
        title: '初次使用'
      },
      {
        key: '4',
        href: '#onlineResume',
        title: '在线简历'
      },
      {
        key: '5',
        href: '#advancedTutorial',
        title: '进阶教程'
      },
    ]"
          :offsetTop="120"
      ></a-anchor>
    </div>
    <!-- 内容 -->
    <div class="help-content">
      <a-typography-title id="developer-introduction" :level="3" class="help-title">
        <span>开发者序</span>
        <a class="help-links" href="#developer-introduction">#</a>
      </a-typography-title>
      <a-typography-text class="help-info">
        感谢使用，这是钟意的毕设项目，若使用途中碰到问题，可以联系我邮件：thatcoder@163.com
      </a-typography-text>
      <a-typography-title id="function-introduction" :level="3" class="help-title">
        <span>菜单介绍</span>
        <a class="help-links" href="#function-introduction">#</a>
      </a-typography-title>
      <a-list :data-source="functionIntroduction" class="help-list" item-layout="horizontal">
        <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta>
            <template #title>
              {{item.title}}
            </template>
            <template #avatar>
              <a-button :icon="item.icon" shape="circle" type="text" />
            </template>
            <template #description>
              {{item.description}}
            </template>
          </a-list-item-meta>
        </a-list-item>
        </template>
      </a-list>
      <a-typography-title id="firstStart" :level="3" class="help-title">
        <span>初次使用</span>
        <a class="help-links" href="#firstStart">#</a>
      </a-typography-title>
      <a-list :data-source="firstStartIntroduction" class="help-list" item-layout="horizontal">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                {{item}}
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
      <a-typography-title id="onlineResume" :level="3" class="help-title">
        <span>在线简历</span>
        <a class="help-links" href="#onlineResume">#</a>
      </a-typography-title>
      <a-list :data-source="onlineResumeIntroduction" class="help-list" item-layout="horizontal">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                {{item}}
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
      <a-typography-title id="advancedTutorial" :level="3" class="help-title">
        <span>进阶教程</span>
        <a class="help-links" href="#advancedTutorial">#</a>
      </a-typography-title>
      <a-typography-text class="help-info">
        待更新...
      </a-typography-text>
    </div>
  </div>
</template>
<script setup>

import {h, inject, onMounted, ref} from "vue";
import {ContactsTwoTone} from "@ant-design/icons-vue";
const RMenuCurrent = inject('RMenuCurrent');
RMenuCurrent.value = ['help'];

const targetOffset = ref(undefined);

const functionIntroduction = ref([
  {
    title: '简历档案',
    icon: h(ContactsTwoTone),
    description: '简历档案功能，可以上传自己的简历，并展示在个人中心页面。'
  },
  {
    title: '简历文件',
    icon: h(ContactsTwoTone),
    description: '简历文件功能，可以上传自己的简历文件，并展示在个人中心页面。'
  },
  {
    title: '写作模式',
    icon: h(ContactsTwoTone),
    description: '在线编辑功能，可以编辑自己的简历，并实时预览效果。'
  },
  {
    title: '模板工坊',
    icon: h(ContactsTwoTone),
    description: '模板工坊功能，可以选择不同的模板，快速生成简历。'
  },
  {
    title: '求职交流',
    icon: h(ContactsTwoTone),
    description: '求职交流功能，可以与其他用户进行交流，分享自己的工作经验。'
  }
]);

const firstStartIntroduction = ref([
  "step1: 首先在简历档案创建一份你的档案。",
  "step2: 在模板工坊选择模板，点击制作。",
  "step3: 点击制作后会提示选择一个档案，选择刚才创建的档案。",
  "step4: 稍等片刻后即可在简历文件中看到你创建的简历。"
])

const onlineResumeIntroduction = ref([
  "功能介绍：在线简历指不需要制作简历文件，直接在线预览或分享简历，并实时更新。",
  "如何预览：创建完简历档案后，在档案的卡片上选择更多功能，选择预览简历即可。",
  "高级设置：在档案的卡片上选择更多功能，选择高级功能可以对在线简历权限进行设置。"
])

onMounted(() => {
  targetOffset.value = window.innerHeight / 2;
});
</script>
<script>
export default {
  name: 'Help',
}
</script>
<style scoped>

.help-main {
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
}

.help-sidebar {
  padding: 20px;
}

.help-content {
  padding: 20px;
}

.help-title{
  margin-top: 20px;
}
.help-title a {
  display: none;
}

.help-title:hover a {
  display: inline;
}

.help-info {
  font-family: LXGW WenKai Mono Lite, LXGW, "LXGW", system-ui, "Microsoft Yahei" !important;
}
.help-links {
  margin-left: 10px;
}
</style>