import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import Minimalist from "@/views/mode/Minimalist.vue";
import Folder from "@/views/mine/Folder.vue";
import Template from "@/views/mine/Template.vue";
import UserInfo from "@/views/mine/UserInfo.vue";
import Templates from "@/views/more/Templates.vue";
import Welcome from "@/views/welcome.vue";
import Edit from "@/views/mode/Edit.vue";
import StartSky from "@/views/online/StartSky.vue";
import Mine from "@/views/mine/Index.vue"
import Discuss from "@/views/mode/Discuss.vue"
import Help from "@/views/more/Help.vue"

const componentImport = async (view) => {
    return await view
}

const routes = [
    {
        path: '/',
        name: 'welcome',
        title: '欢迎使用',
        component: Welcome
    },
    {
        path: '/mine',
        name: 'mine',
        component: HomeView,
        children: [
            {
                path: 'index',
                name: 'mine',
                title: '个人信息',
                component: Mine
            },
            {
                path: 'user',
                name: 'userInfo',
                title: '简历信息',
                component: UserInfo
            },
            {
                path: 'folder',
                name: 'folder',
                title: '简历文件',
                component: Folder
            },
            {
                path: 'template',
                name: 'template',
                title: '我的模板',
                component: Template
            }
        ]
    },
    {
        path: '/mode',
        name: 'mode',
        component: HomeView,
        children: [
            {
                path: 'templates',
                name: 'templates',
                title: '模板工坊',
                component: Templates
            },
            {
                path: 'minimalist',
                name: 'minimalist',
                title: '匿名模式',
                component: Minimalist
            },
            {
                path: 'edit',
                name: 'edit',
                title: '写作模式',
                component: Edit
            },
            {
                path: 'discuss',
                name: 'discuss',
                title: '求职讨论',
                component: Discuss
            },
        ]
    },
    {
        path: '/online/:id',
        name: 'online',
        component: StartSky
    },
    {
        path: '/:user',
        name: 'vip',
        component: StartSky
    },
    {
        path: '/more',
        name:'more',
        component: HomeView,
        children: [
            {
                path: 'help',
                name: 'help',
                title: '帮助中心',
                component: Help
            }
        ]
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/mine') {
        next({ path: '/mine/user' })
    } else if (to.path === '/mine/user') {
        next()
    }else {
        next()
    }
})

export default router
