import app from "@/main";
import {h} from "vue";
import {CodeTwoTone, AppstoreTwoTone, MacCommandOutlined, HighlightTwoTone, EditTwoTone} from "@ant-design/icons-vue";
import FM from "@/utils/format";

/**
 * 上下文菜单插件
 * @type {{Create: CM.Create}}
 */
const CM = {
    /**
     * 创建一个上下文菜单
     * @param e {MouseEvent} Element元素
     * @param items {Array} 菜单内容
     * @constructor
     */
    CreateCM: (e, items) => {
        e.preventDefault();
        app.config.globalProperties.$mycontextmenu({
            theme: "mac",
            x: e.x,
            y: e.y,
            items
        });
    },
    data: {
        testItems: (e) => {
            return [
                {
                    label: "测试数据",
                    icon: h(CodeTwoTone),
                    divided: true,
                    disabled: true,
                },
                {
                    label: "打印域文本",
                    icon: h(CodeTwoTone),
                    onClick: () => {
                        console.log("操作: 节点文本 ==> ", e.target.innerText)
                        console.log("操作: 选中文本 ==> ", FM.utils.getSelection())
                    },
                    shortcut: "Ctrl + P",

                },
                {
                    label: "标注",
                    icon: h(HighlightTwoTone),
                    onClick: () => {
                        FM.format.setLabel()
                    }
                },
                {
                    label: "批注",
                    icon: h(EditTwoTone),
                    onClick: () => {
                        const note = prompt("请输入批注内容:");
                        FM.format.setNote(note)
                    }
                },
                {
                    label: "更多功能",
                    icon: h(AppstoreTwoTone),
                    children: [
                        {
                            label: "划线",
                            onClick: () => {
                                FM.format.setLine()
                            }
                        },
                    ]
                },
            ]
        }
    }
}

export default CM