<template>
  <a-comment v-for="(item, index) in comments" :key="item.reComment.id">
    <template #actions>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <span>{{ item.reComment.createTime }}</span>
        <span style="margin-left: 0.5rem; color: #1890ff; cursor: pointer;" @click="FormCommentAdd.reId = item?.reComment?.id;FormCommentAdd.reUserId = item?.reComment?.userId;;showAddComment=true">回复</span>
        <a-popconfirm
            cancel-text="取消"
            ok-text="删除"
            title="确定删除该评论？"
            @confirm="deleteComment(item.reComment.id)"
        >
          <span v-if="item?.reComment?.userId === thatLocal.getItem('userId') && item?.reComment?.isDelete === 0" style="margin-left: 0.5rem; color: darkred; cursor: pointer;" @click="">删除</span>
        </a-popconfirm>

      </div>
    </template>
    <template #author>
      <a :href="'/' + item.userName" target="_blank">{{ item.nickName || item.userName || item.userId || '匿名用户' }}</a>
      <a-tag v-if="item.userId === discussMoreInfo?.reDiscuss.userId" color="#CC7F344C" style="color: #141414; margin-left: 1rem;">
        <template #icon>
          <CrownTwoTone />
        </template>
        楼主
      </a-tag>
    </template>
    <template #avatar>
      <a :href="'/' + item.userName" target="_blank">
        <a-avatar :alt="item.userName || item.userId || '匿名用户'" :src="item.userAvatar || '/favicon.ico'" />
      </a>
    </template>
    <template #content>
      <p>{{ item.reComment.content || '分享的人好帅' }}</p>
    </template>
    <!-- 确保正确传递评论列表 -->
    <ACommentList
        v-if="item?.commentList && item.commentList?.length"
        :comments="item.commentList"
    />
  </a-comment>
</template>

<script setup>
import {defineProps, inject, ref} from 'vue';
import { CrownTwoTone } from '@ant-design/icons-vue';
const thatLocal = require("@/utils/thatLocal")
const discussMoreInfo = inject('discussMoreInfo');
const showAddComment = inject('showAddComment');
const FormCommentAdd = inject('FormCommentAdd');
const deleteComment =inject('deleteComment');
const props = defineProps({
  comments: Array,
});
</script>

<style scoped>
:where(.css-dev-only-do-not-override-1hsjdkk).ant-comment {
  border-left: 2px dashed rgba(204, 204, 204, 0.34); /* 设置左边框为虚线，并指定颜色和宽度 */
  padding-left: 10px; /* 添加一些内边距，以便内容不贴近虚线 */
}
:where(.css-dev-only-do-not-override-1hsjdkk).ant-comment .ant-comment-inner {
  display: flex;
  padding: 10px 0;
}
</style>
