const msgger = require('@/utils/ThatMsg');


const SufCheck = {
    /**
     * 预检查文件类型
     * @param fileInfo 文件流
     * @param type 限制类型
     * @param size 限制大小 (MB) 默认2MB
     * @returns {boolean} true: 符合类型
     */
    fileCheck: (fileInfo, type='all', size=2) => {
        const fileNameSplit = fileInfo?.file?.name?.split('.') || [];
        const fileType = fileNameSplit[fileNameSplit.length - 1].toLowerCase() || 'null';
        const typeCheck = (type) => {
            const typeObject = {
                'image': ['jpg', 'jpeg', 'png', 'gif'],
                'video': ['mp4', 'avi','mov','mkv'],
                'audio': ['mp3', 'wav', 'ogg'],
                'document': ['pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx', 'ppt', 'pptx'],
                'all': ['jpg', 'jpeg', 'png', 'gif','mp4', 'avi','mov','mkv','mp3', 'wav', 'ogg', 'pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx', 'ppt', 'pptx']
            }
            return typeObject[type].includes(fileType);
        }
        const sizeCheck = (size) => {
            const fileSize = fileInfo?.file?.size || 0;
            return fileSize <= size * 1024 * 1024;
        }
        const typeResult = typeCheck(type);
        switch (typeResult) {
            case true:
                const sizeResult = sizeCheck(size);
                if (sizeResult) {
                    return true;
                } else {
                    msgger.error({msg: '文件大小超过限制，请上传小于 ' + size + 'MB 的文件！'});
                    return false;
                }
            default:
                msgger.error({msg: '文件类型不正确，请上传正确的文件类型！'});
                return false;
        }
    },
    serviceCheck: (axios, hasData=true) => {

    }
}

export default SufCheck;