const {proAxios} = require('@/api/MyAxios')
const proxyObject = require("@/api/proxy/ProxyObject");

const proxyUrl = 'https://resume.thatapi.cn/resume';

const resumeUrlBase = {
    addInfo: '/info',
    listInfo: '/info/list',
    listTmp: '/tmp/list',
    online: '/info/online',
    config: '/online',
    listConfig: '/online/list',
};

const resumeUrl = proxyObject(resumeUrlBase, proxyUrl);

/**
 * 添加简历信息
 * @param data
 * @returns {Promise<Object|boolean>}
 */
const addResumeInfo = async (data) => {
    const ResumeInfoRes = (await proAxios({
        url: resumeUrl.addInfo,
        method: 'post',
        data
    }));
    if (ResumeInfoRes.Then) {
        return ResumeInfoRes.Sources
    } else {
        return false
    }
}

/**
 * 获取简历信息列表
 * @returns {Promise<Object|boolean>}
 */
const listResumeInfo = async () => {
    const ResumeListRes = (await proAxios({
        url: resumeUrl.listInfo,
        method: 'GET',
    }));
    if (ResumeListRes.Then) {
        return ResumeListRes.Sources
    } else {
        return false
    }
}

/**
 * 删除简历信息
 * @param ids {Array<Number>|Number}
 * @returns {Promise<Object|boolean>}
 */
const deleteResumeInfo = async (ids) => {
    const deleteRes = (await proAxios({
        url: resumeUrl.addInfo + `/${ids.join(',')}`,
        method: 'DELETE',
    }))
    if (deleteRes.Then) {
        return deleteRes.Sources
    } else {
        return false
    }
}


/**
 * 修改简历信息
 * @param data
 * @returns {Promise<Object|boolean>}
 */
const putResumeInfo = async (data) => {
    const ResumeInfoRes = (await proAxios({
        url: resumeUrl.addInfo,
        method: 'put',
        data
    }))
    if (ResumeInfoRes.Then) {
        return ResumeInfoRes.Sources
    } else {
        return false
    }
}


/**
 * 获取简历模板列表
 * @returns {Promise<Object|boolean>}
 */
const listResumeTmp = async () => {
    const ResumeListRes = (await proAxios({
        url: resumeUrl.listTmp+'?pageNum=1&pageSize=100',
        method: 'GET',
    }))
    if (ResumeListRes.Then) {
        return ResumeListRes.Sources
    } else {
        return false
    }
}

const onlineResume = async (data) => {
    const onlineRes = (await proAxios({
        url: resumeUrl.online,
        method: 'post',
        data,
        token: false
    }))
    if (onlineRes.Then) {
        return onlineRes.Sources
    }else{
        return false
    }
}

const getOnline = async (userId=-1) => {
    const onlineRes = (await proAxios({
        url: resumeUrl.listConfig,
        method: 'get',
        userId
    }))
    if (onlineRes.Then) {
        return onlineRes.Sources
    }else{
        return false
    }
}

const addOnline = async (data) => {
    const onlineRes = (await proAxios({
        url: resumeUrl.config,
        method: 'post',
        data
    }))
    if (onlineRes.Then) {
        return onlineRes.Sources
    }else{
        return false
    }
}

const updateOnline = async (data) => {
    const onlineRes = (await proAxios({
        url: resumeUrl.config,
        method: 'put',
        data
    }))
    if (onlineRes.Then) {
        return onlineRes.Sources
    }else{
        return false
    }
}

module.exports = storageController = {
    addResumeInfo,
    listResumeInfo,
    deleteResumeInfo,
    putResumeInfo,
    listResumeTmp,
    onlineResume,
    getOnline,
    addOnline,
    updateOnline
}
