<template>
  <a-spin :spinning="LoadResumeInfo" class="welcome-loading" size="large">
    <div v-if="LoadResumeInfo" class="UserInfo" style="height: 88vh;">
      <a-skeleton v-for="i in 10" :paragraph="{ rows: 3 }" active avatar class="UserInfo-card" style="width: 300px"/>
    </div>
    <div v-else class="UserInfo">
      <a-empty style="width: 300px !important;margin: 10px;">
        <template #description>
          <a-button type="primary" @click="VisitAdd.box = true">
            <PlusOutlined/>
            新建档案
          </a-button>
        </template>
      </a-empty>
      <div style="display:flex;flex-direction: column;align-items: center;justify-content:center;">
        <a-typography-title v-if="resumeInfoData?.length === 0" :level="3" style="opacity: 0.5;margin-bottom: 0">暂无简历档案, 快来新建吧！</a-typography-title>
      </div>
      <a-card v-for="(data, index) in resumeInfoData" :id="data?.id + ''|| ''+Date.now()" class="UserInfo-card"
              hoverable style="width: 300px">
        <template #actions>
          <a-button key="newResume" type="text" @click="FunInfoToFile(data)">
            <CopyOutlined/>
            复制
          </a-button>
          <a-button key="edit" type="text" @click="FunEditInfo(data,index)">
            <edit-outlined/>
            编辑
          </a-button>

          <a-dropdown arrow placement="bottom">
            <a-button key="setting" type="text">
              <EllipsisOutlined/>
              更多
            </a-button>
            <template #overlay>
              <a-menu>
                <!--                TODO: 网页生产简历功能-->
                <a-menu-item key="1" @click="FunPreviewResume(data.id)">
                  <ChromeOutlined/> &nbsp; 预览简历
                </a-menu-item>
                <!--                TODO: 高级设置管理公开状态等等-->
                <a-menu-item key="2" @click="FunResumeUsed(data,index)">
                  <setting-outlined/> &nbsp; 高级设置
                </a-menu-item>
                <a-menu-item key="3" danger>
                  <a-popconfirm cancelText="取消" okText="确认" placement="top" title="确认删除？"
                                @confirm="deleteResumeInfo(data.id).then(() => true)">
                    <DeleteOutlined/> &nbsp; 删除档案
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <!--      <ellipsis-outlined key="ellipsis"/>-->
        </template>
        <a-card-meta :key="data?.id || ''+Date.now()" :description="data?.remark || '这是未响应数据,真糟糕!'"
                     :title="data?.resumeData?.user?.name || '张三'">
          <template #avatar>
            <a-avatar :size="60" :src="(data?.resumeData?.avatar?.length > 0 ? data.resumeData.avatar.startsWith('http')? data.resumeData.avatar : 'https://resumes.thatcdn.cn' + data.resumeData.avatar : '/favicon.ico' )"
            ></a-avatar>
          </template>
        </a-card-meta>
      </a-card>
    </div>

    <!--  档案高级设置拟态框-->
    <a-modal v-model:open="VisitPower" :closable="false" :confirm-loading="confirmLoading" :keyboard="false" :maskClosable="false" :ok-text="modalText"
             cancel-text="取消"
             centered style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;height: auto" @cancel="editResumeData={edit:false,index:-1,used:false};VisitPower=false" @ok="handleOk">
      <a-form
          :model="resumeUsed"
          layout="horizontal"
          style="min-width: 300px;margin-top: 20px;margin-left:4vw;display: flex;flex-direction: column;align-items: flex-start"
      >
        <a-form-item label="公开访问">
          <a-switch v-model:checked="resumeUsed.public"/>
        </a-form-item>
        <a-form-item v-if="resumeUsed.public" label="设为默认">
          <a-switch v-model:checked="resumeUsed.isMain"/>
        </a-form-item>
        <a-form-item v-if="resumeUsed.public" label="公开工作">
          <a-switch v-model:checked="resumeUsed.job"/>
        </a-form-item>
        <a-form-item v-if="resumeUsed.public" label="公开教育">
          <a-switch v-model:checked="resumeUsed.education"/>
        </a-form-item>
        <a-form-item v-if="resumeUsed.public" label="公开作品">
          <a-switch v-model:checked="resumeUsed.project"/>
        </a-form-item>

        <a-form-item label="浏览次数">
          {{ resumeUsed.visitNum }} 次
        </a-form-item>
        <a-form-item v-if="resumeUsed.public" label="访问地址">
          <a-typography-paragraph v-if="!resumeUsed.isMain" aria-valuetext="2333" copyable style="color: #467bb1;margin-bottom:0">
            {{ 'https://resume.app.thatcoder.cn' + '/online/' + resumeInfoData[editResumeData.index]?.id }}
          </a-typography-paragraph>
          <a-typography-paragraph v-else aria-valuetext="2333" copyable style="color: #467bb1;margin-bottom:0">
            {{ 'https://resume.app.thatcoder.cn/' + thatLocal.getItem("userName") }}
          </a-typography-paragraph>
        </a-form-item>
      </a-form>
    </a-modal>

    <!--  档案拟态框-->
    <a-watermark content="简历说 SayResume" style="z-index: 999999999 !important;">
      <a-drawer
          v-model:open="VisitAdd.box"
          :closable="false"
          :footer-style="{ textAlign: 'right' }"
          :maskClosable="false"
          placement="right"
          style="background: #efeeee"
          title="新建简历档案"
          width="100vw"
          @close="VisitAdd.box = false"
      >
        <div class="new-box">
          <div class="new-box-left">
            <!--    工作技能-->
            <div class="UserInfo-card-new">
              <a-typography-title :level="4">工作技能
                <a-button size="small" style="padding-bottom: 10px" type="dashed" @click="FunFormSkillOpen">
                  <template #icon>
                    <EditTwoTone/>
                  </template>
                  编辑
                </a-button>
              </a-typography-title>
              <a-drawer v-model:open="VisitAdd.skill" :closable="false" :footer-style="{ textAlign: 'right' }"
                        :maskClosable="false"
                        placement="right"
                        title="工作经历编辑" width="100vw">
                <a-form
                    v-for="(user, index) in dataInputSkills.users"
                    :key="user.id"
                    ref="inputFormSkill"
                    :model="dataInputSkills"
                    name="dynamic_form_nest_item"
                    style="max-width: 800px; min-width: 300px"
                    @finish="FunFormSkillOk"
                >
                  <a-form-item
                      :label="index + 1"
                      :name="['users', index, 'skill']"
                      :rules="{
          required: true,
          message: '内容不能为空',
        }"
                      style="max-width: 800px; min-width: 300px;"
                  >
                    <a-form-item-rest>
                      <a-button danger size="small" type="dashed" @click="FunFormSkillRm(user)">
                        <MinusCircleOutlined/>
                        删除此条技能
                      </a-button>
                    </a-form-item-rest>
                    <a-typography-paragraph v-model:content="user.skill"
                                            :editable="{ maxlength: 255, autoSize: {  minRows: 3 } }">
                      <template #editableIcon>
                        <HighlightOutlined/>
                      </template>
                      <template #editableTooltip>编辑</template>
                    </a-typography-paragraph>
                  </a-form-item>
                </a-form>
                <a-form-item style="max-width: 800px; min-width: 300px">
                  <a-button block type="dashed" @click="FunFormSkillAdd">
                    <PlusOutlined/>
                    添加技能描述
                  </a-button>
                </a-form-item>
                <template #footer>
                  <a-button style="margin-right: 8px" @click="VisitAdd.skill = false">取消</a-button>
                  <a-button type="primary" @click="FunFormSkillOk">确认</a-button>
                </template>
              </a-drawer>
              <a-badge-ribbon :text="userInfo.skills.length" color="volcano">
                <a-list :data-source="userInfo.skills" bordered column="4" size="small">
                  <template v-if="userInfo.skills.length > 3" #header>
                    <div>
                      <a-typography-text strong>已折叠 {{ userInfo.skills.length - 3 }} 条技能描述</a-typography-text>
                    </div>
                  </template>
                  <template #renderItem="{ item, index }">
                    <a-list-item v-if="index<3">{{ substring(item, 40) }}...</a-list-item>
                  </template>
                </a-list>
              </a-badge-ribbon>
            </div>
            <br><br><br>
            <!--    教育经历-->
            <div class="UserInfo-card-new">
              <a-typography-title :level="4">教育经历
                <a-button size="small" style="padding-bottom: 10px" type="dashed"
                          @click="copyUserInfo(true);editEdu.model='edu';VisitAdd.edu = true">
                  <template #icon>
                    <EditTwoTone/>
                  </template>
                  编辑
                </a-button>
              </a-typography-title>
              <a-tag v-for="(value, key) in userInfo.education" :key="key" color="orange">
                <BankTwoTone/>
                {{ value.name }}
              </a-tag>
            </div>
            <br><br><br>
            <!--    项目经历-->
            <div class="UserInfo-card-new">
              <a-typography-title :level="4">项目经验
                <a-button size="small" style="padding-bottom: 10px" type="dashed"
                          @click="copyUserInfo(true);editEdu.model='pro';VisitAdd.edu = true">
                  <template #icon>
                    <EditTwoTone/>
                  </template>
                  编辑
                </a-button>
              </a-typography-title>
              <a-tag v-for="(value, key) in userInfo.project" :key="key" color="orange">
                <FolderOpenTwoTone/>
                {{ value.name }}
              </a-tag>
            </div>
            <br><br><br>
            <!--    工作经历-->
            <div class="UserInfo-card-new">
              <a-typography-title :level="4">工作经历
                <a-button size="small" style="padding-bottom: 10px" type="dashed"
                          @click="copyUserInfo(true);editEdu.model='job';VisitAdd.edu = true">
                  <template #icon>
                    <EditTwoTone/>
                  </template>
                  编辑
                </a-button>
              </a-typography-title>
              <a-tag v-for="(value, key) in userInfo.job" :key="key" color="orange">
                <ToolTwoTone/>
                {{ value.name }}
              </a-tag>
            </div>
            <br><br><br>
          </div>
          <div class="new-box-right">
            <!--    个人信息-->
            <div class="UserInfo-card-new">
              <a-typography-title :level="4">个人信息
                <a-button size="small" type="dashed"
                          @click="VisitAdd.user = true;copyUserInfo(true);fileList[0].url='https://resumes.thatcdn.cn' + userInfo.avatar;">
                  <template #icon>
                    <EditTwoTone/>
                  </template>
                  编辑
                </a-button>
              </a-typography-title>
              <a-card size="small" style="background: #efeeee;width: 80%">
                <template #title>
                  <a-avatar :src="'https://resumes.thatcdn.cn' + (userInfo?.avatar || '/favicon.ico')" size="large"
                            style="margin: 10px"></a-avatar>
                  <a-typography-text strong>{{ userInfo.user.name }}</a-typography-text>
                </template>
                <!--          <a-typography-paragraph v-model:content="userInfo.user.age" editable />-->
                <a-tag v-for="(value, key) in userInfo.user" :key="key" color="orange">{{ value }}</a-tag>
              </a-card>
              <a-drawer v-model:open="VisitAdd.user" :closable="false" :footer-style="{ textAlign: 'right' }"
                        :maskClosable="false"
                        placement="right" title="个人信息编辑">
                <div class="new-box-form">
                  <a-form
                      :model="userInfo.user"
                      layout="horizontal"
                      style="max-width: 800px"
                  >
                    <a-form-item label="姓名">
                      <a-input v-model:value="userInfo.user.name" placeholder="e.g. 张三"/>
                    </a-form-item>
                    <a-form-item label="性别">
                      <a-radio-group v-model:value="userInfo.user.gender">
                        <a-radio value="男">男</a-radio>
                        <a-radio value="女">女</a-radio>
                      </a-radio-group>
                    </a-form-item>
                    <a-form-item label="年龄">
                      <a-input-number v-model:value="userInfo.user.age"/>
                    </a-form-item>
                    <a-form-item label="民族">
                      <a-input v-model:value="userInfo.user.mz" placeholder="e.g. 汉族"/>
                    </a-form-item>
                    <a-form-item label="出生年月">
                      <a-input v-model:value="userInfo.user.birthday" placeholder="e.g. 2000-04"/>
                    </a-form-item>
                    <a-form-item label="户籍地址">
                      <a-input v-model:value="userInfo.user.hometown" placeholder="e.g. 山东菏泽"/>
                    </a-form-item>
                    <a-form-item label="现居地址">
                      <a-input v-model:value="userInfo.user.address" placeholder="e.g. 广东广州天河区"/>
                    </a-form-item>
                    <a-form-item label="政治面貌">
                      <a-select v-model:value="userInfo.user.politics">
                        <a-select-option value="人民群众">人民群众</a-select-option>
                        <a-select-option value="共青团员">共青团员</a-select-option>
                        <a-select-option value="预备党员">预备党员</a-select-option>
                        <a-select-option value="中共党员">中共党员</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="学历学位">
                      <a-select v-model:value="userInfo.user.education">
                        <a-select-option value="高中">高中及以下</a-select-option>
                        <a-select-option value="大专">大专</a-select-option>
                        <a-select-option value="本科">本科</a-select-option>
                        <a-select-option value="研究生">研究生</a-select-option>
                        <a-select-option value="硕博">硕博</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="专业学科">
                      <a-input v-model:value="userInfo.user.profession" placeholder="e.g. 软件工程"/>
                    </a-form-item>
                    <a-form-item label="电话号码">
                      <a-input v-model:value="userInfo.user.phone" placeholder="e.g. 133xxxxxxxx"/>
                    </a-form-item>
                    <a-form-item label="电子邮件">
                      <a-auto-complete
                          v-model:value="userInfo.user.email"
                          :options="optionEmail"
                          placeholder="e.g. thatcoder@163.com"
                          @search="searchEmail"
                      >
                        <template #option="{ value: val }">
                          {{ val.split('@')[0] }} @
                          <span style="font-weight: bold">{{ val.split('@')[1] }}</span>
                        </template>
                      </a-auto-complete>
                    </a-form-item>
                    <a-form-item label="上传头像">
                      <a-upload v-model:fileList="fileList" :custom-request="FunUpdateLocalFileCheck" :max-count="1"
                                list-type="picture-card">
                        <div>
                          <PlusOutlined/>
                          <div style="margin-top: 8px">点击上传</div>
                        </div>
                      </a-upload>
                    </a-form-item>
                  </a-form>
                </div>
                <template #footer>
                  <a-button style="margin-right: 8px" @click="copyUserInfo(false, true);VisitAdd.user = false;">取消
                  </a-button>
                  <a-button type="primary" @click="copyUserInfo(false, false);VisitAdd.user = false">确认</a-button>
                </template>
              </a-drawer>
            </div>
            <br><br>
            <!--    技能证书-->
            <div class="UserInfo-card-new">
              <a-badge :count="userInfo.certificates.length" :offset="[7,2]">
                <a-typography-title :level="4">技能证书</a-typography-title>
              </a-badge>
              <br>
              <template v-for="(tag, index) in userInfo.certificates" :key="tag">
                <a-tooltip v-if="tag.length > 20" :title="tag">
                  <a-tag :closable="true" color="orange" @close="closeCert(tag)">
                    {{ `${tag.slice(0, 20)}...` }}
                  </a-tag>
                </a-tooltip>
                <a-tag v-else :closable="true" color="orange" @close="closeCert(tag)">
                  {{ tag }}
                </a-tag>
              </template>
              <a-input
                  v-if="stateCert.inputVisible"
                  ref="inputCert"
                  v-model:value="stateCert.inputValue"
                  :style="{ width: '78px' }"
                  size="small"
                  type="text"
                  @blur="inputCheckCert"
                  @keyup.enter="inputCheckCert"
              />
              <a-tag v-else style="background: #fff; border-style: dashed" @click="showCert">
                <plus-outlined/>
                添加证书
              </a-tag>
            </div>
            <br><br>
            <!--    兴趣爱好-->
            <div class="UserInfo-card-new">
              <a-badge :count="userInfo.hobbies.length" :offset="[7,2]">
                <a-typography-title :level="4">兴趣爱好</a-typography-title>
              </a-badge>
              <br>
              <template v-for="(tag, index) in userInfo.hobbies" :key="tag">
                <a-tooltip v-if="tag.length > 20" :title="tag">
                  <a-tag :closable="true" color="orange" @close="closeHobby(tag)">
                    {{ `${tag.slice(0, 20)}...` }}
                  </a-tag>
                </a-tooltip>
                <a-tag v-else :closable="true" color="orange" @close="closeHobby(tag)">
                  {{ tag }}
                </a-tag>
              </template>
              <a-input
                  v-if="stateHobby.inputVisible"
                  ref="inputHobby"
                  v-model:value="stateHobby.inputValue"
                  :style="{ width: '78px' }"
                  size="small"
                  type="text"
                  @blur="inputCheckHobby"
                  @keyup.enter="inputCheckHobby"
              />
              <a-tag v-else style="background: #fff; border-style: dashed" @click="showHobby">
                <plus-outlined/>
                添加爱好
              </a-tag>
            </div>
            <br><br>
            <!--    自我评价-->
            <div class="UserInfo-card-new">
              <a-typography-title :level="4">自我评价</a-typography-title>
              <a-typography-paragraph v-model:content="userInfo.self_evaluation" editable>
                <template #editableIcon>
                  <HighlightOutlined/>
                </template>
                <template #editableTooltip>编辑</template>
              </a-typography-paragraph>
            </div>
            <br><br>
            <!--    社交方式-->
            <div class="UserInfo-card-new">
              <a-badge :count="userInfo.socials.length" :offset="[7,2]">
                <a-typography-title :level="4">社交方式</a-typography-title>
              </a-badge>
              <br>
              <template v-for="(tag, index) in userInfo.socials" :key="tag">
                <a-tooltip :title="tag.link">
                  <a-tag :closable="true" color="orange" @close="closeSocial(tag)">
                    {{ `${tag.name.slice(0, 20)}` }}
                  </a-tag>
                </a-tooltip>
              </template>
              <a-input
                  v-if="stateSocial.inputVisible"
                  ref="inputSocial"
                  v-model:value="stateSocial.inputValue.name"
                  :style="{ width: '120px' }"
                  size="small"
                  type="text"
                  @blur="inputCheckSocial"
                  @keyup.enter="inputCheckSocial"
              />
              <a-tooltip v-else title="输入格式: 平台|链接 ">
                <a-tag style="background: #fff; border-style: dashed" @click="showSocial">
                  <plus-outlined/>
                  添加社交方式
                </a-tag>
              </a-tooltip>
            </div>
            <br>
          </div>
        </div>
        <template #footer>
          <a-button style="margin-right: 8px" @click="editResumeData={edit:false,index:-1,used:false};VisitAdd.box = false">取消
          </a-button>
          <a-button type="primary" @click="saveResumeInfo();VisitAdd.box = false">保存</a-button>
        </template>
      </a-drawer>
    </a-watermark>


    <!--  教育 工作 项目-->
    <a-drawer v-model:open="VisitAdd.edu" :closable="false" :footer-style="{ textAlign: 'right' }"
              :maskClosable="false" :title="inputFormEduLabel[editEdu.model].title" placement="left">
      <a-card
          v-for="(value, key) in (editEdu.model==='edu'? userInfo.education : editEdu.model==='job' ? userInfo.job : userInfo.project)"
          :key="key" size="small"
          style="background: #efeeee;width: 80%;margin: 5px">
        <template #title>
          <a-typography-text strong>{{ value.name }}</a-typography-text>
        </template>
        <template #extra>
          <a-button size="small" type="dashed"
                    @click="editEdu.edited = true;editEdu.index = key;inputFormEdu = value;FunFormEduRead();VisitAdd.eduAdd = true;copyUserInfo(true);">
            编辑
          </a-button>
          <a-button danger size="small" type="dashed" @click="FunFormEduRmItem(key)">删除</a-button>
        </template>
        <template v-for="(value2, key2) in value" :key="key2">
          <a-tooltip v-if="value2.length > 20" :title="value2">
            <a-tag color="orange">
              {{ `${value2.slice(0, 20)}...` }}
            </a-tag>
          </a-tooltip>
          <a-tag v-else color="orange">
            {{ value2 }}
          </a-tag>
        </template>
      </a-card>
      <a-button style="margin: 20px" type="primary" @click="VisitAdd.eduAdd = true;copyUserInfo(true)">
        <PlusOutlined/>
        添加
      </a-button>
      <a-drawer v-model:open="VisitAdd.eduAdd" :closable="false" :footer-style="{ textAlign: 'right' }"
                :maskClosable="false"
                :title="inputFormEduLabel[editEdu.model].title" placement="left" style="min-width: 300px">
        <a-form
            :model="inputFormEdu"
            layout="horizontal"
            style="max-width: 800px"
        >
          <a-form-item :label="inputFormEduLabel[editEdu.model].name[0]">
            <a-input v-model:value="inputFormEdu.name" :placeholder="inputFormEduLabel[editEdu.model].name[1]"/>
          </a-form-item>
          <a-form-item :label="inputFormEduLabel[editEdu.model].star[0]">
            <a-input v-model:value="inputFormEdu.start" :placeholder="inputFormEduLabel[editEdu.model].star[1]"/>
          </a-form-item>
          <a-form-item :label="inputFormEduLabel[editEdu.model].end[0]">
            <a-input v-model:value="inputFormEdu.end" :placeholder="inputFormEduLabel[editEdu.model].end[1]"/>
          </a-form-item>
          <a-form-item :label="inputFormEduLabel[editEdu.model].power[0]">
            <a-input v-model:value="inputFormEdu.power" :placeholder="inputFormEduLabel[editEdu.model].power[1]"/>
          </a-form-item>
          <a-form-item :label="inputFormEduLabel[editEdu.model].desc[0]">
            <a-textarea v-model:value="inputFormEdu.desc" :placeholder="inputFormEduLabel[editEdu.model].desc[1]"/>
          </a-form-item>
          <a-form-item :label="inputFormEduLabel[editEdu.model].tech[0]">
            <a-textarea v-model:value="inputFormEdu.tech" :placeholder="inputFormEduLabel[editEdu.model].tech[1]"/>
          </a-form-item>
          <a-form-item :label="inputFormEduLabel[editEdu.model].work[0]">
            <a-form-item
                v-for="(domain, index) in dynamicValidateForm.domains"
                :key="domain.key"
                :name="['domains', index, 'value']"
                v-bind="index === 0 ? {} : {}"
            >
              <a-textarea
                  v-model:value="domain.value"
                  :placeholder="inputFormEduLabel[editEdu.model].work[1]"
                  style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                  v-if="dynamicValidateForm.domains.length > 1"
                  class="dynamic-delete-button"
                  @click="FunFormEduRm(domain)"
              />
            </a-form-item>
            <a-form-item v-bind="formItemLayoutWithOutLabel">
              <a-button style="width: 60%" type="dashed" @click="FunFormEduAdd">
                <PlusOutlined/>
                添加
              </a-button>
            </a-form-item>
          </a-form-item>
          <a-form-item :label="inputFormEduLabel[editEdu.model].link[0]">
            <a-input v-model:value="inputFormEdu.link" :placeholder="inputFormEduLabel[editEdu.model].link[1]"/>
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button style="margin-right: 8px" @click="FunFormEduNo">取消
          </a-button>
          <a-button type="primary" @click="FunFormEduOk">
            {{
              editEdu.edited ? '保存' : '添加'
            }}
          </a-button>
        </template>
      </a-drawer>
      <template #footer>
        <a-button style="margin-right: 8px" @click="copyUserInfo(false, true);VisitAdd.edu = false">取消
        </a-button>
        <a-button type="primary" @click="copyUserInfo(false, false);VisitAdd.edu = false;">保存</a-button>
      </template>
    </a-drawer>

  </a-spin>
</template>

<script setup>

import {
  BankTwoTone,
  ChromeOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EditTwoTone,
  EllipsisOutlined,
  FolderOpenTwoTone,
  HighlightOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SettingOutlined,
  ToolTwoTone
} from "@ant-design/icons-vue"
import {inject, nextTick, reactive, ref} from "vue";
import {GlobalReceipt} from "@/utils/thatRe";
import thatLocal from "@/utils/thatLocal";
import FileUtil from "@/utils/exportDocx";
import SufCheck from "@/utils/SufCheck";
import msgger from "@/utils/ThatMsg";
import router from "@/router";

const api = require('@/api/controller');
const thatRe = GlobalReceipt()
const RMenuCurrent = inject('RMenuCurrent');

RMenuCurrent.value = ['userInfo'];
let userInfoTmp = ref([{
  avatar: "",
  // 个人信息
  user: {},
  // 技能
  skills: [],
  // 项目经历
  project: [],
  // 工作经历
  job: [],
  //教育经历
  education: [],
  // 语言能力
  languages: [],
  // 兴趣爱好
  hobbies: [],
  // 证书
  certificates: [],
  // 社交信息 如github等等
  socials: [],
  // 自我评价
  self_evaluation: ''
}])
let userInfo = ref({
  avatar: "https://blog.thatcoder.cn/favicon.ico",
  // 个人信息
  user: {
    name: '张三',
    age: 24,
    mz: '',
    gender: '',
    education: '',
    profession: '',
    politics: '',
    hometown: '',
    phone: '',
    email: '',
    address: '',
    birthday: '',
  },

  // 技能
  skills: [
    "熟悉Java后端开发与java基础知识，如常用包、集合框架(ArrayList、HashMap、HashSet等)、反射等，了解常见的数据结构及算法，熟悉常见的设计模式，如单例模式、工厂模式、适配器模式等。",
    "熟悉Spring Cloud中的核心组件，包括Nacos、Seata、Open Feign、Gateway，了解Nacos的服务注册和服务发现机制。熟练掌握SpringMVC、MyBatis、My Batis Plus等主流开发框架，深谙Spring的IOC和AOP设计理念。了解CI/CD管道流程与简单使用Jenkins工具。",
    "熟悉前端开发与多种预处理技术如Vue、Webpack、Node、Bun、Next、TypeScript、HTML三件套、Element UI、Arco Design、Ant Design、微信小程序等，了解多种渲染方式如BSR、SSG、SSR。",
    "熟悉使用Git版本控制，积极参与开源项目维护, 有良好的编码规范, 能较好的协作开发。",
    "掌握Apache、NginX、Tomcat等Web服务器和应用服务器的使用，了解负载均衡的配置。",
    "掌握Docker容器化技术及多年Linux系统的操作经验。",
    "具备基本的UI设计理解，能够简单运用即时设计工具，与产品经理有相关对接合作。",
    "具备Python的基本知识，积累了Python爬虫项目的实际经验。"
  ],

  // 项目经历
  project: [
    {
      name: '个人博客',
      start: '2020-01-01',
      end: '2022-01-01',
      power: '全桟开发',
      desc: '个人博客，记录学习、生活、工作等',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      work: ['个人博客，记录学习、生活、工作等'],
      link: 'https://blog.thatcoder.cn',
    }
  ],
  // 工作经历
  job: [
    {
      name: '广州华多网络科技有限公司',
      start: '2022-01-01',
      end: '2022-07-01',
      power: '前端开发工程师',
      desc: '负责公司官网、内部系统的前端开发',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      work: ['负责公司官网、内部系统的前端开发'],
      link: 'https://www.huawei.com/cn/',
    }
  ],

  //教育经历
  education: [
    {
      name: '广东工业大学',
      start: '2016-09-01',
      end: '2020-06-30',
      power: '工科学位',
      desc: '软件工程',
      tech: '经济学',   // 辅修专业或主要课程
      work: ['软件工程'],         // 校园活动
      link: '班级前5%',   //成绩
    }
  ],


  // 语言能力
  languages: [
    {
      name: '英语',
      level: 'CET-6',
    }
  ],

  // 兴趣爱好
  hobbies: [
    '看书',
    '露营',
    '摄影',
    '品茶',
  ],

  // 证书
  certificates: [
    "CET-6",
    "软考高级",
    "普通话二甲"
  ],

  // 社交信息 如github等等
  socials: [
    {
      name: "博客",
      link: 'https://blog.thatcoder.cn'
    },
    {
      name: 'GitHub',
      link: 'https://github.com/thatcoders',
    },
    {
      name: '知乎',
      link: 'https://www.zhihu.com/people/thatcoder',
    },
  ],

  // 自我评价
  self_evaluation: '热爱编程，喜欢尝试新事物，乐于分享，有良好的沟通能力，能承受一定的工作压力。'

})

// 方法空间
/**
 * 新建档案
 */
const VisitAdd = ref(
    {
      box: false,
      user: false,
      skill: false,
      project: false,
      job: false,
      edu: false,
      social: false,
      eduAdd: false,
      jobAdd: false,
      projectAdd: false,
    }
)

// 裁剪方法
const substring = (str, len) => {
  return str.substr(0, len)
}

// 兴趣爱好
const inputHobby = ref();
const stateHobby = reactive({
  inputVisible: false,
  inputValue: '',
});
const closeHobby = removedTag => {
  userInfo.value.hobbies = userInfo.value.hobbies.filter(tag => tag !== removedTag)
};
const showHobby = () => {
  stateHobby.inputVisible = true;
  nextTick(() => {
    inputHobby.value.focus();
  });
};
const inputCheckHobby = () => {
  const inputValue = stateHobby.inputValue;
  let tags = userInfo.value.hobbies;
  if (inputValue && tags.indexOf(inputValue) === -1) {
    tags = [...tags, inputValue];
  }
  userInfo.value.hobbies = tags
  Object.assign(stateHobby, {
    inputVisible: false,
    inputValue: '',
  });
};

//社交
const inputSocial = ref();
const stateSocial = reactive({
  inputVisible: false,
  inputValue: {
    name: '',
    link: '',
  },
});
const closeSocial = removedTag => {
  userInfo.value.socials = userInfo.value.socials.filter(tag => tag !== removedTag)
};
const showSocial = () => {
  stateSocial.inputVisible = true;
  nextTick(() => {
    inputSocial.value.focus();
  });
};
const inputCheckSocial = () => {
  const initSocial = () => {
    Object.assign(stateSocial, {
      inputVisible: false,
      inputValue: {
        name: '',
        link: '',
      },
    });
  }
  if (stateSocial.inputValue.name.length === 0) {
    initSocial()
    return
  }
  let inputValue = stateSocial.inputValue.name.split('|');
  if (inputValue.length !== 2) {
    thatRe.msg.warning("添加失败,请注意社交方式填写格式")
    initSocial()
    return
  }
  inputValue = {
    name: inputValue[0],
    link: inputValue[1],
  }
  let tags = userInfo.value.socials;
  if (inputValue && tags.indexOf(inputValue) === -1) {
    tags = [...tags, inputValue];
  }
  userInfo.value.socials = tags
  Object.assign(stateSocial, {
    inputVisible: false,
    inputValue: {
      name: '',
      link: '',
    },
  });
};

// 证书
const inputCert = ref();
const stateCert = reactive({
  inputVisible: false,
  inputValue: '',
});
const closeCert = removedTag => {
  userInfo.value.certificates = userInfo.value.certificates.filter(tag => tag !== removedTag)
};
const showCert = () => {
  stateCert.inputVisible = true;
  nextTick(() => {
    inputCert.value.focus();
  });
};
const inputCheckCert = () => {
  const inputValue = stateCert.inputValue;
  let tags = userInfo.value.certificates;
  if (inputValue && tags.indexOf(inputValue) === -1) {
    tags = [...tags, inputValue];
  }
  userInfo.value.certificates = tags
  Object.assign(stateCert, {
    inputVisible: false,
    inputValue: '',
  });
};

//邮件
const optionEmail = ref([]);
const searchEmail = val => {
  let res;
  const emailDomains = ['qq.com', '163.com', 'gmail.com', 'hotmail.com', 'outlook.com', 'yahoo.com', 'sina.com', 'sohu.com', 'aliyun.com', 'foxmail.com', 'live.com', 'yahoo.cn'];
  if (!val || val.indexOf('@') >= 0) {
    res = [];
  } else {
    res = emailDomains.map(domain => ({
      value: `${val}@${domain}`,
    }));
  }
  optionEmail.value = res;
};

//工作技能
const inputFormSkill = ref();
const dataInputSkills = reactive({
  users: [],
});
const FunFormSkillRm = item => {
  const index = dataInputSkills.users.indexOf(item);
  if (index !== -1) {
    dataInputSkills.users.splice(index, 1);
  }
};
const FunFormSkillAdd = () => {
  dataInputSkills.users.push({
    skill: '',
    id: Date.now()
  });
};
const FunFormSkillOk = values => {
  let newSkill = []
  dataInputSkills.users.filter(item => newSkill.push(item.skill));
  userInfo.value.skills = newSkill
  VisitAdd.value.skill = false
  dataInputSkills.users = []
};
const FunFormSkillOpen = () => {
  dataInputSkills.users = []
  userInfo.value.skills.filter(item => dataInputSkills.users.push({
    skill: item,
    id: Date.now()
  }))
  VisitAdd.value.skill = true
}

// 教育 工作 项目
let inputFormEdu = ref({name: '', start: '', end: '', power: '', desc: '', tech: '', work: [], link: ''})

const formAdd = () => {
  if (inputFormEdu.value.name === "" && inputFormEdu.value.start === "" && inputFormEdu.value.end === "") {
    thatRe.msg.warning("请填写完整信息")
  } else {
    if (editEdu.value.edited) {
      if (editEdu.value.model === "edu") {
        userInfo.value.education[editEdu.value.index] = inputFormEdu.value
        thatRe.msg.success("添加教育经历成功")
      } else if (editEdu.value.model === "job") {
        userInfo.value.job[editEdu.value.index] = inputFormEdu.value
        thatRe.msg.success("添加工作经历成功")
      } else if (editEdu.value.model === "pro") {
        userInfo.value.project[editEdu.value.index] = inputFormEdu.value
        thatRe.msg.success("添加项目经验成功")
      }
    } else {
      if (editEdu.value.model === "edu") {
        userInfo.value.education.push(inputFormEdu.value)
        thatRe.msg.success("添加教育经历成功")
      } else if (editEdu.value.model === "job") {
        userInfo.value.job.push(inputFormEdu.value)
        thatRe.msg.success("添加工作经历成功")
      } else if (editEdu.value.model === "pro") {
        userInfo.value.project.push(inputFormEdu.value)
        thatRe.msg.success("添加项目经验成功")
      }
    }
  }
  inputFormEdu.value = {name: '', start: '', end: '', desc: '', tech: '', work: [], link: ''}
}

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};
const dynamicValidateForm = reactive({
  domains: [],
});
const FunFormEduRm = item => {
  const index = dynamicValidateForm.domains.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.domains.splice(index, 1);
  }
};
const FunFormEduRmItem = (key) => {
  if (editEdu.value.model === "edu") {
    userInfo.value.education.splice(key, 1);
    thatRe.msg.success("删除成功")
  } else if (editEdu.value.model === "job") {
    userInfo.value.job.splice(key, 1);
    thatRe.msg.success("删除成功")
  } else if (editEdu.value.model === "pro") {
    userInfo.value.project.splice(key, 1);
    thatRe.msg.success("删除成功")
  }
}
const FunFormEduAdd = () => {
  dynamicValidateForm.domains.push({
    value: '',
    key: Date.now(),
  });
};
const inputFormEduLabel = {
  edu: {
    title: "完善教育经历",
    name: ["高校名称", "e.g. 清华大学"],
    star: ["入学年月", "e.g. 2020-09"],
    end: ["毕业年月", "e.g. 2024-06"],
    power: ["学士学位", "e.g. 工科学士(可不填)"],
    desc: ["专业名称", "e.g. 软件工程"],
    tech: ["主辅课程", "e.g. 经济学、金融学、计算机"],
    work: ["校园经历", "e.g. 担任xxx, 领导xxx ..."],
    link: ["成绩情况", "e.g. 年纪前1%"],
  },
  job: {
    title: "完善工作经历",
    name: ["公司名称", "e.g. 字节跳动"],
    star: ["入职年月", "e.g. 2020-09"],
    end: ["离职年月", "e.g. 2024-06"],
    power: ["职权定位", "e.g. 产品经理"],
    desc: ["业务描述", "e.g. 短视频业务, 直播带货, 电商金融相结合..."],
    tech: ["技术栈", "e.g. SpringCloud、Redis、MySQL..."],
    work: ["工作职责", "e.g. 使用Redis缓存热点数据"],
    link: ["在线网址", "e.g. https://www.douyin.com/discover"],
  },
  pro: {
    title: "完善项目经历",
    name: ["项目名称", "e.g. 个人博客"],
    star: ["立项年月", "e.g. 2020-09"],
    end: ["完成年月", "e.g. 2024-06"],
    power: ["职权定位", "e.g. 全栈开发"],
    desc: ["项目描述", "e.g. 前后端分离的个人博客网站"],
    tech: ["技术栈", "e.g. SpringCloud、Redis、MySQL..."],
    work: ["职责范围", "e.g. 负责xxx, 完成xxx ..."],
    link: ["仓库地址", "e.g. https://github.com/brickspert/blog"],
  },
}
let editEdu = ref({
  edited: false,
  index: -1,
  model: "edu"
})
const FunFormEduOk = () => {
  copyUserInfo(false, false)
  let newEdu = []
  dynamicValidateForm.domains.filter(domain => {
    if (domain.value !== "") {
      newEdu.push(domain.value)
    }
  });
  inputFormEdu.value.work = newEdu
  formAdd()
  editEdu.value.edited = false
  editEdu.value.index = -1
  VisitAdd.value.eduAdd = false
  dynamicValidateForm.domains = []
};
const FunFormEduRead = () => {
  const readData = (editEdu.value.model === 'edu' ? userInfo.value.education : editEdu.value.model === 'job' ? userInfo.value.job : userInfo.value.project)[editEdu.value.index].work
  for (let i = 0; i < readData.length; i++) {
    dynamicValidateForm.domains.push({
      value: readData[i],
      key: Date.now(),
    });
  }
}
const FunFormEduNo = () => {
  copyUserInfo(false, true)
  editEdu.value.edited = false
  editEdu.value.index = -1
  inputFormEdu.value = {name: '', start: '', end: '', desc: '', tech: '', work: [], link: ''}
  VisitAdd.value.eduAdd = false
  dynamicValidateForm.domains = []
}

/**
 * 数据回档
 * @param model {boolean} TF: 进档, 退档
 * @param domain {boolean} TF: 取档, 删档
 */
const copyUserInfo = (model, domain = false) => {
  if (model) {
    userInfoTmp.value.push(JSON.parse(JSON.stringify(userInfo.value)))
  } else {
    if (domain) {   // 取档
      userInfo.value = JSON.parse(JSON.stringify(userInfoTmp.value.pop()));
    } else {
      userInfoTmp.value.pop()
    }
  }
};


// 简历信息数据请求相关
let resumeInfoData = ref([])
const LoadResumeInfo = ref(false)
const saveResumeInfo = async () => {
  let data
  let saveRes
  // if (fileList.value.length === 1){
  //   userInfo.value.avatar = fileList.value[0].url
  // }
  if (!editResumeData.value.edited && editResumeData.value.index === -1) {
    data = {
      resumeData: JSON.stringify(userInfo.value),
      resumeName: "测试简历",
      resumeUsed: JSON.stringify({
        public: false,
        visitNum: 0,
        job: true,
        project: true,
        education: true,
      }),
      remark: "" + Date.now()
    }
    saveRes = (await api.resumeController.addResumeInfo(data))
  } else {
    data = resumeInfoData.value[editResumeData.value.index]
    if (editResumeData.value.used){
      data.reIndex = resumeUsed.value.isMain ? 1 : 0
      data.resumeUsed = JSON.stringify(Object.assign(resumeUsed.value))
      data.resumeData = JSON.stringify(data.resumeData)
    }else{
      data.resumeData = JSON.stringify(userInfo.value)
      data.resumeUsed = JSON.stringify(data.resumeUsed)
    }
    editResumeData.value = {edited: false, index: -1, used: false}
    saveRes = (await api.resumeController.putResumeInfo(data))
  }
  if (saveRes && saveRes.msg) {
    thatRe.msg.success(saveRes.msg)
    await refResumeInfo()
    return true
  } else {
    return false
  }
}
const refResumeInfo = async () => {
  LoadResumeInfo.value = true
  let newList = []
  const listRes = (await api.resumeController.listResumeInfo())
  if (listRes && listRes.rows) {
    for (let i = 0; i < listRes.rows.length; i++) {
      listRes.rows[i].resumeData = listRes.rows[i].resumeData ? JSON.parse(listRes.rows[i].resumeData) : {}
      listRes.rows[i].resumeUsed = listRes.rows[i].resumeUsed ? JSON.parse(listRes.rows[i].resumeUsed) : {}
      newList.push(listRes.rows[i])
    }
    resumeInfoData.value = newList
    thatLocal.setItem('resumeData', newList)
  } else {
  }
  LoadResumeInfo.value = false
}
const deleteResumeInfo = async (ids) => {
  LoadResumeInfo.value = true
  if (!Array.isArray(ids)) {
    ids = [ids]
  }
  const deleteRes = (await api.resumeController.deleteResumeInfo(ids))
  LoadResumeInfo.value = false
  if (deleteRes && deleteRes.msg) {
    thatRe.msg.success(deleteRes.msg)
  } else {
    thatRe.msg.warning(deleteRes)
  }
  await refResumeInfo()
}
const editResumeInfo = async (index) => {
  const data = resumeInfoData.value[index]
  data.resumeData = JSON.stringify(data.resumeData)
  const editRes = (await api.resumeController.putResumeInfo(data))

  if (editRes && editRes.msg) {
    thatRe.msg.success(editRes.msg)
    await refResumeInfo()
  } else {
    thatRe.msg.warning(editRes)
  }
}
// 预览在线简历
const FunPreviewResume = (id) => {
  window.open(window.location.origin + "/online/" + id, "_blank")
}
// 高级配置
let resumeUsed = ref({
  public: false,
  visitNum: 0,
  isMain: false,
  job: true,
  project: true,
  education: true,
})
const FunResumeUsed = (item, index) => {
  editResumeData.value.edited = true
  editResumeData.value.index = index
  editResumeData.value.used = true
  resumeUsed.value = JSON.parse(JSON.stringify(item.resumeUsed))
  VisitPower.value = true
}
const VisitPower = ref(false)
const modalText = ref('保存');
const confirmLoading = ref(false);
const handleOk = async () => {
  modalText.value = '正在保存...';
  confirmLoading.value = true;
  const saveRes = await saveResumeInfo()
  if (saveRes){
    confirmLoading.value = false;
    VisitPower.value = false
    modalText.value = '保存';
  }else{
    modalText.value = '保存失败';
    confirmLoading.value = false;
  }
};

// 监听文件上传预检
const fileList = ref([
  {
    uid: "1",
    name: 'image.png',
    status: 'done',
    url: 'https://resumes.thatcdn.cn' + (userInfo.value?.avatar?.length > 0 ? userInfo.value.avatar : '/favicon.ico'),
  }
]);
const dataLocalFileStream = ref(null)
const FunUpdateLocalFileCheck = async fileInfo => {
  const upKey = thatRe.msg.loading("上传中...")
  if (!SufCheck.fileCheck(fileInfo, 'image', 2)) {
    fileList.value.pop()
    return
  }
  if (fileInfo && fileInfo.file) {
    dataLocalFileStream.value = fileInfo.file
    await FunUpdateLocalFileOk()
    thatRe.msg.destroy(true, upKey, "上传成功")
    // const upRes = await FileUtil.upload(file.name)
  }
}

// 监听文件上传
const FunUpdateLocalFileOk = async () => {
  LoadResumeInfo.value = true;
  const upLocalRes = await FileUtil.uploadLimit('img', dataLocalFileStream.value, '简历头像');
  userInfo.value.avatar = upLocalRes.fileUri
  // const fileData = {
  //   uid: fileList.value[0].uid,
  //   name: 'image.png',
  //   status: 'done',
  //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  // }
  fileList.value[0].url = 'https://resumes.thatcdn.cn' + upLocalRes.fileUri
  fileList.value[0].status = 'done'
  LoadResumeInfo.value = false;
};

// 简历信息操作函数
const editResumeData = ref({
  edited: false,
  index: -1,
  used: false,
})
const FunInfoToFile = (item) => {
  console.log(item)
}
const FunEditInfo = (item, index) => {
  editResumeData.value.edited = true
  editResumeData.value.index = index
  userInfo.value = JSON.parse(JSON.stringify(item.resumeData))
  VisitAdd.value.box = true
}

(async () => {
  await refResumeInfo()
})()
</script>

<script>
export default {
  name: "UserInfo"
}
</script>
<style scoped>
.UserInfo {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px; /* 控制间距 */
  padding: 3vw;
  justify-items: center;
}

.UserInfo-card {
  width: 300px !important;
  backdrop-filter: blur(10px);
  margin: 10px;
}

.UserInfo-card:hover {
  outline: none;
  backdrop-filter: none;
}

.UserInfo-card-new {
  outline: dashed 5px #e6e3ed !important;
  -moz-outline-radius: 30px;
  padding: 20px;
  background: #ebe9efc7;
  backdrop-filter: blur(20px);
}

@media (max-width: 768px) {
  .UserInfo-card {
    width: 40vw;
  }
}

@media (max-width: 480px) {
  .UserInfo-card {
    width: 90vw;
  }
}

.new-box {
  display: flex;
  justify-content: center; /* 在父容器中水平居中 */

}

.new-box-left, .new-box-right {
  flex: 1; /* 平均占据剩余空间 */
  padding: 20px 4% 4%;
}

.new-box-form {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .new-box {
    flex-direction: column; /* 在手机上垂直布局 */
  }

  .new-box-left, .new-box-right {
    width: 100%; /* 在手机上铺满整个宽度 */
  }
}
</style>