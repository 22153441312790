const FM = {
    utils: {
        getSelectNode: () => {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            // 创建自定义标签，将其设置为选中文本的范围
            const node = document.createElement('test');
            range.surroundContents(node);
            return node
        },
        getSelectionText: () => {
            const selection = window.getSelection();
            return selection.toString()
        },
        getSelection: () => {
            return window.getSelection();
        },
    },
    format: {

        /**
         * 设置标注
         */
        setLabel: () => {
            const node = FM.utils.getSelectNode()
            // answer.range.surroundContents(answer.node);
            node.style.backgroundColor = '#2dbbd421';
        },
        /**
         * 设置批注
         * @param note {string} 批注内容
         */
        setNote: (note) => {
            const node = FM.utils.getSelectNode()
            // answer.range.surroundContents(answer.node);
            node.style.backgroundColor = '#2DBBD44C';
            node.setAttribute("note", note)
        },
        /**
         * 设置划线
         */
        setLine: () => {
            const node = FM.utils.getSelectNode()
            node.classList.add("FMLine")
        },
    }
}

export default FM