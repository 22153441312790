<template>
<div class="mine">
  <div class="mine-header">
  </div>
  <div class="mine-content">
    <div class="card-container">
      <a-tabs v-model:activeKey="activeKey" tabPosition="left" type="card">
        <a-tab-pane key="1" tab="基本信息">

          <a-typography-title :level="4" style="margin: 20px">头像</a-typography-title>
          <hr>
          <div class="mine-content-item">
            <a-upload v-model:fileList="fileList" :custom-request="FunUpdateLocalFileCheck" :max-count="1"
                      list-type="picture-card">
              <div>
                <PlusOutlined/>
                <div style="margin-top: 8px">点击更新</div>
              </div>
            </a-upload>
          </div>

          <a-typography-title :level="4" style="margin: 20px">基本信息</a-typography-title>
          <hr>
          <a-form
              :model="userInfo"
              layout="horizontal"
              style="max-width: 800px;margin: 20px"
          >
            <a-form-item label="账号">
              <a-typography-paragraph v-model:content="userInfo.userName" style="margin-left: 10px;margin-bottom: 0">
              </a-typography-paragraph>
            </a-form-item>
            <a-form-item label="注册">
              <a-typography-paragraph v-model:content="userInfo.creatTime" style="margin-left: 10px;margin-bottom: 0">
              </a-typography-paragraph>
            </a-form-item>
            <a-form-item label="昵称">
              <a-typography-paragraph v-model:content="userInfo.nickName" :editable="userInfo?.userName!=='testuser'" style="margin-left: 10px;margin-bottom: 0">
                <template #editableIcon><HighlightOutlined /></template>
                <template #editableTooltip>点击编辑</template>
              </a-typography-paragraph>
            </a-form-item>
            <a-form-item label="邮箱">
              <a-typography-paragraph v-model:content="userInfo.email" :editable="userInfo?.userName!=='testuser'" style="margin-left: 10px;margin-bottom: 0">
                <template #editableIcon><HighlightOutlined /></template>
                <template #editableTooltip>点击编辑</template>
              </a-typography-paragraph>
            </a-form-item>
            <a-form-item label="电话">
              <a-typography-paragraph v-model:content="userInfo.phone" :editable="userInfo?.userName!=='testuser'" style="margin-left: 10px;margin-bottom: 0">
                <template #editableIcon><HighlightOutlined /></template>
                <template #editableTooltip>点击编辑</template>
              </a-typography-paragraph>
            </a-form-item>
          </a-form>
          <a-button :disabled="userInfo.userName==='testuser'" style="float: right;" type="primary" @click="saveUserInfo">保存</a-button>
        </a-tab-pane>

        <a-tab-pane key="2" tab="在线简历">
          <!-- 在线简历: 一言语录, 欢迎语, 个人技能, 底部留言 -->
          <a-typography-title :level="4" style="margin: 20px">一言语录</a-typography-title>
          <hr>
          <a-form
              ref="formRef2"
              :model="dynamicValidateForm2"
              name="dynamic_form_nest_item"
              @finish="onFinish2"
          >
            <a-space
                v-for="(user, index) in dynamicValidateForm2.users"
                :key="user.id"
                align="baseline"
                style="display: grid;grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));"
            >
              <a-form-item
                  :name="['users', index, 'first']"
                  :rules="{
          required: true,
          message: '不能为空',
        }"
              >
                <a-input v-model:value="user.first" placeholder="e.g. 一个有温度的简历" />
              </a-form-item>
              <a-button  danger @click="removeUser2(user)"><MinusCircleOutlined /> 删除</a-button>
            </a-space>
            <a-form-item>
              <a-button block type="dashed" @click="addUser2">
                <PlusOutlined />
                新增一条语录
              </a-button>
            </a-form-item>
          </a-form>

          <a-typography-title :level="4" style="margin: 20px">欢迎语</a-typography-title>
          <hr>
          <a-form
              :model="onlineResumeConfig"
              layout="horizontal"
              style="max-width: 800px;margin: 20px"
          >
            <a-form-item label="一句欢迎">
              <a-typography-paragraph v-model:content="onlineResumeConfig.welcome" :editable="userInfo.userName!=='testuser'" style="margin-left: 10px;margin-bottom: 0">
                <template #editableIcon><HighlightOutlined /></template>
                <template #editableTooltip>点击编辑</template>
              </a-typography-paragraph>
            </a-form-item>
          </a-form>

          <a-typography-title :level="4" style="margin: 20px">自我介绍</a-typography-title>
          <hr>
          <a-form
              :model="onlineResumeConfig"
              layout="horizontal"
              style="max-width: 800px;margin: 20px"
          >
            <a-form-item label="简短介绍">
              <a-typography-paragraph v-model:content="onlineResumeConfig.self" :editable="userInfo.userName!=='testuser'" style="height: auto;margin-left: 10px;margin-bottom: 0">
                <template #editableIcon><HighlightOutlined /></template>
                <template #editableTooltip>点击编辑</template>
              </a-typography-paragraph>
            </a-form-item>
          </a-form>

          <a-typography-title :level="4" style="margin: 20px">个人签名</a-typography-title>
          <hr>
          <a-form
              :model="onlineResumeConfig"
              layout="horizontal"
              style="max-width: 800px;margin: 20px"
          >
            <a-form-item label="个人签名">
              <a-typography-paragraph v-model:content="onlineResumeConfig.sign" editable style="height: auto;margin-left: 10px;margin-bottom: 0">
                <template #editableIcon><HighlightOutlined /></template>
                <template #editableTooltip>点击编辑</template>
              </a-typography-paragraph>
            </a-form-item>
          </a-form>

          <a-typography-title :level="4" style="margin: 20px">个人能力</a-typography-title>
          <hr>
          <a-form
              ref="formRef"
              :model="dynamicValidateForm"
              name="dynamic_form_nest_item"
              @finish="onFinish"
          >
            <a-space
                v-for="(user, index) in dynamicValidateForm.users"
                :key="user.id"
                align="baseline"
                style="display: grid;grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));"
            >
              <a-form-item
                  :name="['users', index, 'first']"
                  :rules="{
          required: true,
          message: '名称不能为空',
        }"
              >
                <a-input v-model:value="user.first" placeholder="能力名称" />
              </a-form-item>
              <a-form-item
                  :name="['users', index, 'last']"
                  :rules="{
          required: true,
          message: '描述不能为空',
        }"
              >
                <a-input v-model:value="user.last" placeholder="能力描述" />
              </a-form-item>
              <a-form-item
                  :name="['users', index, 'num']"
              >
                <a-row>
                  <a-slider v-model:value="user.num" :max="100" :min="0" style="width:70%" />
<!--                  <a-input-number style="width: 20%;margin-left: 2%" v-model:value="user.num" :min="0" :max="100" />-->
                </a-row>
              </a-form-item>
<!--              <MinusCircleOutlined @click="removeUser(user)" />-->
              <a-button  danger @click="removeUser(user)"><MinusCircleOutlined /> 删除</a-button>
            </a-space>
            <a-form-item>
              <a-button block type="dashed" @click="addUser">
                <PlusOutlined />
                新增一条能力
              </a-button>
            </a-form-item>
          </a-form>

          <a-typography-title :level="5" style="margin: 20px">底部留言</a-typography-title>
          <hr>
          <a-form
              :model="onlineResumeConfig"
              layout="horizontal"
              style="max-width: 800px;margin: 20px"
          >
            <a-form-item label="底部留言">
              <a-typography-paragraph v-model:content="onlineResumeConfig.message" :editable="userInfo.userName!=='testuser'" style="margin-left: 10px;margin-bottom: 0">
                <template #editableIcon><HighlightOutlined /></template>
                <template #editableTooltip>点击编辑</template>
              </a-typography-paragraph>
            </a-form-item>
          </a-form>

          <a-button :disabled="userInfo.userName==='testuser'" style="float: right;" type="primary" @click="saveOnlineConfig">保存</a-button>
        </a-tab-pane>

        <a-tab-pane key="3" tab="更多功能">
          <p>敬请期待</p>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
  <div class="mine-footer"></div>
</div>
</template>

<script setup>
import {reactive, ref} from 'vue';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import SufCheck from "@/utils/SufCheck";
import FileUtil from "@/utils/exportDocx";
import thatLocal from "@/utils/thatLocal";
import {HighlightOutlined} from "@ant-design/icons-vue";
import api from "@/api/controller";
import {GlobalReceipt} from "@/utils/thatRe";
import router from "@/router";

const thatRe = GlobalReceipt()
const activeKey = ref('1');
const localInfo = thatLocal.getItem("userInfo");
// 用户账号信息
const userInfo = ref({
  userName: localInfo.userName,
  nickName: localInfo.nickName,
  email: localInfo.email,
  phone: localInfo.phonenumber,
  creatTime: localInfo.createTime,
  avatar: localInfo.avatar,
})
const onlineResume = ref({})
// 在线简历配置
const onlineResumeConfig = ref({
  // 一言语录
  signs: ["希望能成为有趣的人", "给时光以生命<br>给岁月以文明", "你好，请多指教", "当你在凝视着网页的时候<br>网页也正在凝视着你", "苟……", "平凡的日常正奇迹的发生着", "Lata<br>lulila<br>lulula<br>lulalila ♪♫", "搞事！搞事！搞事！", "敬畏之心！", "赞美之心！", "我很好奇！"],
  // 欢迎语
  welcome: "很感谢你花时间认识我!",
  // 自我介绍
  self: "您好，我是张三，是的，您没听错，就是那个人尽皆知，家喻户晓的张三。每个人都认为他们了解张三，但实际上，我可不只是一个平淡无奇的名字。\n" +
      "我是书的亲密伙伴，我热爱字句，崇尚诗歌，无论是飘渺的诗句云烟，还是硬朗的散文铁石，都在我心中激起阵阵涟漪。古人云：书犹草衣，我以文为昵，以墨为食。毛泽东的豪情壮志，庄子的逍遥自在，老舍的平民情怀，我都能豪情满怀的津津乐道。",
  sign: "浪漫主义的花会盛开在理想主义的枝头上，理想主义的根永远扎在现实的土壤之中。",
  // 个人技能
  skills: [
    {
      name: '茶艺',
      desc: '一茶底一世界',
      level: 90,
    },
    {
      name: '摄影',
      desc: '一张照片一生',
      level: 70,
    }
  ],
  // 底部留言
  message: "简历太短, 我的故事挺长, 不妨来聊聊 ~",
})
const formRef = ref();
const dynamicValidateForm = reactive({
  users: [
      {
        first: '茶艺',
        last: '一茶底一世界',
        num: 90,
        id: Date.now()
      },
      {
        first: '摄影',
        last: '一张照片一生',
        num: 70,
        id: Date.now()
      }
  ],
});
const removeUser = item => {
  const index = dynamicValidateForm.users.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.users.splice(index, 1);
  }
};
const addUser = () => {
  dynamicValidateForm.users.push({
    first: '',
    last: '',
    num: 50,
    id: Date.now(),
  });
};
const onFinish = values => {
  if (dynamicValidateForm.users.length === 0){
    onlineResumeConfig.value.skills = []
  }else{
    onlineResumeConfig.value.skills = []
    dynamicValidateForm.users.forEach(item => {
      onlineResumeConfig.value.skills.push({name: item.first, desc: item.last, level: item.num});
    });
  }
};
const formRef2 = ref();
const dynamicValidateForm2 = reactive({
  users: [
    {
      first: '给时光以生命<br>给岁月以文明',
      id: Date.now()
    },
    {
      first: '当你在凝视着网页的时候<br>网页也正在凝视着你',
      id: Date.now()
    },
    {
      first: '你好，请多指教',
      id: Date.now()
    },
  ],
});
const removeUser2 = item => {
  const index = dynamicValidateForm2.users.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm2.users.splice(index, 1);
  }
};
const addUser2 = () => {
  dynamicValidateForm2.users.push({
    first: '',
    last: '',
    num: 50,
    id: Date.now(),
  });
};
const onFinish2 = values => {
  if (dynamicValidateForm2.users.length === 0){
    onlineResumeConfig.value.signs = []
  }else{
    onlineResumeConfig.value.signs = []
    dynamicValidateForm2.users.forEach(item => {
      onlineResumeConfig.value.signs.push(item.first);
    });
  }
};

// 监听文件上传预检
const fileList = ref([{
    uid: "1",
    name: 'image.png',
    status: 'done',
    url: (userInfo.value?.avatar?.length > 0 ? userInfo.value.avatar : 'https://resumes.thatcdn.cn' + '/favicon.ico'),
}]);
const dataLocalFileStream = ref(null)
const FunUpdateLocalFileCheck = async fileInfo => {
  if (!SufCheck.fileCheck(fileInfo, 'image', 2)) {
    fileList.value.pop()
    return
  }
  if (fileInfo && fileInfo.file) {
    dataLocalFileStream.value = fileInfo.file
    await FunUpdateLocalFileOk()
    // const upRes = await FileUtil.upload(file.name)
  }
}

// 监听文件上传
const FunUpdateLocalFileOk = async () => {
  const upLocalRes = await FileUtil.uploadLimit('img', dataLocalFileStream.value, '用户头像');
  // const fileData = {
  //   uid: fileList.value[0].uid,
  //   name: 'image.png',
  //   status: 'done',
  //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  // }
  fileList.value[0].url = 'https://resumes.thatcdn.cn' + upLocalRes.fileUri
  fileList.value[0].status = 'done'
};

const saveUserInfo = async () => {
  const loadKey = thatRe.msg.loading("保存中...")
   // 保存用户信息
  const data = {
    userId: localInfo.userId,
    nickName: userInfo.value.nickName,
    email: userInfo.value.email,
    phonenumber: userInfo.value.phone,
    avatar: fileList.value[0].url,
  }
  const putRes = await api.userController.putUserInfo(data)
  if (putRes.code === 200) {
    thatLocal.setItem("avatar", fileList.value[0].url)
    thatLocal.setItem("nickName", userInfo.value.nickName)
    let uf = thatLocal.getItem("userInfo");
    uf.avatar = fileList.value[0].url;
    uf.nickName = userInfo.value.nickName;
    uf.phonenumber = userInfo.value.phone;
    uf.email = userInfo.value.email;
    thatLocal.setItem("userInfo", uf)
    thatRe.msg.destroy(true, loadKey, "保存成功")
  }else {
    thatRe.msg.destroy(false, loadKey, "保存失败")
  }

}

const saveOnlineConfig = async () => {
  onFinish()
  onFinish2()
  const loadKey = thatRe.msg.loading("保存中...")
  // 保存在线简历配置
  const data = {
    id: onlineResume.value.id,
    userId: onlineResume.value.userId,
    onlineWelcome: onlineResumeConfig.value.welcome,
    onlineMessage: onlineResumeConfig.value.message,
    onlineSelf: onlineResumeConfig.value.self,
    onlineMore: JSON.stringify(onlineResumeConfig.value),
  }
  const putRes = await api.resumeController.updateOnline(data)
  if (putRes.code === 200) {
    await initData()
    thatRe.msg.destroy(true, loadKey, "保存成功")
  }else {
    thatRe.msg.destroy(false, loadKey, "保存失败")
  }

}
const initData = async () => {
  const configRes = await api.resumeController.getOnline()
  if (configRes.rows.length === 0){
    const data = {
      onlineWelcome: onlineResumeConfig.value.welcome,
      onlineMessage: onlineResumeConfig.value.message,
      onlineSelf: onlineResumeConfig.value.self,
      onlineMore: JSON.stringify(onlineResumeConfig.value),
    }
    await api.resumeController.addOnline(data)
    await initData()
  }else {
    onlineResume.value = configRes.rows[0];
    onlineResumeConfig.value.welcome = onlineResume.value.onlineWelcome
    onlineResumeConfig.value.message = onlineResume.value.onlineMessage
    onlineResumeConfig.value.self = onlineResume.value.onlineSelf
    onlineResumeConfig.value.skills = JSON.parse(onlineResume.value.onlineMore).skills
    onlineResumeConfig.value.signs = JSON.parse(onlineResume.value.onlineMore).signs
  }

}

(async () => {
  await initData()
})()
</script>
<script>
export default {
  name: "Mine"
}
</script>
<style scoped>
.mine {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 20%;
  margin-top: 5vh;
}

.mine-content{
  max-width: 1280px;
}
.mine-content-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.mine-content-item h4{
  position: relative;
  float: left !important;
  box-shadow: 0 0 0 1px #d9d9d9;
}

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: rgba(255, 255, 255, 0.78);
  border-radius: 8px;
  margin-left: 1vw;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
</style>