const api = require('@/api/controller');

class StorageSign {
    id = -1;
    fileId = -1;
    fileUri = "";
    sign = "";
    signData = "";
    status = 0;

    constructor(storageSign) {
        this.id = storageSign.id;
        this.fileId = storageSign.fileId;
        this.fileUri = storageSign.fileUri;
        this.sign = storageSign.sign;
        this.signData = storageSign.signData;
        this.status = storageSign.status;
    }

    toJSON() {
        return {
            id: this.id,
            fileId: this.fileId,
            fileUri: this.fileUri,
            sign: this.sign,
            signData: this.signData,
            status: this.status
        }
    }

    async save() {
        return await api.storageController.callback.putFile(this.toJSON())
    }
}

export default StorageSign;