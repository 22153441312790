<template>
<!--  <button v-if="load" @click="toggleFullscreen">全屏</button>-->
  <div v-if="load" id="officeBox" class="officeBox"  style="width: 100%;height: 100%;padding: 0;margin: 0;z-index:99999999">
    <DocumentEditor
        id="docEditor"
        :key="documentKey"
        :config="editorConfig"
        :documentServerUrl="documentSite"
        :events_onDocumentReady="onDocumentReady"
        :style="{
            width: '100%',
            height: '100%',
            margin: '0',
            padding: '0',
            zIndex: '99999999'
        }"
    />
  </div>
</template>

<script setup>
import {DocumentEditor} from "@onlyoffice/document-editor-vue";
import {inject, onBeforeMount, onMounted, ref} from "vue";
import axios from "axios";
import thatLocal from "@/utils/thatLocal";
import {onBeforeRouteUpdate, useRoute} from "vue-router";

const documentUrl = ref();
const documentType = ref('docx');
const documentName = ref('your-document-name');
const documentKey = ref('your-document-key');
const documentSite = 'https://office.thatcoder.cn/'
const isMobile = inject('isMobile');
const RMenuCurrent = inject('RMenuCurrent');
RMenuCurrent.value = ['edit'];

const onDocumentReady = (e) => {
  console.log('Document is ready: ', e);
};

const onDocumentStateChange = (event) => {
  if (event.data === 'onDocumentStateChange') {
    saveDocument();
  }
};

const onRequestSaveAs = (event) => {
  // 处理文档另存为的回调事件
  console.log('Document save as requested');
  // 执行文档另存为的逻辑,例如弹出保存对话框等
  // ...
};

const onRequestInsertImage = (event) => {
  // 处理插入图片的回调事件
  console.log('Insert image requested');
  // 执行插入图片的逻辑,例如打开图片选择器等
  // ...
};

const saveDocument = () => {
  axios.post('https://resume.app.thatcoder.cn/save-document', {
    documentUrl: documentUrl.value,
    documentType: documentType.value,
    documentKey: documentKey.value,
    type: isMobile.value ? 'mobile' : 'desktop',  // mobile embedded or desktop
  })
      .then(response => {
        console.log('Document saved successfully');
      })
      .catch(error => {
        console.error('Document save error:', error);
      });
};
const editorConfig = ref({
  editorConfig: {
    mode: 'edit',
    lang: 'zh',
    customization: {
      anonymous: {
        request: true,
        label: "Guest"
      },
      autosave: false,
      comments: true,
      compactHeader: true,
      compactToolbar: false,
      compatibleFeatures: true,
      customer: {
        address: "A small corner of China",
        info: "A member of the open source spirit of the Internet",
        logo: "https://resume.app.thatcoder.cn/favicon.ico",
        logoDark: "https://resume.app.thatcoder.cn/favicon.ico",
        mail: "thatcoder@163.com",
        name: "钟意",
        phone: 13305374721,
        www: "https://blog.thatcoder.cn"
      },
      forcesave: false,
      goback: {
        blank: true,
        requestClose: false,
        text: "回到简历列表",
        url: "https://resume.app.thatcoder.cn/mine/folder"
      },
      help: false,
      hideRightMenu: false,
      hideRulers: true,
      integrationMode: "embed",
      logo: {
        image: "https://resume.app.thatcoder.cn/favicon.ico",
        imageDark: "https://resume.app.thatcoder.cn/favicon.ico",
        imageEmbedded: "https://resume.app.thatcoder.cn/favicon.ico",
        url: "https://resume.app.thatcoder.cn"
      },
      macros: true,
      macrosMode: "禁用",
      mentionShare: false,
      mobileForceView: true,
      plugins: false,
      toolbarHideFileName: true,
      toolbarNoTabs: true,
      // uiTheme: "theme-dark",
      unit: "厘米",
      zoom: 100
    },
    // callbackUrl: 'https://resume.app.thatcoder.cn/onlyoffice-callback',
    callbackUrl: 'https://resume.thatapi.cn/office/callback',
  },
  document: {
    title: documentName,
    url: documentUrl,
    fileType: documentType,
    key: documentKey,
    info: {
      favorite: false,
      folder: "" + thatLocal.getItem('userId'),
      owner: "" + thatLocal.getItem('userName'),
      sharingSettings: [
        {
          permissions: "Full Access",
          user: "" + thatLocal.getItem('userName')
        }
      ],
      uploaded: "2010-07-07 3:46 PM"
    }
  },
  events: {
    onReady: onDocumentReady,
    // onDocumentStateChange: onDocumentStateChange,
    onRequestSaveAs: onRequestSaveAs,
    // onRequestInsertImage: onRequestInsertImage,
    // 添加其他需要处理的回调事件
  },
  // documentServerUrl: 'https://office.thatcoder.cn', // 指定 ONLYOFFICE 服务器的网址
});

const toggleFullscreen = () => {
  const officeBox = document.getElementById('officeBox');
  if (!document.fullscreenElement) {
    officeBox.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

const uRoute = useRoute()
const fileId = ref(0)
const load = ref(false)
// onMounted();
onMounted(() => {
  const query = uRoute.query
  if (query?.fileUri && query?.id) {
    const url = "https://resumes.thatcdn.cn" + query.fileUri
    documentUrl.value = url
    editorConfig.value.document.url = url
    fileId.value = query.id
    documentType.value = query.fileType
    documentName.value = query.fileName
    documentKey.value = thatLocal.getItem("userName") + "-document-" + query.id
    load.value = true
  }else {
  }
})
</script>

<style scoped>
.officeBox{
  width: 100%;
  height: 80vh;
  padding: 0;
  margin: 0;
}
</style>