const rewriteRules = {
    '/proxy/service': 'https://api.thatcoder.cn/public/resume'
};

const applyRewrite = (url) => {
    for (const key in rewriteRules) {
        if (url.startsWith(key)) {
            return rewriteRules[key] + url.substring(key.length);
        }
    }
    return url;
};

module.exports = {applyRewrite};
