import {createMyApp} from './app';
import './registerServiceWorker';

const app = createMyApp();

// if (process.env.NODE_ENV === 'development') {
//     app.config.warnHandler = function (msg, vm, trace) {
//         // 忽略特定的警告信息
//         if (msg.includes('ResizeObserver loop limit exceeded')) {
//             return;
//         }
//         console.warn(`[Vue warn]: ${msg}${trace}`);
//     };
// }

app.mount("#app");

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}


export default app;