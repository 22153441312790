import api from "@/api/controller";
import StorageSign from "@/types/storageSign";
import msgger from "@/utils/ThatMsg";

const PizZip = require('pizzip');
const Docxtemplater = require('docxtemplater');
const JSZipUtils = require('jszip-utils')

const FileUtil = {
    export: async (info, template_id = '001') => {
        let file = null;

        let src = "";
        if (!template_id.startsWith('http')) {
            src = `/docs/resumes_${template_id}.docx`
        }else {
            src = template_id
        }
        try {
            const content = await JSZipUtils.getBinaryContent(src);
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip);

            await doc.setData(info);
            await doc.render();

            const out = doc.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            });

            file = out;
        } catch (error) {
            console.error('Error fetching or rendering template:', error);
        }

        return file;
    },
    /**
     * 上传制作简历文件
     * @param fileName 文件名称
     * @param remark 备注
     * @param userInfo 模板简历信息
     * @param file 文件流或模板ID
     * @returns {Promise<void>}
     */
    upload: async (fileName, remark, userInfo, file) => {

        const suffix = file?.name?.split('.') || ["docx"]
        let fileStream = null
        if (typeof file === 'string') {
            fileStream = await FileUtil.export(userInfo, file)
            msgger.success({
                msg: "模板填充完成，正在上传..."
            })
        } else {
            fileStream = file
        }

        if (!fileStream) {  // 导出失败
            msgger.error({
                msg: "导出失败！"
            })
            return
        }
        const sign = await api.storageController.sign.putFile('docx', suffix[suffix.length - 1])
        if (sign["code"] !== 200) {  // 签名失败
            return
        }
        const upRes = await api.upyunController.putFile(sign["data"], fileStream)
        if (upRes["code"] !== 200) {  // 上传失败
            return
        }
        const storageSign = new StorageSign(sign["data"])
        storageSign.status = 1
        storageSign.sign = fileName
        storageSign.signData = remark
        const saveRes = await storageSign.save()
        if (saveRes["code"] !== 200) {  // 回执失败
            msgger.error({
                msg: "操作失败，请稍后再试！"
            })
            return
        }
        console.log(saveRes["data"])
        let msg = null
        if (!userInfo && file && typeof file !== 'string') {
            msg = "简历上传成功：\n" + saveRes["data"].fileName
        } else if (userInfo && file && typeof file == 'string') {
            msg = "简历生成成功：\n" + saveRes["data"].fileName
        } else {
            msg = "简历保存成功：\n" + saveRes["data"].fileName
        }
        msgger.success({
            msg
        })
        return saveRes["data"]
    },
    /**
     * 上传文件到又拍云，限制上传类型
     * @param type 上传类型
     * @param fileStream 文件流
     * @param fileName 文件名称
     * @param remark 备注
     * @returns {Promise<*>}
     */
    uploadLimit: async (type, fileStream,  remark=null, fileName='111') => {
        const suffix = fileStream?.name.split('.') || [type]
        const sign = await api.storageController.sign.putFile(type, suffix[suffix.length - 1])
        if (sign["code"] !== 200) {  // 签名失败
            return
        }
        const upRes = await api.upyunController.putFile(sign["data"], fileStream)
        if (upRes["code"] !== 200) {  // 上传失败
            return
        }
        const storageSign = new StorageSign(sign["data"])
        storageSign.status = 1
        storageSign.sign = fileName
        storageSign.signData = remark
        const saveRes = await storageSign.save()
        if (saveRes["code"] !== 200) {  // 回执失败
            msgger.error({
                msg: "操作失败，请稍后再试！"
            })
            return
        }
        console.log(saveRes["data"])
        return saveRes["data"]
    }
};

/**
 * 上传文件至又拍云
 * @param fileName
 * @param userInfo
 * @returns {Promise<void>}
 * @constructor
 */

export default FileUtil