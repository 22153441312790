/**
 * 这是 thatLocal.js 文件，它包含了 localStorage 的相关操作函数。
 */

const keyPrefix = 'tc_';

/**
 * 这是设置 localStorage 键值对的函数
 * @param {string} key - 键
 * @param {any} value - 值
 */
function setItem(key, value) {
    localStorage.setItem(keyPrefix + key, JSON.stringify(value));
}

/**
 * 这是获取 localStorage 键值对的函数
 * @param {string} key - 键
 * @returns {any} - 值
 */
function getItem(key) {
    const value = localStorage.getItem(keyPrefix + key);
    return value && JSON.parse(value);
}


/**
 * 这是删除 localStorage 键值对的函数
 * @param {string} key - 键
 */
function removeItem(key) {
    localStorage.removeItem(keyPrefix + key);
}

/**
 * 这是清空 localStorage 的函数
 */
function clear() {
    localStorage.clear();
}

/**
 * 这是获取 localStorage 中所有键的函数
 * @returns {string[]} - 键数组
 */
function keys() {
    const prefixLength = keyPrefix.length;
    return Object.keys(localStorage).filter(key => key.startsWith(keyPrefix)).map(key => key.slice(prefixLength));
}

/**
 * 这是获取 localStorage 中所有值的函数
 * @returns {any[]} - 值数组
 */
function values() {
    return keys().map(key => getItem(key));
}


/**
 * 这是获取 localStorage 中所有键值对的函数
 * @returns {Object} - 键值对对象
 */
function entries() {
    const result = {};
    keys().forEach(key => {
        result[key] = getItem(key);
    });
    return result;
}


/**
 * 这是判断 localStorage 是否为空的函数
 * @returns {boolean} - 是否为空
 */
function isEmpty() {
    return keys().length === 0;
}

/**
 * 这是获取 localStorage 中键的数量的函数
 * @returns {number} - 键的数量
 */
function size() {
    return keys().length;
}

/**
 * 这是获取 localStorage 中某个键的类型
 * @param {string} key - 键
 * @returns {string} - 类型
 */
function getType(key) {
    const value = getItem(key);
    return Object.prototype.toString.call(value).slice(8, -1);
}

/**
 * 这是获取 localStorage 中某个键的长度
 * @param {string} key - 键
 * @returns {number} - 长度
 */
function getLength(key) {
    const value = getItem(key);
    return value.length;
}

/**
 * 这是获取 localStorage 中某个键的最后修改时间
 * @param {string} key - 键
 * @returns {number} - 最后修改时间
 */
function getLastModified(key) {
    return localStorage.getItem(keyPrefix + key + '_lastModified');
}

/**
 * 这是设置 localStorage 中某个键的最后修改时间
 * @param {string} key - 键
 * @param {number} time - 最后修改时间
 */
function setLastModified(key, time) {
    localStorage.setItem(keyPrefix + key + '_lastModified', time);
}

module.exports = thatLocal = {
    setItem,
    getItem,
    removeItem,
    clear,
    keys,
    values,
    entries,
    isEmpty,
    size,
    getType,
    getLength,
    getLastModified,
    setLastModified
};
