const {proAxios} = require('@/api/MyAxios')
const proxyObject = require("@/api/proxy/ProxyObject");
const axios = require("axios");
const {head} = require("axios");

const proxyUrl = '/proxy/cloud';
const bucket = 'app-resumes';

const cloudUrlBase = {
    normal: '',
};

const cloudUrl = proxyObject(cloudUrlBase, proxyUrl);

const cloudHeaders = (sign, date, length) => {
    return {
        'Authorization': sign,
        'Date': "" + date,
        'Content-Length': length,
    };
}

/**
 * 上传文件
 * @param storage 签名实体
 * @param fileStream
 * @returns {Promise<*>}
 */
const putFile = async (storage, fileStream) => {
    const {sign, signData} = storage
    const data = new FormData();
    data.append('file', fileStream);
    data.append('policy', signData);
    data.append('authorization', sign);
    const putRes = await proAxios({
        method: 'POST',
        url: cloudUrl.normal,
        data,
    })
    // const putRes =  await axios.post(cloudUrl.normal, data,{
    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // })
    return putRes.Sources
}

module.exports = upyunController = {
    putFile
}
