const {proAxios} = require('@/api/MyAxios')
const proxyObject = require("@/api/proxy/ProxyObject");

const proxyUrl = 'https://resume.thatapi.cn/ai';

const aiUrlBase = {
    chat: '/chat',
};

const aiUrl = proxyObject(aiUrlBase, proxyUrl);

const chat = async (question) => {
    const chatRes = (await proAxios({
        url: aiUrl.chat,
        method: 'post',
        data: {question}
    }));
    if (chatRes.Then) {
        return chatRes.Sources
    } else {
        return false
    }
}


module.exports = userController = {
    chat
}
