<template>
<div class="discuss-main">
  <div class="discuss-banner">

  </div>
  <div class="discuss-content">
    <div class="discuss-pull">
      <a-tabs v-model:activeKey="activeKeyDiscuss" :centered="true"  :destroyInactiveTabPane="true" tabPosition="left" type="card" @change="changeDiscussList">
        <a-tab-pane v-if="!discussMore" key="1" tab="面经分享" >
          <ADiscussList :ListSizeKey="'1'" />
        </a-tab-pane>

        <a-tab-pane v-if="!discussMore" key="2" tab="等你来答" >
          <ADiscussList :ListSizeKey="'2'" />
        </a-tab-pane>

        <a-tab-pane v-if="!discussMore" key="3" tab="求职推荐" >
          <ADiscussList :ListSizeKey="'3'"  />
        </a-tab-pane>

        <a-tab-pane v-if="!discussMore" key="4" tab="灌水闲聊" >
          <ADiscussList :ListSizeKey="'4'" />
        </a-tab-pane>

        <a-tab-pane v-if="!discussMore" key="5" tab="我的话题" >
          <ADiscussList :ListSizeKey="'5'" />
        </a-tab-pane>

        <a-tab-pane v-if="!discussMore" key="6" tab="收藏话题" >
          <ADiscussList  :ListSizeKey="'6'" />
        </a-tab-pane>

        <a-tab-pane v-if="!discussMore" key="7" tab="我的评论" >
          <ACommentList v-if="commentMyList?.length > 0" :comments="commentMyList" />
        </a-tab-pane>


        <a-tab-pane v-if="discussMore && discussMoreInfo?.reDiscuss?.title" key="8" tab="话题详情">
          <a-page-header
              :title="discussMoreInfo?.reDiscuss.title"
              class="site-page-header"
              style="background: rgb(168 164 157 / 25%);border-radius: 16px;margin: 1rem"
          >
            <template v-if="discussMoreInfo?.reDiscuss?.tag?.length > 0" #tags>
              <a-tag color="blue">{{discussMoreInfo?.reDiscuss.tag}}</a-tag>
            </template>
            <template #extra>
<!--              <a-popconfirm-->
<!--                  :title="isLike? '确定取消收藏？' : '确定收藏？'"-->
<!--                  :ok-text="isLike? '取消收藏' : '收藏'"-->
<!--                  cancel-text="点错了"-->
<!--                  @confirm="isLike ? deleteDiscussLike(discussMoreInfo?.reDiscuss.id) : addDiscussLike()"-->
<!--              >-->
              <a-button v-if="discussMoreInfo?.userId === thatLocal.getItem('userId')" size="small" @click="FshowEditDiscuss">
                <edit-two-tone />
                修改内容
              </a-button>
              <a-button key="2" :disabled="loadLike" :loading="loadLike"  :type="isLike? 'default' : 'primary'" size="small" @click="isLike ? deleteDiscussLike(discussMoreInfo?.reDiscuss.id) : addDiscussLike()"><StarTwoTone v-if="!loadLike" two-tone-color="#ffae00" />
                {{ loadLike ? isLike ? '移除中...' : '收藏中...' : isLike ? '已加收藏' : '加入收藏' }}
              </a-button>
<!--              </a-popconfirm>-->
<!--              <a-button v-else @click="addDiscussLike" key="1" size="small" type="primary"><StarTwoTone two-tone-color="#ffae00" />加入收藏</a-button>-->
              <a-button v-if="discussMoreInfo?.reDiscuss?.id" key="3" size="small" style="margin: 1rem" type="primary" @click="FormCommentAdd.reId=0;FormCommentAdd.reUserId=0;showAddComment=true"><MessageTwoTone />发表评论</a-button>
            </template>
            <a-avatar :alt="discussMoreInfo?.userName || discussMoreInfo?.userId || '匿名用户'" :src="discussMoreInfo?.userAvatar || '/favicon.ico'" style="width: 4rem;height: 4rem;z-index:2;" />
            <span style="margin-left: 1rem;font-size: 1rem;font-weight: bold;margin-top: -1rem;">作者：{{discussMoreInfo?.nickName || discussMoreInfo?.userName || discussMoreInfo?.userId || '匿名用户'}}</span>
            <span style="margin-left: 1rem;font-size: .8rem;margin-top: -1rem;">发布时间：{{discussMoreInfo?.reDiscuss?.createTime}}</span>
            <a-row class="content" style="margin-top: -1.4rem;background-color: rgba(237, 143, 65, 0.09); /* 添加背景色 */border-radius: 20px;z-index: 1;padding: 1rem;">
                <pre class="tabs-discuss-info" style="white-space: pre-wrap;font-family: 'LXGW WenKai Mono Lite';font-size: larger;margin-top: 2rem;">{{contentTrim(discussMoreInfo?.reDiscuss.content)}}</pre>
            </a-row>
          </a-page-header>
        </a-tab-pane>

        <template #leftExtra>
          <a-button v-if="!discussMore" style="margin: 0 0 0.5rem 0" @click="showAddDiscuss=true"><EditTwoTone />发布话题</a-button>
          <br>
          <a-button v-if="discussMore" style="margin: 0 0 0.5rem 0" type="primary" @click="goBackDiscussList"><LeftSquareTwoTone />返回列表</a-button>
        </template>
        <template #rightExtra>
        </template>

      </a-tabs>
    </div>
    <div v-if="discussMoreInfo?.reDiscuss?.id" class="discuss-comment tabs-discuss-info">
      <a-spin :spinning="loadingComment" class="welcome-loading" size="large">
        <ACommentList  v-if="commentList?.length > 0" :comments="commentList"/>
        <a-comment v-else>
          <template #author>
            <a>小意</a>
          </template>
          <template #avatar>
            <a-avatar alt="匿名用户" src="/favicon.ico" />
          </template>
          <template #content>
            <p>暂无评论, 快来抢沙发！</p>
          </template>
        </a-comment>
      </a-spin>
<!--      <a-tabs v-model:activeKey="activeKeyComment" tabPosition="right" type="card">-->
<!--        <a-tab-pane v-if="discussMoreInfo?.id" key="1" tab="评论区">-->
<!--        </a-tab-pane>-->

<!--        <a-tab-pane v-else key="2" tab="评论区">-->
<!--          <p>查看详情展开评论</p>-->
<!--        </a-tab-pane>-->

<!--        <template #leftExtra>-->
<!--          <a-button @click="showAddComment=true" v-if="discussMoreInfo?.id" style="margin: 1rem"><MessageTwoTone />发表评论</a-button>-->
<!--        </template>-->
<!--      </a-tabs>-->
    </div>
  </div>
</div>

<!--  发布话题弹窗-->
  <a-modal
      v-model:open="showAddDiscuss"
      :closable="false"
      :close-icon="CloseCircleTwoTone"
      :confirm-loading="submittingAddDiscuss"
      :mask-closable="false"
      :ok-text="submittingAddDiscuss? '正在发布...' : '发布'"
      cancel-text="还没想好"
      centered
      style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;"
      @cancel="showAddDiscuss=false"
      @close="showAddDiscuss=false"
      @ok="SubmitAddDiscuss"
  >
    <a-spin :spinning="submittingAddDiscuss" class="welcome-loading" size="large">
    <a-form :model="FormDiscussAdd">
      <a-form-item label="话题标题" name="title">
        <a-input v-model:value="FormDiscussAdd.title" placeholder="e.g. 字节跳动面经分享" />
      </a-form-item>
      <a-form-item label="话题内容" name="content">
        <a-textarea v-model:value="FormDiscussAdd.content" placeholder="e.g. 题目、面试官、面试经历、面试技巧、面试心得、面试感受等" />
      </a-form-item>
      <a-form-item label="话题分类" name="category">
        <a-select v-model:value="FormDiscussAdd.category">
          <a-select-option value="面经分享">面经分享</a-select-option>
          <a-select-option value="等你来答">等你来答</a-select-option>
          <a-select-option value="求职推荐">求职推荐</a-select-option>
          <a-select-option value="灌水闲聊">灌水闲聊</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="话题标签" name="tag">
        <a-input v-model:value="FormDiscussAdd.tag" placeholder="e.g. 字节跳动" />
      </a-form-item>
    </a-form>
    </a-spin>
  </a-modal>

  <!--  修改话题弹窗-->
  <a-modal
      v-model:open="showEditDiscuss"
      :closable="false"
      :close-icon="CloseCircleTwoTone"
      :confirm-loading="submittingAddDiscuss"
      :mask-closable="false"
      :ok-text="submittingAddDiscuss? '正在修改...' : '修改'"
      cancel-text="还没想好"
      centered
      style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;"
      @cancel="showEditDiscuss=false"
      @close="showEditDiscuss=false"
      @ok="SubmitEditDiscuss"
  >
    <a-spin :spinning="submittingAddDiscuss" class="welcome-loading" size="large">
      <a-form :model="FormEditDiscussAdd">
        <a-form-item label="话题标题" name="title">
          <a-input v-model:value="FormEditDiscussAdd.title" placeholder="e.g. 字节跳动面经分享" />
        </a-form-item>
        <a-form-item label="话题内容" name="content">
          <a-textarea v-model:value="FormEditDiscussAdd.content" placeholder="e.g. 题目、面试官、面试经历、面试技巧、面试心得、面试感受等" />
        </a-form-item>
        <a-form-item label="话题分类" name="category">
          <a-select v-model:value="FormEditDiscussAdd.category">
            <a-select-option value="面经分享">面经分享</a-select-option>
            <a-select-option value="等你来答">等你来答</a-select-option>
            <a-select-option value="求职推荐">求职推荐</a-select-option>
            <a-select-option value="灌水闲聊">灌水闲聊</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="话题标签" name="tag">
          <a-input v-model:value="FormEditDiscussAdd.tag" placeholder="e.g. 字节跳动" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>

  <!--  发布评论弹窗-->
  <a-modal
      v-model:open="showAddComment"
      :closable="false"
      :close-icon="CloseCircleTwoTone"
      :confirm-loading="submittingAddDiscuss"
      :mask-closable="false"
      :ok-text="submittingAddDiscuss? '正在评论...' : '评论'"
      cancel-text="还没想好"
      centered
      style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;"
      @cancel="showAddComment=false"
      @close="showAddComment=false"
      @ok="SubmitAddComment"
  >
    <a-spin :spinning="submittingAddDiscuss" class="welcome-loading" size="large">
      <a-form :model="FormCommentAdd">
        <a-form-item label="评论内容" name="content">
          <a-textarea v-model:value="FormCommentAdd.content" placeholder="e.g. 文明用语、有价值观、有见地、有深度、有创意" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script setup>
import {computed, inject, provide, ref} from "vue";
import {CloseCircleTwoTone, EditTwoTone, LeftSquareTwoTone, MessageTwoTone ,CrownTwoTone,  StarTwoTone ,NotificationTwoTone, AlertTwoTone } from "@ant-design/icons-vue";
import ACommentList from "@/components/ACommentList";
import thatLocal from "@/utils/thatLocal";
import {GlobalReceipt} from "@/utils/thatRe";
import {request} from "axios";
import ADiscussList from "@/components/ADiscussList.vue";
const RMenuCurrent = inject('RMenuCurrent');
RMenuCurrent.value = ['discuss'];
const api = require('@/api/controller');
const thatRe = GlobalReceipt();
const discussMore = ref(false)
const discussMoreInfo = ref({})
const activeKeyDiscuss = ref('1');
const activeKeyComment = ref('2');
const listSize = ref({
  page: 1,
  pageSize: 4,
  activeDiscussKey: "1"
});
const showAddDiscuss = ref(false);
const showAddComment = ref(false);
const submittingAddDiscuss = ref(false);
const loadingTab = ref(false);
const loadingComment = ref(false);
const discussList = ref([]);
const discussTotal = ref([]);
const discussLikeList = ref([]);
const discussCurrent = ref(1);
const categoryList = ["面经分享", "等你来答", "求职推荐", "灌水闲聊", "我的话题", "收藏话题", "我的评论"];
const discussCurrentChange = async (page, pageSize, ListSizeKey) => {
  await getDiscussList(Number(page), Number(pageSize), categoryList[Number(ListSizeKey)-1]);
  listSize.value = {
    page: Number(page),
    pageSize: Number(pageSize),
    activeDiscussKey: ListSizeKey,
  }
}
const showEditDiscuss = ref(false);
const FshowEditDiscuss = () => {
  FormEditDiscussAdd.value = discussMoreInfo.value.reDiscuss;
  showEditDiscuss.value = true;
};
const commentList = ref([]);
const commentMyList = ref([]);
const FormDiscussAdd = ref({
  title: "",
  content: "",
  category: "灌水闲聊",
  tag: "",
});
const FormEditDiscussAdd = ref({});
const FormCommentAdd = ref({
  discussId: 0,
  content: "",
  reId: 0,
  reUserId: 0,
});

const contentSlices = (content) => {
  if (content.length > 80) {
    return content.slice(0, 80) + "...";
  }
  return content;
}
const contentTrim = (content) => {
  content = content.trim();
  return content;
}
const ChangeDiscussMore = async (index) => {
  discussMoreInfo.value = discussList.value[index];
  checkDiscussLike();
  activeKeyDiscuss.value = "8";
  activeKeyComment.value = "1";
  await getCommentList();
}
const goBackDiscussList = async () => {
  activeKeyDiscuss.value = listSize.value.activeDiscussKey;
  // activeKeyComment.value = "2";
  discussMore.value = false;
  discussMoreInfo.value = {};
  await discussCurrentChange(listSize.value.page, listSize.value.pageSize, listSize.value.activeDiscussKey);
}

// 切换话题列表
const changeDiscussList = async (key) => {
  discussCurrent.value = 1;
  const category = categoryList[Number(activeKeyDiscuss.value)-1];
  await getDiscussList(1, 4, category);
}

// 获取话题列表
const getDiscussList = async (pageNum = 1, pageSize = 4, category = "面经分享") => {
  loadingTab.value = true;
  if (category === "收藏话题"){
    const likeTotal = await getDiscussLikeList(pageNum, pageSize);
    discussList.value = discussLikeList.value;
    discussTotal.value = likeTotal;
    loadingTab.value = false;
    return;
  }else if (category === "我的评论"){
    discussMoreInfo.value = {
      reDiscuss: {
        userId: thatLocal.getItem("userId"),
      },
    };
    await getMyCommentList();
    loadingTab.value = false;
    return;
  }
  const res = (await api.discussController.getDiscussList({ pageNum, pageSize, category, isAsc : "desc", orderByColumn: "id" }))
  loadingTab.value = false;
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  discussList.value = res.rows;
  discussTotal.value = res.total;
}


// 获取评论列表
const getCommentList = async () => {
  loadingComment.value = true;
  const res = (await api.discussController.getCommentList({ discussId: discussMoreInfo.value.reDiscuss.id, isAsc : "desc", orderByColumn: "id"  }))
  loadingComment.value = false;
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  commentList.value = res.rows;
  // discussTotal.value = res.total;
}

// 新增话题
const SubmitAddDiscuss =  async () => {
  const questionKey = thatRe.msg.loading('正在发布...')
  if (FormDiscussAdd.value.title === "" || FormDiscussAdd.value.content === "") {
    thatRe.msg.destroy(false, questionKey, '请输入内容再发布吧')
    return;
  }
  submittingAddDiscuss.value = true;
  // FormDiscussAdd.value["userId"] = thatLocal.getItem("userId");
  const res = (await api.discussController.addDiscuss(FormDiscussAdd.value))
  submittingAddDiscuss.value = false;
  if (res.code !== 200) {
    thatRe.msg.destroy(false, questionKey, '发布失败: ' + res.msg)
    return;
  }
  thatRe.msg.destroy(true, questionKey, '发布成功')
  showAddDiscuss.value = false;
  await changeDiscussList()
}
// 修改话题
const SubmitEditDiscuss =  async () => {
  const questionKey = thatRe.msg.loading('正在修改...')
  if (FormEditDiscussAdd.value.title === "" || FormEditDiscussAdd.value.content === "") {
    thatRe.msg.destroy(false, questionKey, '请输入内容再提交吧')
    return;
  }
  submittingAddDiscuss.value = true;
  // FormDiscussAdd.value["userId"] = thatLocal.getItem("userId");
  const res = (await api.discussController.updateDiscuss(FormEditDiscussAdd.value))
  submittingAddDiscuss.value = false;
  if (res.code !== 200) {
    thatRe.msg.destroy(false, questionKey, '修改失败: ' + res.msg)
    return;
  }
  thatRe.msg.destroy(true, questionKey, '修改成功')
  showEditDiscuss.value = false;
  discussMoreInfo.value.reDiscuss = FormEditDiscussAdd.value;
  await changeDiscussList()
}

// 删除话题
const deleteDiscuss = async (id) => {
  const res = await api.discussController.deleteDiscuss([id])
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  thatRe.msg.success('删除成功')
  await changeDiscussList();
}

// 新增评论
const SubmitAddComment =  async () => {
  const questionKey = thatRe.msg.loading('正在评论...')
  if (FormCommentAdd.value.content === "") {
    thatRe.msg.destroy(false, questionKey, '请输入内容再评论吧')
    return;
  }
  submittingAddDiscuss.value = true;
  FormCommentAdd.value["discussId"] = discussMoreInfo.value.reDiscuss.id;
  // FormDiscussAdd.value["userId"] = thatLocal.getItem("userId");
  const res = (await api.discussController.addComment(FormCommentAdd.value))
  submittingAddDiscuss.value = false;
  if (res.code !== 200) {
    thatRe.msg.destroy(false, questionKey, '评论失败: ' + res.msg)
    return;
  }
  thatRe.msg.destroy(true, questionKey, '评论成功')
  showAddComment.value = false;
  await getCommentList();

}

// 删除评论
const deleteComment = async (id) => {
  const res = await api.discussController.deleteComment([id])
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  thatRe.msg.success('删除成功')
  await getCommentList();
}

// 获取我的评论列表
const getMyCommentList = async () => {
  const res = await api.discussController.getCommentMyList({ pageNum: 1, pageSize: 9999 ,isAsc : "desc", orderByColumn: "id"  })
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  commentMyList.value = res.rows;
}

// 收藏话题
const addDiscussLike = async () => {
  loadLike.value = true;
  const res = await api.discussController.addDiscussLike({discussId: discussMoreInfo.value.reDiscuss.id})
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  setTimeout(() => {
    loadLike.value = false;
  }, 600);
  thatRe.msg.success('收藏成功：'+ discussMoreInfo.value.reDiscuss.title)
  await getDiscussLikeList();
}

// 获取收藏列表
const getDiscussLikeList = async (pageNum = 1, pageSize = 999) => {
  const res = await api.discussController.getDiscussLikeList({pageNum, pageSize})
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  discussLikeList.value = res.rows;
  checkDiscussLike();
  return res.total;
}

// 检查是否收藏
const isLike = ref(false);
const loadLike = ref(false);
// #d6820085
const checkDiscussLike = (discussId = false) => {
  if (discussId){
    for (let i = 0; i < discussLikeList.value.length; i++) {
      if (discussId && Number(discussId) > 0 && discussLikeList.value[i]?.reDiscuss?.id === discussId) {
        return true;
      }
    }
    return false;
  }
  else{
    for (let i = 0; i < discussLikeList.value.length; i++) {
      if (discussLikeList.value[i]?.reDiscuss?.id === discussMoreInfo.value?.reDiscuss?.id) {
        isLike.value = true;
        return;
      }
    }
    isLike.value = false;
  }
}


// 取消收藏
const deleteDiscussLike = async (discussId) => {
  loadLike.value = true;
  const res = await api.discussController.deleteDiscussLike([discussId])
  if (res.code !== 200) {
    thatRe.msg.error(res.msg)
    return;
  }
  setTimeout(() => {
    loadLike.value = false;
  }, 600);
  thatRe.msg.success('取消收藏成功：'+ discussMoreInfo.value.reDiscuss.title)
  await getDiscussLikeList();
}

provide('showAddComment', showAddComment);
provide('FormCommentAdd', FormCommentAdd);
provide('discussMoreInfo', discussMoreInfo);
provide('deleteComment', deleteComment);
provide('discussMore', discussMore);
provide('discussList', discussList);
provide('discussTotal', discussTotal);
provide('discussCurrent', discussCurrent);
provide('contentSlices', contentSlices);
provide('ChangeDiscussMore', ChangeDiscussMore);
provide('discussCurrentChange', discussCurrentChange);
provide('checkDiscussLike', checkDiscussLike);
provide('loadingTab', loadingTab);
provide('discussLikeList', discussLikeList);
provide('deleteDiscuss', deleteDiscuss);

(async () => {
  await getDiscussLikeList();
  await getDiscussList();
})();
</script>
<script>
export default {
  name: "Discuss"
}
</script>
<style scoped>
.discuss-main {
  width: 100%;
  height: 100%;
}
.discuss-content{
  width: 90%;
  margin-left: 5%;
  margin-top: 1%;
  display: flex;
  flex-direction: row;
}
.discuss-content > div:first-child {
  flex: 3;
  margin: 1%;
}
.discuss-content > div:last-child {
  flex: 2;
  margin: 1%;
}
.mine {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 20%;
  margin-top: 5vh;
}

.mine-content{
  max-width: 1280px;
}
.mine-content-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.mine-content-item h4{
  position: relative;
  float: left !important;
  box-shadow: 0 0 0 1px #d9d9d9;
}

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: rgba(255, 255, 255, 0.78);
  border-radius: 8px;
  margin-left: 1vw;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}
div.discuss-content > div.discuss-pull > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div {
  border-radius: 12px 12px 12px 12px !important;
  margin: 2% !important;
}

[data-theme='compact'] #components-page-header-demo-content .example-link {
  line-height: 20px;
}
#components-page-header-demo-content .example-link-icon {
  margin-right: 8px;
}

[data-theme='compact'] #components-page-header-demo-content .example-link-icon {
  width: 20px;
  height: 20px;
}

#components-page-header-demo-content .ant-page-header-rtl .example-link {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
#components-page-header-demo-content .ant-page-header-rtl .example-link-icon {
  margin-right: 0;
  margin-left: 8px;
}
#components-page-header-demo-content .content p {
  margin-bottom: 1em;
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word;
}

.components-page-header-demo-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1rem; /* 列之间的间隔 */
}


.components-page-header-demo-content > div:hover {
  background: #f5f5f5;
  box-shadow: 0 0 10px #999;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.scale-fade-enter-active,
.scale-fade-leave-active {
  transition: all 0.5s ease;
}

.scale-fade-enter,
.scale-fade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}


pre {
  font-size: 16px; /* 设置合适的字号 */
  line-height: 1.5; /* 设置行高 */
  padding: 1rem; /* 添加内边距 */
  overflow-x: auto; /* 横向溢出时显示滚动条 */
  word-wrap: break-word; /* 长单词自动换行 */
}

.tabs-discuss-info {
  max-height: 82vh;
  overflow: hidden auto;
}

.tabs-discuss-info:hover {
  overflow: hidden auto;
}


</style>