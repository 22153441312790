<template>
  <a-spin :spinning="LoadResumeInfo" class="welcome-loading" size="large">
    <!-- TODO: 模板分类建议 -->
    <div v-if="LoadResumeInfo" class="UserInfo">
      <a-skeleton v-for="i in 10" :paragraph="{ rows: 3 }" active avatar class="UserInfo-card" style="width: 300px"/>
    </div>
    <div v-else class="UserInfo">
      <a-card v-for="data in templates" :id="data?.id + ''|| ''+Date.now()" class="UserInfo-card" hoverable
              style="width: 300px">
        <template #actions>
          <a-button key="newResume" type="text" @click="FunInfoToFileRead1(data)">
            <AuditOutlined/>
            一键制作同款
          </a-button>
          <a-button key="newResume" type="text" @click="FunDownTmp(data)">
            <DownloadOutlined/>
            下载模板
          </a-button>
<!--          <edit-outlined key="edit"/>-->
<!--          <a-dropdown arrow placement="bottom">-->
<!--            <setting-outlined key="setting"/>-->
<!--            <template #overlay>-->
<!--              <a-menu>-->
<!--                <a-menu-item>-->
<!--                  <ChromeOutlined/> &nbsp; 预览简历-->
<!--                </a-menu-item>-->
<!--                <a-menu-item>-->
<!--                  <DeleteOutlined/> &nbsp; 删除档案-->
<!--                </a-menu-item>-->
<!--                <a-menu-item>-->
<!--                  <EllipsisOutlined/> &nbsp; 高级设置-->
<!--                </a-menu-item>-->
<!--              </a-menu>-->
<!--            </template>-->
<!--          </a-dropdown>-->
        </template>
        <a-image :src="data?.tmpImgUrl || '/favicon.ico'"/>
      </a-card>
    </div>

    <!--    新建对话框-选择数据-->
    <a-modal v-model:open="VisitAdd1" :closable="false"
             :mask-closable="false" cancel-text="取消" centered ok-text="生成" style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;"
             @ok="FunInfoToFile()">
      <a-empty v-if="dataResumeData.length === 0" style="width: 300px !important;margin: 10px;">
        <template #description>
          无数据，请先创建个人简历档案
        </template>
      </a-empty>
      <a-form v-else :model="inputFileInfo">
        <a-form-item label="用户档案">
          <a-select
              :filter-option="filterOption"
              :options="dataResumeDataLabel"
              placeholder="选择简历档案"
              show-search
              style="max-width: 800px; min-width: 300px"
              @change="handleChange"
          ></a-select>
        </a-form-item>
        <a-form-item label="简历名称">
          <a-input v-model:value="inputFileInfo.fileName" placeholder="e.g. 张之之的简历"
                   style="max-width: 800px; min-width: 300px"/>
        </a-form-item>
        <a-form-item label="简历备注">
          <a-input v-model:value="inputFileInfo.remark" placeholder="e.g. 面市场营销用"
                   style="max-width: 800px; min-width: 300px"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-spin>
</template>

<script setup>
import {ref} from "vue";
import {
  ChromeOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
  SettingOutlined,
  DownloadOutlined,
  AuditOutlined
} from "@ant-design/icons-vue";
import FileUtil from "@/utils/exportDocx";
import thatLocal from "@/utils/thatLocal";
import {GlobalReceipt} from "@/utils/thatRe";

const thatRe = GlobalReceipt()
const api = require('@/api/controller');


// 模板文件数据接口相关
const LoadResumeInfo = ref(false);
const templates = ref([]);
const refTemplates = async () => {
  LoadResumeInfo.value = true;
  const fileRes = (await api.resumeController.listResumeTmp());
  if (fileRes && fileRes.rows) {
    templates.value = fileRes.rows
  } else {
    templates.value = []
  }
  const data = thatLocal.getItem('resumeData')
  dataResumeData.value = data || []
  dataResumeDataLabel.value = []
  for (let i = 0; i < dataResumeData.value.length; i++) {
    dataResumeDataLabel.value.push({
      value: i + '',
      label: (dataResumeData.value[i]?.resumeData?.user?.name + '' || '张三') + ' （' + (dataResumeData.value[i]?.remark + '' || '这个人很懒，什么都没写...') + '）',
    })
  }
  LoadResumeInfo.value = false;
}

// 模板信息操作函数
const dataResumeData = ref([]);
const dataResumeDataLabel = ref([]);
const filterOption = (input, option) => {
  return option.label.indexOf(input) >= 0;
};
const VisitAdd1 = ref(false);
const VisitAdd2 = ref(false);
const inputFileInfo = ref({
  fileName: "",
  remark: "",
  userInfo: {},
  tmpUrl: ""
})
const FunInfoToFileRead1 = (item) => {
  inputFileInfo.value.tmpUrl = item.tmpUrl
  VisitAdd1.value = true;
}
const handleChange = value => {
  inputFileInfo.value.userInfo = dataResumeData.value[Number(value)]?.resumeData || {}
};
const FunInfoToFile = async () => {
  VisitAdd1.value = false;
  LoadResumeInfo.value = true;
  await FileUtil.upload(inputFileInfo.value.fileName, inputFileInfo.value.remark, inputFileInfo.value.userInfo, inputFileInfo.value.tmpUrl)
  LoadResumeInfo.value = false;
}

// 传入地址下载模板文件
const FunDownTmp = (item) => {
  window.open(item.tmpUrl)
}

(async () => {
  await refTemplates();
})()
</script>

<script>
export default {
  name: "Templates"
}
</script>

<style scoped>
.UserInfo {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px; /* 控制间距 */
  padding: 3vw;
  justify-items: center;
}

.UserInfo-card {
  width: 300px !important;
  background: #fff;
  backdrop-filter: blur(10px);
  margin: 10px;
}

.UserInfo-card:hover {
  outline: none;
  backdrop-filter: none;
}
</style>