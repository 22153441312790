<template data-phone-cc-input="1">
  <!-- 背景 -->
  <div class="background">
    <canvas id="startrack"></canvas>
    <div class="cover"></div>
  </div>
  <div class="start-sky">
    <!-- 导航栏 -->
    <nav>
      <a class="active" href="https://resume.app.thatcoder.cn">简历说</a>
      <a class="clip"></a>
      <a href="https://blog.thatcoder.cn">钟意博客</a>
    </nav>

    <!-- 主体 -->
    <div class="main" style="">
      <!-- 封面信息 -->
      <div class="ch intro">
        <div ref="introRef" class="container">
          <div class="hello">
            <h1 id="slogan" v-html="slogan"></h1>
            <h2>
              <div class="circle"><span></span> <span></span>
                <span></span></div>
              {{ userInfo.user.name }}的在线简历
            </h2>
          </div>
        </div>
      </div>
      <!-- 关于作者 -->
      <div class="ch about">
        <div class="container">
          <h2 class="chtitle">Who is <span>{{ userInfo.user.name }}</span>？</h2>
          <div class="clear">
            <div class="introduct">
              <img :src="userInfo.avatar? userInfo.avatar.startsWith('http')? userInfo.avatar : 'https://resumes.thatcdn.cn' + userInfo.avatar : '/favicon.ico'" class="avatar" style="width: 100px;height: 100px;object-fit: cover;border-radius: 50%;">
<!--              <P>坚硬的代替了那些柔软的，清晰的取代了那些模糊的。那些滑溜溜和黏糊糊的东西，带你来到了世界的角落。</p>-->
              <p v-if="onlineInfo?.welcome" style="padding-bottom:1em;">{{onlineInfo.welcome}}</p>
              <p v-else style="padding-bottom:1em;">How lucky to meet you!</p>
              <p v-if="onlineInfo?.self">{{onlineInfo.self}}</p>
              <p v-else>你好，这里是{{ userInfo.user.name }}
<!--                <sup>thatcoder</sup>-->
                ，年轮{{ userInfo.user.age }}圈。{{ userInfo.self_introduction }}
              </p>
              <br><br>
              <p v-if="onlineInfo?.sign" style="margin-top: 1em;">{{onlineInfo.sign}}</p>
              <p v-else style="margin-top: 1em;">希望能与你在比特之海的繁星之下相见！</p>
              <div class="chatbox">
                <div class="line loading"><span></span>
                  <span></span> <span></span></div>
              </div>
            </div>

            <!-- 常驻技能 -->
            <ul v-if="onlineInfo?.skills?.length > 0" class="skill clear">
              <li v-for="skill in onlineInfo.skills">
                <p>{{ skill.name }}</p>
                <div class="progress">
                  <div :style="{'width':skill.level+'%'}"></div>
                  <span>{{ skill.desc }}</span>
                </div>
              </li>
            </ul>


          </div>
        </div>
      </div>
      <!-- 个人社交 -->
      <div class="find ch">
        <div class="container links">
          <h2 class="chtitle">{{ userInfo.user.name }}出没的<span>地方</span></h2>
          <!--          <div class="clear col-3">-->
          <!--            <a href="/" target="_blank">-->
          <!--              <div class="item">-->
          <!--                <div class="bg" style="background-color:#28a9e0"></div>-->
          <!--                <div class="inner"><span>博客</span></div>-->
          <!--              </div>-->
          <!--            </a>-->
          <!--            <a href="/" target="_blank">-->
          <!--              <div class="item">-->
          <!--                <div class="bg" style="background-color:#28a9e0"></div>-->
          <!--                <div class="inner"><span>朝晞</span></div>-->
          <!--              </div>-->
          <!--            </a>-->
          <!--            <a href="//xue.zxiyun.com" target="_blank">-->
          <!--              <div class="item">-->
          <!--                <div class="bg" style="background-color:#28a9e0"></div>-->
          <!--                <div class="inner"><span>朝晞互联</span></div>-->
          <!--              </div>-->
          <!--            </a></div>-->
          <div class="clear">
            <a v-for="social in userInfo.socials" :href="social.link" target="_blank">
              <div class="item">
                <div :style="{'background-color':matchIcon(social.link)[1]}" class="bg"></div>
                <div class="inner"><i :class="matchIcon(social.link)[0]"></i>
                  <span>{{ social.name }}</span></div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- 参与的在线项目 -->
      <div v-if="used.project && userInfo.project?.length > 0"  class="gate ch">
        <div class="container links">
          <h2 class="chtitle">{{ userInfo.user.name }}打造的<span>作品</span></h2>
          <div class="clear">
            <a v-for="project in userInfo.project" :href="project?.link ? project.link : '#'" target="_blank">
              <div class="item">
                <!--                <div class="avatar"><img src="../../assets/start-sky/images/micooz.bab73e68.jpeg"></div>-->
                <div class="inner"><h5>{{ project.name }}</h5>
                  <p>{{ project.desc }}</p></div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- 工作经历 -->
      <div v-if="used.job && userInfo.job?.length > 0" class="gate ch">
        <div class="container links">
          <h2 class="chtitle">{{ userInfo.user.name }}参与的<span>工作</span></h2>
          <div class="clear">
            <a-tabs v-model:activeKey="activeJobKey" centered tabPosition="left"  type="card">
              <a-tab-pane key="job-1" tab="简约线性">
                <div :style="customJobStyle" class="job-timeline">
                  <a-timeline style="margin-top: 4vh;margin-left: 6vw;">
                    <a-timeline-item v-for="(job,index) in userInfo.job" :key="'job-item-'+index" color="blue">
<!--                      <template #dot>🕰</template>-->
                      <p style="color: #ffffff">{{job.start}} - {{job.end}}</p>
                      <p style="color: #ffffff">
                        {{ job.name }}
                        <a-tag v-if="job?.power" color="orange" style="margin-left: 10px;padding: 2px;">{{job.power}}</a-tag>
                      </p>
                    </a-timeline-item>
                  </a-timeline>
                </div>
              </a-tab-pane>
              <a-tab-pane key="job-2" force-render tab="详情列表">
                <a-collapse v-model:activeKey="activeJobListKey" :bordered="false" accordion
                            style="background: rgba(255,255,255,0);">
                  <template #expandIcon="{ isActive }">
                    <ToolTwoTone :rotate="isActive ? 135 : 0" style="font-size: 20px"/>
                  </template>
                  <a-collapse-panel v-for="(job,index) in userInfo.job" :key="'job-item-'+index"
                                    :style="customJobStyle">
                    <template #header>
                      <p style="color: antiquewhite">{{ job.name }}</p>
                    </template>
                    <div style="color: #ffffff">
                      <p>职权定位：{{ job.power }}&nbsp;&nbsp;&nbsp;&nbsp;时间：{{ job.start }} - {{ job.end }}</p>
                      <p></p>
                      <p>项目描述：{{ job.desc }}</p>
                      <p>工作技能：{{ job.tech }}</p>
                      <div>工作职责：
                        <ul style="margin-left: 2em;">
                          <li v-for="item in job.work">{{ item }}</li>
                        </ul>
                      </div>
                    </div>
                    <template #extra>
                      <a-tag color="orange" style="padding: 3px;">{{ job.power }}</a-tag>
                      <!--                  <p style="color: antiquewhite">{{ job.power }}</p>-->
                    </template>
                  </a-collapse-panel>
                </a-collapse>
              </a-tab-pane>
              <template #renderTabBar="{ DefaultTabBar, ...props }">
                <component :is="DefaultTabBar" :style="{ opacity: 0.8, color: '#ffffff'}" v-bind="props" />
              </template>
            </a-tabs>

          </div>
        </div>
      </div>

      <!-- 教育经历 -->
      <div v-if="used.education && userInfo.education?.length > 0" class="gate ch">
        <div class="container links">
          <h2 class="chtitle">{{ userInfo.user.name }}成长的<span>院校</span></h2>
          <div class="clear">
            <a-tabs v-model:activeKey="activeJobKey2" centered tabPosition="left"  type="card">
              <a-tab-pane key="edu-1" tab="简约线性">
                <div :style="customJobStyle" class="job-timeline">
                  <a-timeline style="margin-top: 4vh;margin-left: 6vw;">
                    <a-timeline-item v-for="(job,index) in userInfo.education" :key="'job-item-'+index" color="blue">
                      <!--                      <template #dot>🕰</template>-->
                      <p style="color: #ffffff">{{job.start}} - {{job.end}}</p>
                      <p style="color: #ffffff">
                        {{ job.name }}
                        <a-tag v-if="job?.desc" color="orange" style="margin-left: 10px;padding: 2px;">{{job.desc}}</a-tag>
                      </p>
                    </a-timeline-item>
                  </a-timeline>
                </div>
              </a-tab-pane>
              <a-tab-pane key="edu-2" force-render tab="详情列表">
                <a-collapse v-model:activeKey="activeJobListKey2" :bordered="false" accordion
                            style="background: rgba(255,255,255,0);">
                  <template #expandIcon="{ isActive }">
                    <ToolTwoTone :rotate="isActive ? 135 : 0" style="font-size: 20px"/>
                  </template>
                  <a-collapse-panel v-for="(job,index) in userInfo.education" :key="'edu-item-'+index"
                                    :style="customJobStyle">
                    <template #header>
                      <p style="color: antiquewhite">{{ job.name }}</p>
                    </template>
                    <div style="color: #ffffff">
                      <p>专业学科：{{ job.desc }}&nbsp;&nbsp;&nbsp;&nbsp;时间：{{ job.start }} - {{ job.end }}</p>
                      <p></p>
                      <p>辅修课程：{{ job.tech }}</p>
                      <p>取得成绩：{{ job.link }}</p>
                      <div>校园经历：
                        <ul style="margin-left: 2em;">
                          <li v-for="item in job.work">{{ item }}</li>
                        </ul>
                      </div>
                    </div>
                    <template #extra>
                      <a-tag color="orange" style="padding: 3px;">{{ job.power }}</a-tag>
                      <!--                  <p style="color: antiquewhite">{{ job.power }}</p>-->
                    </template>
                  </a-collapse-panel>
                </a-collapse>
              </a-tab-pane>
              <template #renderTabBar="{ DefaultTabBar, ...props }">
                <component :is="DefaultTabBar" :style="{ opacity: 0.8, color: '#ffffff'}" v-bind="props" />
              </template>
            </a-tabs>

          </div>
        </div>
      </div>

      <!-- 联系方式 -->

      <!-- 底部 -->
      <div class="footer ch">
        <div class="container">
          <h3 v-if="onlineInfo?.message">{{onlineInfo.message}}</h3>
          <h3 v-else>如果对{{ userInfo.user.name }}感兴趣, 不妨联系TA吧!</h3>
          <p>如果你也想制作一份在线简历, 不妨点击下面的按钮, 简历说将为你免费提供帮助!</p>
          <a-button href="https://resume.app.thatcoder.cn/" size="small" style="background: #474444;color: #939090;margin: 16px"
                    target="_blank" type="primary"> 👉 制作简历
          </a-button>
          <p class="c">
            <a href="https://blog.thatcoder.cn">Say Resume @ 钟意 </a>
          </p>
        </div>
      </div>

    </div>

  </div>
</template>
<script setup>
import {onMounted, onUpdated, ref, watch, watchEffect} from "vue";
import {ToolTwoTone} from '@ant-design/icons-vue';
import api from "@/api/controller";
import {ClockCircleOutlined} from '@ant-design/icons-vue';
import {GlobalReceipt} from "@/utils/thatRe";
import router from "@/router";

const thatRe = GlobalReceipt()
// 打印路由的地址ID /xxx/xxx/view/{id}


const getResumeInfo =async () => {
  let getData = {
    reId: "1314",
    userName: ""
  }
  const id = window.location.href.split('/').pop()
  if (id.match(/^\d+$/)) {
    if (window.location.href.includes("online")){
      getData.reId = ""+id
    }
    else {
      window.location.href = window.location.origin + "/online/" + id
    }
  }else{
    getData.userName = id;
  }
  const res = await api.resumeController.onlineResume(getData)
  if (res.code === 200){
    userInfo.value = JSON.parse(res.data)
    used.value = JSON.parse(res.used)
    if (res.config){
      onlineInfo.value.welcome = res.config.onlineWelcome.length > 0 ? res.config.onlineWelcome : false;
      onlineInfo.value.message = res.config.onlineMessage.length > 0 ? res.config.onlineMessage : false;
      onlineInfo.value.self = res.config.onlineSelf.length > 0 ? res.config.onlineSelf : false;
      const s = JSON.parse(res.config.onlineMore)
      onlineInfo.value.skills = s.skills.length > 0 ? s.skills : [];
      onlineInfo.value.sign = s.sign.length > 0 ? s.sign : false;
      slogans.value = s.signs.length > 0 ? s.signs : slogans.value;
    }
  }else {
    await router.push({path: "/404"})
  }
}
getResumeInfo()

const activeJobListKey = ref('job-item-0')
const activeJobKey = ref('job-1');
const activeJobListKey2 = ref('edu-item-0')
const activeJobKey2 = ref('edu-1');
const customJobStyle =
    'background: rgb(247 247 247 / 20%);border-radius: 8px;margin-bottom: 24px;border: 0;overflow: hidden;backdrop-filter: blur(5px);';
let config=ref({
  welcome: false,
  message: false,
  self: false,
  skills: false,
  signs: false,
})
let used = ref({
  public: false,
  visitNum: 0,
  job: true,
  project: true,
  education: true,})
let userInfo = ref({
  avatar: null,
  // 个人信息
  user: {
    name: '钟意',
    age: 24,
    mz: '',
    gender: '',
    education: '',
    profession: '',
    politics: '',
    hometown: '',
    phone: '',
    email: '',
    address: '',
    birthday: '',
  },

  // 技能
  skills: [
    "熟悉Java后端开发与java基础知识，如常用包、集合框架(ArrayList、HashMap、HashSet等)、反射等，了解常见的数据结构及算法，熟悉常见的设计模式，如单例模式、工厂模式、适配器模式等。",
    "熟悉Spring Cloud中的核心组件，包括Nacos、Seata、Open Feign、Gateway，了解Nacos的服务注册和服务发现机制。熟练掌握SpringMVC、MyBatis、My Batis Plus等主流开发框架，深谙Spring的IOC和AOP设计理念。了解CI/CD管道流程与简单使用Jenkins工具。",
    "熟悉前端开发与多种预处理技术如Vue、Webpack、Node、Bun、Next、TypeScript、HTML三件套、Element UI、Arco Design、Ant Design、微信小程序等，了解多种渲染方式如BSR、SSG、SSR。",
    "熟悉使用Git版本控制，积极参与开源项目维护, 有良好的编码规范, 能较好的协作开发。",
    "掌握Apache、NginX、Tomcat等Web服务器和应用服务器的使用，了解负载均衡的配置。",
    "掌握Docker容器化技术及多年Linux系统的操作经验。",
    "具备基本的UI设计理解，能够简单运用即时设计工具，与产品经理有相关对接合作。",
    "具备Python的基本知识，积累了Python爬虫项目的实际经验。"
  ],

  // 项目经历
  project: [
    {
      name: '个人博客',
      start: '2020-01-01',
      end: '2022-01-01',
      power: '全桟开发',
      desc: '个人博客，记录学习、生活、工作等',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      work: ['个人博客，记录学习、生活、工作等'],
      link: 'https://blog.thatcoder.cn',
    }
  ],
  // 工作经历
  job: [
    {
      name: '广州华多网络科技有限公司',
      start: '2022-01-01',
      end: '2022-07-01',
      power: '前端开发工程师',
      desc: '负责公司官网、内部系统的前端开发',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      work: ['负责公司官网、内部系统的前端开发'],
      link: 'https://www.huawei.com/cn/',
    },
    {
      name: '广州华多网络科技有限公司',
      start: '2022-01-01',
      end: '2022-07-01',
      power: '前端开发工程师',
      desc: '负责公司官网、内部系统的前端开发',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      work: ['负责公司官网、内部系统的前端开发'],
      link: 'https://www.huawei.com/cn/',
    },
    {
      name: '广州华多网络科技有限公司',
      start: '2022-01-01',
      end: '2022-07-01',
      power: '前端开发工程师',
      desc: '负责公司官网、内部系统的前端开发',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      work: ['负责公司官网、内部系统的前端开发'],
      link: 'https://www.huawei.com/cn/',
    }
  ],

  //教育经历
  education: [
    {
      name: '广东工业大学',
      start: '2016-09-01',
      end: '2020-06-30',
      power: '工科学位',
      desc: '软件工程',
      tech: '经济学',   // 辅修专业或主要课程
      work: ['软件工程'],         // 校园活动
      link: '班级前5%',   //成绩
    }
  ],


  // 语言能力
  languages: [
    {
      name: '英语',
      level: 'CET-6',
    }
  ],

  // 兴趣爱好
  hobbies: [
    '看书',
    '露营',
    '摄影',
    '品茶',
  ],

  // 证书
  certificates: [
    "CET-6",
    "软考高级",
    "普通话二甲"
  ],

  // 社交信息 如github等等
  socials: [
    {
      name: "博客",
      link: 'https://blog.thatcoder.cn'
    },
    {
      name: 'GitHub',
      link: 'https://github.com/thatcoders',
    },
    {
      name: '知乎',
      link: 'https://www.zhihu.com/people/thatcoder',
    },
  ],

  // 自我评价
  self_evaluation: '热爱编程，喜欢尝试新事物，乐于分享，有良好的沟通能力，能承受一定的工作压力。',

  // 自我介绍
  self_introduction: '热爱Code，更热爱生活。steam库存过百，爱好露营时候边听曲儿品茶。正在尝试从事互联网产品/后端/全栈开发相关工作。互联网的魅力在于分享与相遇，达瓦里希！',

})

let onlineInfo = ref({
  skills: false,
  // projects: [],
  sign: false,
  welcome: false,
  message: false,
  self: false,
  signs: false,
})

/**
 * 通过链接匹配icon的选择
 * @param url 链接字符串
 * @returns {string[]} 匹配到的icon和颜色
 */
const matchIcon = (url) => {
  const iconMap = {
    github: ['icon-github', '#353535'],
    weibo: ['icon-weibo', '#e6162d'],
    qq: ['icon-qq', '#28a9e0'],
    mail: ['icon-mail', '#28a9e0'],
    bili: ['icon-bilibili', '#f9a2a8'],
    bangumi: ['icon-bookmark', '#f09199'],
    netease: ['icon-netease_music', '#f12d35'],
    instagram: ['icon-instagram', '#b600ff'],
    zhihu: ['icon-zhihu', '#0f88eb'],
    twitter: ['icon-twitter', '#28a9e0'],
  }

  if (url.indexOf('github') !== -1) {
    return iconMap.github
  } else if (url.indexOf('weibo') !== -1) {
    return iconMap.weibo
  } else if (url.indexOf('qq') !== -1) {
    return iconMap.qq
  } else if (url.indexOf('mail') !== -1) {
    return iconMap.mail
  } else if (url.indexOf('bilibili') !== -1) {
    return iconMap.bili
  } else if (url.indexOf('bangumi') !== -1) {
    return iconMap.bangumi
  } else if (url.indexOf('music.163') !== -1) {
    return iconMap.netease
  } else if (url.indexOf('instagram') !== -1) {
    return iconMap.instagram
  } else if (url.indexOf('zhihu') !== -1) {
    return iconMap.zhihu
  } else {
    return ['null', '#28a9e0']
  }
}

const slogans = ref(["希望能成为有趣的人", "给时光以生命<br>给岁月以文明", "你好，请多指教", "当你在凝视着网页的时候<br>网页也正在凝视着你", "苟……", "Lata<br>lulila<br>lulula<br>lulalila ♪♫", "搞事！搞事！搞事！", "敬畏之心！", "赞美之心！", "我很好奇！", "欢迎大家光临星象馆<br>这里有着无论何时永远不会消失<br>美丽的无穷光辉<br>满天的星星等候着大家的到来"]);
const slogan = ref('思考中...');
const randomIndex = Math.floor(Math.random() * slogans.value.length);
slogan.value = slogans.value[randomIndex];
// document.getElementById("slogan").innerHTML= slogan.value;
watchEffect(() => {
  setInterval(() => {
    const randomIndex = Math.floor(Math.random() * slogans.value.length);
    slogan.value = slogans.value[randomIndex];
    // document.getElementById("slogan").innerHTML= slogan.value;
  }, 6000); // 30000毫秒即30秒一换
});
onMounted(() => {
  window.scrollTo(0, 0); // 将页面滚动到顶部
});

// 不要动下面的代码，除非你知道你在做什么
window.Ta = {};
onMounted(() => {
  import("@/assets/start-sky/js/page.3a0791a3.js");
  import("@/assets/start-sky/js/stats.js");
});


</script>
<script>
import {onMounted} from "vue";

export default {
  name: 'StartSky'
}
</script>
<style scoped>
.start-sky {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
  background-color: #212121;
}

nav, .main {
  z-index: 3;
  color: #fff;
}

.main {
  position: absolute;
}

*, nav, .footer, .main, .background {
  padding: 0;
  margin: 0;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

body, html {
  -webkit-font-smoothing: antialiased;
  color: #fff;
  line-height: 1.5;
  min-width: 1150px;
  background-color: #202020;
  font-size: 14px;
  overflow-x: hidden
}

.intro {
  position: relative
}

.clear:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both
}

.chatbox > .line {
  display: none
}

#startrack {
  height: 140%;
  width: 100%
}

.background {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  pointer-events: none;

}

.background.fixed {
  top: -80%;
  position: fixed;

}

.background .cover {
  position: fixed;
  bottom: -80%;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: linear-gradient(0deg, #202020 50%, rgba(32, 32, 32, 0));
  transition: height 3s ease, bottom 3s ease; /* 添加过渡动画 */
}

.background.fixedCover .cover {
  position: fixed;
  bottom: 0;
  transition: height 100ms ease, bottom 100ms ease; /* 添加过渡动画 */
}

@keyframes bg {
  0% {
    transform: rotate(0deg);
    border-radius: 0
  }
  50% {
    transform: rotate(180deg);
    border-radius: 0
  }
  to {
    transform: rotate(1turn);
    border-radius: 0
  }
}

@-webkit-keyframes bg {
  0% {
    transform: rotate(0deg);
    border-radius: 0
  }
  50% {
    transform: rotate(180deg);
    border-radius: 0
  }
  to {
    transform: rotate(1turn);
    border-radius: 0
  }
}

@-ms-keyframes bg {
  0% {
    transform: rotate(0deg);
    border-radius: 0
  }
  50% {
    transform: rotate(180deg);
    border-radius: 0
  }
  to {
    transform: rotate(1turn);
    border-radius: 0
  }
}

@-moz-keyframes bg {
  0% {
    transform: rotate(0deg);
    border-radius: 0
  }
  50% {
    transform: rotate(180deg);
    border-radius: 0
  }
  to {
    transform: rotate(1turn);
    border-radius: 0
  }
}

nav {
  position: fixed;
  left: 20px;
  bottom: 0;
  z-index: 2333;
  transform: rotate(-90deg) translateZ(0);
  transform-origin: 0 0;
  transition: all .3s ease-out;
  vertical-align: middle;
  font-family: Roboto, Tahoma, Consolas, Microsoft YaHei
}

nav a.clip {
  height: 3px;
  width: 3px;
  background: #fff;
  border-radius: 100%
}

nav a {
  display: inline-block;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  opacity: .4;
  transition: all .3s;
  vertical-align: middle
}

nav a.time {
  opacity: 1
}

nav a.active, nav a:hover {
  text-decoration: underline;
  opacity: 1
}

.ch {
  width: 100%;
  padding: 80px 0;
  animation: fadedown 1s cubic-bezier(.19, 1, .22, 1);
  -webkit-animation: fadedown 1s cubic-bezier(.19, 1, .22, 1);
  -ms-animation: fadedown 1s cubic-bezier(.19, 1, .22, 1);
  -moz-animation: fadedown 1s cubic-bezier(.19, 1, .22, 1)
}

.ch h2.chtitle {
  padding-bottom: 30px;
  font-size: 26px;
  letter-spacing: .2em;
  color: hsla(0, 0%, 100%, .5)
}

.ch h2.chtitle span {
  color: #fff
}

.ch h2.chtitle:after {
  content: "";
  display: block;
  width: 10%;
  height: 5px;
  background-color: hsla(0, 0%, 100%, .5);
  margin-top: 30px
}

@keyframes fadedown {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }
  to {
    opacity: 1;
    transform: translateY(0)
  }
}

@-webkit-keyframes fadedown {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }
  to {
    opacity: 1;
    transform: translateY(0)
  }
}

@-ms-keyframes fadedown {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }
  to {
    opacity: 1;
    transform: translateY(0)
  }
}

@-moz-keyframes fadedown {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }
  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.container {
  position: relative;
  width: 1000px;
  margin: 0 auto;
  height: 100%;
  padding: 20px 0
}

.intro {
  color: #fff;
  height: 100vh;
  padding: 0
}

.intro .container {
  animation: fadedown 3.5s cubic-bezier(.19, 1, .22, 1);
  -webkit-animation: fadedown 3.5s cubic-bezier(.19, 1, .22, 1);
  -ms-animation: fadedown 3.5s cubic-bezier(.19, 1, .22, 1);
  -moz-animation: fadedown 3.5s cubic-bezier(.19, 1, .22, 1)
}

.hello {
  position: absolute;
  bottom: 20%;
  left: 0
}

.hello h1, .hello h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.5em;
  letter-spacing: .2em
}

.hello h1 {
  font-size: 42px;
  letter-spacing: .5em
}

.hello h2 {
  padding-top: .6em
}

.hello .circle {
  float: left;
  margin-right: 10px;
  letter-spacing: 0;
  display: flex;
  margin-top: 0.4em;
}

.hello .circle span {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: #fff;
  border-radius: 100%;
  margin-right: 5px
}

.hello .circle span:first-child {
  background-color: #ff493f
}

.hello .circle span:nth-child(2) {
  background-color: #f7c900
}

.hello .circle span:nth-child(3) {
  background-color: #00ff37
}

.about .introduct {
  line-height: 2em
}

.about .introduct img.avatar {
  float: right;
  width: 140px;
  margin-left: 40px;
  margin-right: 20px;
  border-radius: 100%
}

.about ul.skill {
  margin-top: 50px
}

.about ul.skill li {
  list-style: none;
  padding: 10px 0;
  width: 45%;
  float: left;
  margin-right: 5%
}

.about ul.skill li p {
  display: inline-block;
  width: 26%;
  margin-right: 3%;
  font-size: 12px
}

.about ul.skill li .progress {
  display: inline-block;
  width: 70%;
  height: 3px;
  background-color: hsla(0, 0%, 100%, .2);
  vertical-align: middle
}

.about ul.skill li .progress span {
  font-size: 12px;
  color: hsla(0, 0%, 100%, .4);
  opacity: 0;
  position: relative;
  top: -5px;
  letter-spacing: .5em;
  transition: all .3s
}

.about ul.skill li:hover .progress span {
  opacity: 1;
  top: 0
}

.about ul.skill li .progress div {
  background-color: #fff;
  height: 3px;
  position: relative
}

ul.skill li .progress div {
  animation: progressin 7s;
  -webkit-animation: progressin 7s;
  -ms-animation: progressin 7s;
  -moz-animation: progressin 7s
}

@keyframes progressin {
  0% {
    width: 0
  }
}

@-webkit-keyframes progressin {
  0% {
    width: 0
  }
}

@-ms-keyframes progressin {
  0% {
    width: 0
  }
}

@-moz-keyframes progressin {
  0% {
    width: 0
  }
}

.about ul.skill li .progress div:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -4px;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 3px solid #fff
}

.links a {
  color: #fff;
  text-decoration: none
}

.find .links .item {
  position: relative;
  width: 17.6%;
  height: 80px;
  line-height: 80px;
  margin: 10px 1.2%;
  padding: 5px 0;
  text-align: center;
  float: left;
  transition: all .2s;
  opacity: .9
}

.find .links .item:hover {
  opacity: 1;
  transform: translateY(-10px);
  -webkit-animation: index-link-active 1s cubic-bezier(.315, .605, .375, .925) forwards
}

.find .links .item .inner {
  position: relative;
  z-index: 5
}

.find .links .item .bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.8%;
  z-index: 0;
  transition: all .15s
}

.find .links .item:hover .bg {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, .28)
}

.find .links .item i {
  font-size: 20px
}

.find .links .item span {
  display: inline-block;
  width: 100px
}

.find .links .col-3 .item {
  width: 30.93%;
  margin: 10px 1.2%
}

.gate .links .item {
  margin: 5px 0;
  padding: 15px 1.5%;
  float: left;
  width: 22%;
  height: auto;
  transition: all .2s;
  opacity: .85
}

.gate .links .item.akarin {
  opacity: .58
}

.gate .links .item:hover {
  opacity: 1;
  border-radius: 5px;
  transform: translateY(-5px);
  box-shadow: 0 3px 100px rgba(216, 167, 139, 0.28);
  -webkit-animation: index-link-active 1s cubic-bezier(.315, .605, .375, .925) forwards
}

.gate .links .item .avatar {
  float: left;
  height: 60px;
  line-height: 60px;
  width: 60px;
  border-radius: 100%;
  text-align: center;
  margin-right: 15px;
  background-color: #353535;
  overflow: hidden
}

.gate .links .item .avatar i {
  font-size: 24px
}

.gate .links .item .avatar img {
  height: 60px;
  max-width: 60px;
  border-radius: 100%
}

.gate .links .item .inner {
  padding: 6px
}

.gate .links .item .inner h5 {
  font-weight: 400;
  font-size: 17px
}

.gate .links .item .inner p {
  font-size: 13px;
  color: hsla(0, 0%, 100%, .6)
}

.footer {
  text-align: center
}

.footer a {
  color: inherit;
  text-decoration: none
}

.footer a:hover {
  text-decoration: underline
}

.footer h3 {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: .8em;
  margin: 6px 0
}

.footer p {
  font-size: 12px;
  letter-spacing: 1em;
  opacity: .3
}

.footer p.c {
  margin-top: 20px;
  letter-spacing: .1em
}

.chatbox .line {
  margin: 40px 0
}

.chatbox .line p {
  margin: 10px 0
}

.chatbox .question a {
  display: inline-block;
  background-color: hsla(0, 0%, 100%, .3);
  border-radius: 10px 10px 10px 0;
  font-size: 12px;
  padding: 0 10px;
  margin-right: 10px;
  cursor: pointer;
  opacity: .8;
  transform: translateY(0);
  transition: all .2s
}

.chatbox .question a:hover {
  opacity: 1;
  transform: translateY(-2px);
  -webkit-animation: index-link-active 1s cubic-bezier(.315, .605, .375, .925) forwards
}

.chatbox .question.disable a {
  opacity: .3
}

.chatbox .question.disable a.selected {
  opacity: .9
}

.chatbox .question a.error {
  opacity: .3;
  animation: error .2s ease-in-out
}

.chatbox .loading span {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #fff;
  margin-right: 5px;
  animation: loading-point 1.4s ease-in-out infinite both
}

.chatbox .loading span:first-child {
  animation-delay: .2s
}

.chatbox .loading span:nth-child(2) {
  animation-delay: .4s
}

.chatbox .loading span:nth-child(3) {
  animation-delay: .6s
}

@keyframes loading-point {
  0% {
    opacity: .8
  }
  50% {
    opacity: .25
  }
  to {
    opacity: 1
  }
}

@keyframes error {
  0% {
    transform: translateX(0)
  }
  25% {
    transform: translateX(-2px)
  }
  50% {
    transform: translateX(0)
  }
  75% {
    transform: translateX(2px)
  }
  to {
    transform: translateX(0)
  }
}

@keyframes index-link-active {
  0% {
    transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(0)
  }
  16% {
    transform: perspective(1600px) rotateX(10deg) rotateY(5deg) translateZ(32px)
  }
  to {
    transform: perspective(1600px) rotateX(0) rotateY(0) translateZ(65px)
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 88vw;
    min-width: 60vw;
  }

  nav a {
    font-size: 18px
  }
}

@media screen and (max-width: 1400px) {
  .container {
    max-width: 80vw;
    min-width: 50vw;
  }

  nav a {
    font-size: 18px
  }
}

@media screen and (max-width: 700px) {
  body, html, .main, nav, footer, .background {
    min-width: 0
  }

  .container {
    width: auto;
    margin: 0 5%
  }

  div.el span {
    display: block;
    width: 60%;
    left: 0;
    top: 75px;
    margin: -40% auto 0
  }

  nav {
    padding: 15px 5%;
    overflow: auto;
    font-size: 13px;
    left: 0;
    top: inherit;
    bottom: 0;
    transform: rotate(0deg) translateZ(0);
    transform-origin: 0 100%;
    white-space: nowrap;
    width: 90%;
    background: -webkit-linear-gradient(180deg, transparent, rgba(0, 0, 0, .8));
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .8))
  }

  .ch {
    padding: 40px 0
  }

  .hello {
    width: 90%;
    padding: 5%;
    text-align: center;
    bottom: 50%
  }

  .hello .circle {
    float: none;
    margin-right: 0
  }

  .hello h2 {
    padding-top: 0
  }

  .hello h1, .hello h2 {
    font-weight: 400;
    font-size: 14px
  }

  .hello .circle {
    padding: 20px
  }

  .hello .circle span {
    margin: 0 5px;
    width: 10px;
    height: 10px
  }

  .hello h1 {
    font-size: 26px
  }

  .about .introduct img.avatar {
    float: none;
    margin-left: 0;
    margin-right: 0;
    width: 100px
  }

  .about .introduct, .about .skill {
    float: none;
    width: auto
  }

  .about .skill {
    padding-top: 40px
  }

  .about ul.skill li {
    width: 100%
  }

  .find .links .item, .gate .links .item {
    width: 46%;
    height: auto;
    padding: 5px 0;
    margin: 10px 2%
  }

  .find .links .item {
    height: 60px;
    line-height: 60px;
    font-size: 13px
  }

  .gate .links .item .avatar {
    height: 40px;
    line-height: 40px;
    width: 40px
  }

  .gate .links .item .avatar img {
    height: 40px;
    max-width: 40px
  }

  .gate .links .item .inner {
    padding: 0
  }

  .gate .links .item .inner h5 {
    font-size: 15px
  }

  .gate .links .item .inner h5, .gate .links .item .inner p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
  }

  .footer {
    padding-bottom: 100px
  }

  .footer h3, .footer p {
    letter-spacing: .2em
  }
}

/*# sourceMappingURL=/h.9c69ed6c.css.map */
@font-face {
  font-family: nekotora;
  src: url(./fonts/nekotora.54f9b137.eot);
  src: url(./fonts/nekotora.54f9b137.eot#iefix) format("embedded-opentype"), url(./fonts/nekotora.4ea5ebe6.woff2) format("woff2"), url(./fonts/nekotora.9106c612.woff) format("woff"), url(./fonts/nekotora.28169ea4.ttf) format("truetype"), url(../../assets/start-sky/images/nekotora.33032896.svg#nekotora) format("svg");
  font-weight: 400;
  font-style: normal
}

[class*=" icon-"]:before, [class^=icon-]:before {
  font-family: nekotora;
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.icon-search:before {
  content: "\e800"
}

.icon-info:before {
  content: "\e801"
}

.icon-comment:before {
  content: "\e802"
}

.icon-v2ex:before {
  content: "\e803"
}

.icon-zhihu:before {
  content: "\e804"
}

.icon-bilibili:before {
  content: "\e805"
}

.icon-netease_music:before {
  content: "\e806"
}

.icon-tencent_qzone:before {
  content: "\e807"
}

.icon-mail:before {
  content: "\e808"
}

.icon-bookmark:before {
  content: "\e809"
}

.icon-link:before {
  content: "\e80a"
}

.icon-twitter:before {
  content: "\f099"
}

.icon-gplus:before {
  content: "\f0d5"
}

.icon-github:before {
  content: "\f113"
}

.icon-unlink:before {
  content: "\f127"
}

.icon-instagram:before {
  content: "\f16d"
}

.icon-weibo:before {
  content: "\f18a"
}

.icon-qq:before {
  content: "\f1d6"
}

/*# sourceMappingURL=/nekotora.99cf6f8c.css.map */

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}

.job-collapse {
  background: rgb(147, 144, 144);
  color: #d9d9d9 !important;
}

.job-collapse div {
  background: rgba(255, 255, 255, 0.78) !important;
  color: #d9d9d9 !important;
}
.job-collapse span{
  color: antiquewhite !important;
}
.ant-collapse-header-text{
  color: antiquewhite !important;
}
.ant-timeline-item-content{
  color: #efeeee !important;
}
</style>