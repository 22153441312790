const {proAxios} = require('@/api/MyAxios')
const proxyObject = require("@/api/proxy/ProxyObject");

const proxyUrl = 'https://resume.thatapi.cn/dis';

const disUrlBase = {
    discuss: '/discuss',
    discussList: '/discuss/list/category',
    discussMyList: '/discuss/list/my',
    comment: '/comment',
    commentList: '/comment/list/info',
    commentMyList: '/comment/list/my',
    discussLike: '/dlike',
    discussLikeList: '/dlike/list/info'
};

const disUrl = proxyObject(disUrlBase, proxyUrl);

/**
 * 添加话题
 * @param data
 * @returns {Promise<Object|boolean>}
 */
const addDiscuss = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.discuss,
        method: 'post',
        data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

/**
 * 删除话题
 * @param ids
 * @returns {Promise<Object|boolean>}
 * @constructor
 */
const deleteDiscuss = async (ids) => {
    const DisRes = (await proAxios({
        url: disUrl.discuss + `/${ids.join(',')}`,
        method: 'DELETE',
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

/**
 * 修改话题
 * @param data
 * @returns {Promise<Object|boolean>}
 */
const updateDiscuss = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.discuss,
        method: 'put',
        data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

/**
 * 获取话题列表
 * @param data
 * @returns {Promise<Object|boolean>}
 */
const getDiscussList = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.discussList,
        method: 'get',
        params: data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

/**
 * 获取我的话题列表
 * @returns {Promise<Object|boolean>}
 */
const getDiscussMyList = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.discussMyList,
        method: 'get',
        params: data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

/**
 * 添加评论
 * @param data
 * @returns {Promise<Object|boolean>}
 */
const addComment = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.comment,
        method: 'post',
        data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

/**
 * 获取评论列表
 * @param data
 * @returns {Promise<Object|boolean>}
 */
const getCommentList = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.commentList,
        method: 'get',
        params: data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

/**
 * 删除评论
 * @param ids
 * @returns {Promise<Object|boolean>}
 * @constructor
 */
const deleteComment = async (ids) => {
    const DisRes = (await proAxios({
        url: disUrl.comment + `/${ids.join(',')}`,
        method: 'DELETE',
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

const getCommentMyList = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.commentMyList,
        method: 'get',
        params: data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

const addDiscussLike = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.discussLike,
        method: 'post',
        data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

const getDiscussLikeList = async (data) => {
    const DisRes = (await proAxios({
        url: disUrl.discussLikeList,
        method: 'get',
        params: data
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

const deleteDiscussLike = async (ids) => {
    const DisRes = (await proAxios({
        url: disUrl.discussLike + `/delete/${ids.join(',')}`,
        method: 'DELETE',
    }));
    if (DisRes.Then) {
        return DisRes.Sources
    } else {
        return false
    }
}

module.exports = {
    addDiscuss,
    deleteDiscuss,
    updateDiscuss,
    getDiscussList,
    addComment,
    getCommentList,
    deleteComment,
    getDiscussMyList,
    addDiscussLike,
    getDiscussLikeList,
    deleteDiscussLike,
    getCommentMyList
}
