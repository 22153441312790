<template>
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-between;">
    <a-spin :spinning="loadingTab" class="welcome-loading" size="large">
      <div v-if="discussList?.length > 0 ">
<!--        && discussLikeList?.length > 0-->
        <transition-group class="components-page-header-demo-content" name="scale-fade" tag="a-page-header">
          <a-page-header
              v-for="(item, index) in discussList"
              :key="index"
              :avatar="{ src: item?.userAvatar || '/favicon.ico' }"
              :sub-title="item?.nickName || item?.userName || item?.userId || '匿名用户'"
              :title="item?.reDiscuss?.title"
              class="site-page-header"
              style="background: rgb(168 164 157 / 25%); border-radius: 16px; margin: 1rem; display: flex; flex-direction: column; justify-content: space-between;"
          >
            <template v-if="item?.reDiscuss?.tag?.length > 0" #tags>
              <a-tag color="blue">{{ item?.reDiscuss.tag }}</a-tag>
            </template>
            <template #extra>
              <a-popconfirm
                  v-if="item?.userId === thatLocal.getItem('userId') && ListSizeKey === '5'"
                  cancel-text="取消"
                  ok-text="删除"
                  title="确定删除该话题？"
                  @confirm="deleteDiscuss(item?.reDiscuss?.id)"
              >
              <a-button key="2" danger  size="small">删除话题</a-button>
              </a-popconfirm>
              <a-button key="1" size="small" type="primary" @click="ChangeDiscussMore(index); discussMore = true;">查看详情</a-button>
            </template>
            <a-row class="content">
              <div style="flex: 1">
                <p v-if="item?.reDiscuss?.content?.length > 0">
                  {{ contentSlices(item?.reDiscuss.content) }}
                </p>
              </div>
            </a-row>
            <div style="display: flex; justify-content: space-between;">
              <a-tag color="#496abd38" style="color: #141414; font-size: .8rem;">
                <template #icon>
                  <AlertTwoTone />
                </template>
                {{ item?.reDiscuss?.createTime }}
              </a-tag>
              <div></div>
              <div>
                <a-tag :color="checkDiscussLike(item?.reDiscuss?.id)?'#d6820085':'#496abd38'" style="color: #141414; font-size: .8rem;">
                  <template #icon>
                    <StarTwoTone two-tone-color="#ffae00" />
                  </template>
                  {{ item?.reDiscuss?.likeNum }}
                </a-tag>
                <a-tag color="#496abd38" style="color: #141414; font-size: .8rem;">
                  <template #icon>
                    <MessageTwoTone />
                  </template>
                  {{ item?.reDiscuss?.comment }}
                </a-tag>
              </div>
            </div>
          </a-page-header>
        </transition-group>
      </div>
    </a-spin>
    <a-pagination
        v-if="discussTotal > 0"
        v-model:current="discussCurrent"
        :default-page-size="4"
        :total="discussTotal"
        @change="async (page, pageSize) => { await discussCurrentChange(page, pageSize, ListSizeKey); }"
    >
      <template #itemRender="{ type, originalElement }">
        <a v-if="type === 'prev'">上页</a>
        <a v-else-if="type === 'next'">下页</a>
        <component :is="originalElement" v-else></component>
      </template>
    </a-pagination>
  </div>
</template>

<script setup>
import {ref, computed, inject, defineProps} from 'vue';
import { AlertTwoTone, StarTwoTone, MessageTwoTone } from '@ant-design/icons-vue';
import thatLocal from "@/utils/thatLocal";

const discussMoreInfo = inject('discussMoreInfo');
const loadingTab = inject('loadingTab');
const discussMore = inject('discussMore');
const discussList = inject('discussList');
const discussTotal =inject('discussTotal');
const discussCurrent =inject('discussCurrent');
const contentSlices =inject('contentSlices');
const ChangeDiscussMore =inject('ChangeDiscussMore');
const discussCurrentChange =inject('discussCurrentChange');
const checkDiscussLike =inject('checkDiscussLike');
const discussLikeList =inject('discussLikeList');
const deleteDiscuss =inject('deleteDiscuss');

const props = defineProps({
  ListSizeKey: String,
});

</script>

<style scoped>
.components-page-header-demo-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1rem; /* 列之间的间隔 */
}


.components-page-header-demo-content > div:hover {
  background: #f5f5f5;
  box-shadow: 0 0 10px #999;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
</style>
