// NodeMyAxios.js
const axios = require('axios');
const {applyRewrite} = require("./proxy/MyAxiosProxy")
const pendingMap = new Map();
const thatLocal = require('@/utils/thatLocal')
const msgger = require("@/utils/ThatMsg")
/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
const getPendingKey = (config) => {
    let {url, method, params, data} = config;
    if (params) params = JSON.stringify(params);
    if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
    return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
const addPending = (config) => {
    const pendingKey = getPendingKey(config);
    config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
        if (!pendingMap.has(pendingKey)) {
            pendingMap.set(pendingKey, cancel);
        }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
const removePending = (config) => {
    const pendingKey = getPendingKey(config);
    if (pendingMap.has(pendingKey)) {
        const cancelToken = pendingMap.get(pendingKey);
        cancelToken(pendingKey);
        pendingMap.delete(pendingKey);
    }
}

/**
 * 一次封装axios
 * @param axiosConfig : Object "axios请求配置"
 * @param customOptions : Object "默认开启重复请求检测 例如{repeat_request_cancel: true} "
 */
const myAxios = (axiosConfig, customOptions) => {
    if (!axiosConfig.headers) {
        axiosConfig.headers = {}
    }
    if (!thatLocal.isEmpty('token') && axiosConfig.url.startsWith('https://resume.thatapi.cn')) {
        axiosConfig.headers['Nansker'] = 'Thatcoder ' + thatLocal.getItem('token')
    } else if(!axiosConfig?.token){

    }else if (thatLocal.isEmpty('token') && axiosConfig.url.startsWith('https://resume.thatapi.cn')) {
        // console.log(axiosConfig.url)
        msgger.error({
            msg: "请先登录！",
            dismissible: true,
        })
        setTimeout(() => {
            window.location.href = '/'
        }, 1500)
        // if (axiosConfig.url !== '/proxy/service/login') {
        //     return {Then: false, Sources: '请前往引导页登录！'}
        // }
    } else {

    }


    // 创建 axios 实例
    const service = axios.create({
        timeout: 60000,
        headers: axiosConfig.headers,
        changeOrigin: true,
        method: axiosConfig.method,
    });

    // 自定义配置
    let custom_options = Object.assign({
        repeat_request_cancel: true,
    }, customOptions);

    // 请求拦截器
    service.interceptors.request.use(
        config => {
            // config.url = applyRewrite(config.url)
            removePending(config);
            custom_options.repeat_request_cancel && addPending(config);
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    service.interceptors.response.use(
        response => {
            removePending(response.config);
            return response;
        },
        error => {
            error.config && removePending(error.config);
            return Promise.reject(error);
        }
    );

    return service(axiosConfig)
}

/**
 * axios封装请求
 * @param axiosConfig
 * @param requestMore
 * @returns {Promise<{res: Response, Then: boolean, Sources: Object}>}
 */
const proAxios = async (axiosConfig, requestMore = false) => {
    let Then = false, Data, request = (requestMore === undefined || requestMore === true);
    let Sources = {}
    Data = await myAxios(axiosConfig, {
        repeat_request_cancel: request
    }).then(res => {
        // console.log(res)
        if (res["status"] === 200) {
            Then = true
            return res
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.status === 404 && error.response.status === 500) {
                return "请求不存在 或 API或已失效" + "\n" + error.response.data.error
            }
        } else if (error.request) {
            return "请求不存在 或 API或已失效"
        } else {
            return "请求不存在 或 API或已失效"
        }
    })
    if (!Then) {
        msgger.error(
            {
                msg: "服务不可用, 可点击此处联系作者.",
                dismissible: true,
                func: ["click", () => {
                    window.open('mailto:thatbeta@163.com', '_blank')
                }]
            })
    } else {
        Sources = Data && Data?.data ? Data.data : {}
    }
    return {Then: Then, Sources, res: Data}
}

module.exports = {myAxios, proAxios}
