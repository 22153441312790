<template>
  <div class="welcome-bg"></div>
  <div class="welcome">
    <div class="welcome-content">
      <div class="welcome-head">
        <h1 style="display: none" class="welcome-title">简历说</h1>
        <h1 class="welcome-title">Say Resume</h1>
        <h4 class="welcome-sub-title">‍🎉 简历说，让简历制作更简单</h4>
      </div>
      <div :hidden="HiddenForm">
        <a-button :hidden="HiddenStart" class="welcome-start" size="large" @click="HiddenStartChange"
                  @mouseenter="showWelcomeWish" @mouseleave="hideWelcomeWish">
          👉 {{ thatLocal.isEmpty('token') ? '开始使用' : '前往控制台' }}
        </a-button>
      </div>
      <h4 :hidden="!HiddenUser" class="welcome-start-user" @mouseenter="showWelcomeWish" @mouseleave="hideWelcomeWish">
        欢迎回来，{{ thatLocal.getItem('nickName') || thatLocal.getItem('userName') }}！</h4>
      <div :hidden="!HiddenMore" class="welcome-start-more">
        <a-button class="welcome-start-more-item" size="large" @click="HiddenFormChange"
                  @mouseenter="showWelcomeWish" @mouseleave="hideWelcomeWish">
          <template #icon>
            <UserSwitchOutlined/>
          </template>
          登录
        </a-button>
        <a-button class="welcome-start-more-item" size="large" @click="HiddenFormChange"
                  @mouseenter="showWelcomeWish" @mouseleave="hideWelcomeWish">
          <template #icon>
            <UsergroupAddOutlined/>
          </template>
          注册
        </a-button>
        <a-button class="welcome-start-more-item" size="large" @click="testUserLogin"
                  @mouseenter="showWelcomeWish" @mouseleave="hideWelcomeWish">
          <template #icon>
            <ThunderboltOutlined/>
          </template>
          匿名
        </a-button>
      </div>
    </div>

    <!--    官网功能描述-->
    <div class="welcome-info" :hidden="HiddenForm">
      <a-alert
          class="welcome-info-item"
          message="在线编辑"
          description="填写简历信息，即可选择模板快速制作简历。"
          type="info"
          show-icon
      >
        <template #icon>
          <EditOutlined/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="求职交流"
          description="与求职者交流，分享自己的经验，提升职场竞争力。"
          type="info"
          show-icon
      >
        <template #icon>
          <CommentOutlined/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="在线分享"
          description="分享简历，让更多人看到，提升个人品牌。"
          type="info"
          show-icon
      >
        <template #icon>
          <ShareAltOutlined/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="AI加持"
          description="让简历更加专业，提升简历质量，提升编写效率。"
          type="info"
          show-icon
      >
        <template #icon>
          <RobotOutlined/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="模板工坊"
          description="互相分享的模板工坊，提供海量简历模板。"
          type="info"
          show-icon
      >
        <template #icon>
          <SkinTwoTone/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="数据托管"
          description="轻点保存，临时有事也无惧，下次打开简历仍在。"
          type="info"
          show-icon
      >
        <template #icon>
          <CoffeeOutlined/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="匿名使用"
          description="可选不注册，匿名使用，即用即走即可制作简历。"
          type="info"
          show-icon
      >
        <template #icon>
          <SafetyCertificateTwoTone/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="自由组合"
          description="根据岗位要求，自由组合简历，精准匹配不踩雷。"
          type="info"
          show-icon
      >
        <template #icon>
          <ThunderboltTwoTone/>
        </template>
      </a-alert>
      <a-alert
          class="welcome-info-item"
          message="现代设计"
          description="简历说，现代系统设计，体验更专业、简洁、舒适。"
          type="info"
          show-icon
      >
        <template #icon>
          <CrownTwoTone/>
        </template>
      </a-alert>
    </div>

    <!--    登录注册表单-->
    <a-form
        :hidden="!HiddenForm"
        layout="vertical"
        :model="formState"
        name="normal_login"
        class="welcome-form"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
    >

      <a-form-item
          label="用户名"
          name="username"
          :rules="[
              { required: true, trigger: 'blur', message: '请输入您的账号' },
              { min: 3, max: 9, message: '用户账号长度必须介于 3 和 9 之间', trigger: 'blur' }
          ]"
      >
        <a-input v-model:value="formState.username">
          <template #prefix>
            <UserOutlined class="site-form-item-icon"/>
          </template>
        </a-input>
      </a-form-item>

      <a-form-item
          label="密码"
          name="password"
          :rules="[
              { required: true, trigger: 'blur', message: '请输入您的密码' },
              { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
          ]"
      >
        <a-input-password v-model:value="formState.password">
          <template #prefix>
            <LockOutlined class="site-form-item-icon"/>
          </template>
        </a-input-password>
      </a-form-item>
      <a-spin :spinning="HiddenFormLoading" size="large" class="welcome-loading">
        <a-form-item>
          <a-form-item name="remember" no-style>
            <a-checkbox v-model:checked="formState.remember">记住密码</a-checkbox>
          </a-form-item>
          <a class="login-form-forgot" href="">忘记密码</a>
        </a-form-item>

        <a-form-item style="display: flex">
          <a-button class="login-form-button"  html-type="submit"  type="primary" @click="formState.signUp = false">
            登录
          </a-button>
          <span style="margin-left: 60px;"/>
          <a-button class="login-form-button" html-type="submit" type="primary" @click="formState.signUp = true">
            注册
          </a-button>
        </a-form-item>
      </a-spin>
    </a-form>

    <a-back-top :visibility-height="100"/>
    <img ref="welcomeWish" alt="简历说祝愿你面试成功" class="welcome-wish" src="@/assets/img/welcome/wish.png">
    <div class="welcome-footer">
      <a-divider/>
      <p>Say Resumes ©2024 Powered by
        <a-tooltip placement="topLeft" title="点击前往作者博客" color="purple" arrow-point-at-center>
          <a class="welcome-footer-link" href="https://blog.thatcoder.cn" target="_blank">钟意</a>
        </a-tooltip>
        <a-tooltip arrow-point-at-center color="purple" placement="topLeft" title="点击前往简历说国内镜像">
          <a class="welcome-footer-link" href="https://resume.app.thatcoder.cn" style="margin-left: 10px" target="_blank">🧧国内加速</a>
        </a-tooltip>
      </p>
    </div>
  </div>
</template>

<script setup>
import {reactive, computed, onMounted, ref} from 'vue';
import {
  UserOutlined,
  LockOutlined,
  CoffeeOutlined,
  EditOutlined,
  CommentOutlined,
  ShareAltOutlined,
  RobotOutlined,
  SkinTwoTone,
  UserSwitchOutlined,
  ThunderboltOutlined,
  UsergroupAddOutlined,
  SafetyCertificateTwoTone,
  ThunderboltTwoTone,
  CrownTwoTone
} from '@ant-design/icons-vue';
import {GlobalReceipt} from "@/utils/thatRe";
import router from "@/router";

const thatRe = GlobalReceipt()
const api = require('@/api/controller');
const thatLocal = require('@/utils/thatLocal');

const formState = reactive({
  username: '',
  password: '',
  remember: true,
  signUp: false,
});

const onFinish = async (values) => {
  HiddenFormLoadingChange()
  setTimeout(() => {
  }, 50)
  if (formState.signUp) {
    const registerResult = await api.userController.register({
      username: formState.username,
      password: formState.password,
      confirmPassword: formState.password,
      code: "",
      uuid: "",
      roleIds: [109],
    });
    if (registerResult?.code) {
      if (registerResult.code === 200) {
        thatRe.notify.success("注册成功", "正在帮您登录...")
        setTimeout(() => {
        }, 50)
      }else {
        thatRe.notify.warning('注册失败', '用户已存在')
        HiddenFormLoadingChange()
        return;
      }
    } else {
      thatRe.notify.warning('注册失败', '服务器错误, 请页脚联系作者')
      HiddenFormLoadingChange()
      return;
    }
  }
  const loginResult = await api.userController.login(values);
  if (loginResult.code) {
    if (loginResult.code === 200) {
      thatLocal.setItem('token', loginResult.token)
      thatRe.notify.success("登录成功", "即将跳转到简历控制台")
      const userResult = await api.userController.getUserInfo();
      if (userResult.code === 200) {
        thatLocal.setItem('permissions', userResult.permissions)
        thatLocal.setItem('roles', userResult.roles)
        thatLocal.setItem('userInfo', userResult.user)
        thatLocal.setItem('userId', userResult.user.userId)
        thatLocal.setItem('userName', userResult.user.userName)
        thatLocal.setItem('nickName', userResult.user.nickName)
        thatLocal.setItem('avatar', userResult.user.avatar)
        await router.push({path: '/mine/user', query: {from: 'welcome', active: 'userInfo'}});
      }
    } else if (loginResult.code === 500) {
      thatRe.notify.warning('登录失败', '用户不存在或密码错误')
      console.log('key: ', thatRe.notify.warning('登录失败', '用户不存在或密码错误'))
    } else {
      thatRe.notify.warning('登录失败', '服务器错误, 请页脚联系作者')
    }
  }
  HiddenFormLoadingChange()
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const disabled = computed(() => {
  return !(formState.username && formState.password);
});

// Hidden开关

const welcomeWish = ref(null);
const showWelcomeWish = () => {
  welcomeWish.value.style.display = 'block';
};
const hideWelcomeWish = () => {
  welcomeWish.value.style.display = 'none';
};

// 快速开始切换登录
const HiddenStart = ref(false);
const HiddenStartChange = () => {
  if (!thatLocal.isEmpty('token')) {
    HiddenStart.value = !HiddenStart.value;
    HiddenUserChange()
    setTimeout(() => {
      router.push({path: '/mine/user', query: {from: 'welcome', active: 'userInfo'}});
    }, 1500)
  } else {
    HiddenStart.value = !HiddenStart.value;
    HiddenMoreChange()
  }
}

// 描述切换登录
const HiddenForm = ref(false);
const HiddenFormChange = () => {
  HiddenStartChange()
  HiddenForm.value = !HiddenForm.value;
}

// 用户名显示
const HiddenUser = ref(false);
const HiddenUserChange = () => {
  HiddenUser.value = !HiddenUser.value;
}

// 登录方式
const HiddenMore = ref(false);
const HiddenMoreChange = () => {
  HiddenMore.value = !HiddenMore.value;
}

// 登录loading
const HiddenFormLoading = ref(false);
const HiddenFormLoadingChange = () => {
  HiddenFormLoading.value = !HiddenFormLoading.value;
}

const testUserLogin = async () => {
  const loginResult = await api.userController.login({
    username: "testuser",
    password: "testuser",
    remember: true,
  });
  if (loginResult.code) {
    if (loginResult.code === 200) {
      thatLocal.setItem('token', loginResult.token)
      thatRe.notify.warning("匿名模式", "请注意匿名数据随时会被删除")
      thatRe.notify.warning("匿名模式", "请注意匿名数据随时会被删除")
      thatRe.notify.warning("匿名模式", "请注意匿名数据随时会被删除")
      const userResult = await api.userController.getUserInfo();
      if (userResult.code === 200) {
        thatLocal.setItem('permissions', userResult.permissions)
        thatLocal.setItem('roles', userResult.roles)
        thatLocal.setItem('userInfo', userResult.user)
        thatLocal.setItem('userId', userResult.user.userId)
        thatLocal.setItem('userName', userResult.user.userName)
        thatLocal.setItem('nickName', userResult.user.nickName)
        thatLocal.setItem('avatar', userResult.user.avatar)
        await router.push({path: '/mine/user', query: {from: 'welcome', active: 'userInfo'}});
      }
    } else if (loginResult.code === 500) {
      thatRe.notify.warning('登录失败', '用户不存在或密码错误')
      console.log('key: ', thatRe.notify.warning('登录失败', '用户不存在或密码错误'))
    } else {
      thatRe.notify.warning('登录失败', '服务器错误, 请页脚联系作者')
    }
  }
}

// style空间
onMounted(() => {
});

</script>

<style scoped>
.welcome {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  //justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
}

.welcome-bg {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  margin-left: 50%;
  transform: translateX(-50%) scale(1.5);
  width: 50%;
  height: 400px;
  opacity: 0.2;
  filter: blur(69px);
  will-change: transform;
  background: linear-gradient(135deg, rgb(114, 46, 209) 0%, rgb(22, 119, 255) 30%, rgb(245, 34, 45) 70%, rgb(19, 194, 194) 100%) 0% 0% / 200% 200%;
  animation: 10s ease 0s infinite normal none running glow;
}

@keyframes glow {
  0% {
    background-position: 0 -100%;
  }
  50% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0 -100%;
  }
}

.welcome-content {
  text-align: center;
  margin-top: 100px;
}

@media (max-width: 768px) {
  .welcome-content {
    margin-top: 80px;
  }
}

@media (max-width: 480px) {
  .welcome-content {
    margin-top: 60px;
  }
}

.welcome-title {
  pointer-events: none;
  font-weight: 700;
  font-style: italic;
  font-family: AliPuHui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 68px;
  text-shadow: rgba(235, 47, 150, 0.2) 0px 8px 20px, rgba(114, 46, 209, 0.2) 0px 8px 60px, rgba(19, 194, 194, 0.2) 0px 8px 80px;
}

.welcome-sub-title {
  margin-top: 20px;
  font-size: 24px;
  color: rgba(51, 51, 51, 0.65);
  animation: typing 3s steps(20),
  cursor-blink 0.7s step-end infinite alternate;
  border-right: 0.1em solid;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%
  }
}

@keyframes cursor-blink {
  50% {
    border-color: transparent;
  }
}

.welcome-start-user {
  z-index: 3;
  margin-top: 54px;
  font-size: 24px;
  color: rgba(47, 44, 44, 0.65);
}

.welcome-start {
  z-index: 3;
  margin-top: 40px;
  height: 50px;
  width: 180px;
  border-radius: 40px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(67, 134, 229) 0%, rgb(215, 75, 152) 100%) 0% 0% / 200% 100%;
  animation: 5s ease 0s infinite normal none running flow;
}

.welcome-start:hover {
  color: #fff;
  background: linear-gradient(90deg, rgb(22, 119, 255) 0%, rgb(126, 75, 231) 100%) 0% 0% / 200% 100%;
  animation: 5s ease 0s infinite normal none running flow;
}

.welcome-start-more-item {
  margin: 40px 10px 0;
  height: 50px;
  width: 120px;
  border-radius: 40px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(67, 134, 229) 0%, rgb(215, 75, 152) 100%) 0% 0% / 200% 100%;
  animation: 5s ease 0s infinite normal none running flow;
}

.welcome-start-more-item:hover {
  color: #fff;
  background: linear-gradient(90deg, rgb(22, 119, 255) 0%, rgb(126, 75, 231) 100%) 0% 0% / 200% 100%;
  animation: 5s ease 0s infinite normal none running flow;
}

@keyframes flow {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0 0;
  }
}

.site-form-item-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  margin-right: 8px;
}

.welcome-info {
  width: 80%;
  padding-top: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: dense;
  gap: 40px;
  perspective: 1000px; /* 添加透视效果 */
}

@media (max-width: 768px) {
  .welcome-info {
    width: 88%;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

@media (max-width: 480px) {
  .welcome-info {
    width: 88%;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
}

.welcome-info-item {
  font-family: LXGW WenKai Mono Lite, LXGW, "LXGW", system-ui, "Microsoft Yahei" !important;
  height: auto;
  font-size: large;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 24px;
}

.welcome-info-item::before {
  //transform: translateX(-50%) scale(3);
  opacity: 0.2;
  filter: blur(69px);
  will-change: transform;
  background: linear-gradient(135deg, rgb(114, 46, 209) 0%, rgb(22, 119, 255) 30%, rgb(44, 119, 143) 70%, rgb(19, 194, 194) 100%) 0% 0% / 200% 200%;
  animation: 10s ease 0s infinite normal none running glow;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.welcome-footer {
  font-size: smaller;
  color: #999;
  position: page;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
  z-index: 1;
}

.welcome-footer-link {
  color: #888;
  text-decoration: none;
}

.welcome-footer-link:hover {
  color: #999;
}

.welcome-wish {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 3vw;
  pointer-events: none;
  touch-action: none;
}

.welcome-start:hover ~ .welcome-wish,
.welcome-start-more:hover ~ .welcome-wish {
  display: unset;
}

.welcome-form {
  margin-top: 40px;
  padding: 24px;
  width: 30%;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1024px) {
  .welcome-form {
    width: 50%;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .welcome-form {
    width: 68%;
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .welcome-form {
    width: 80%;
    margin-top: 20px;
  }
}


</style>
