/**
 * 实现基础链接代理
 * @param urls - 链接对象
 * @param proxyUrl - 代理前缀
 * @returns {object}
 */
const proxyObject = function (urls, proxyUrl) {
    return new Proxy(urls, {
        get: (target, prop) => {
            return proxyUrl + target[prop];
        }
    });
}

module.exports = proxyObject;