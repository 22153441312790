<template>
  <!-- ConfigProvider可以提供全局样式配置 -->
  <a-config-provider>
    <!-- A-APP是antd封装的组件，提供了消息通知组件的全局 project/inject -->
    <a-app>
      <SpeedInsights/>
      <router-view/>
    </a-app>
  </a-config-provider>
</template>

<script setup>
import {SpeedInsights} from "@vercel/speed-insights/vue"
import {inject} from '@vercel/analytics';
inject();

const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
}
</script>

<style>

</style>
