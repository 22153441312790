<template>
  <a-spin :spinning="LoadPage" class="welcome-loading" size="large">
    <!--  <h1>简历文件</h1>-->
    <!--  <div>{{ info.user_name }}</div>-->
    <!--  <button @click="FileUtil.export(info,'001')">下载DOCX</button>-->
    <!--  <a-button @click="VisitOffice = true">打开简历编辑器</a-button>-->
    <!--  <button @click="FSaveFile('钟意简历', 'java软件', info)">上传测试</button>-->
    <!--  <FolderCard/>-->
      <a-drawer
          v-model:open="VisitOffice"
          :closable="false"
          :footer-style="{ textAlign: 'right' }"
          :maskClosable="false"
          :root-style="{ 'overflow': 'hidden'}"
          height="86vh"
          placement="bottom"
          title="编辑简历"
          @close="FunUpdateFile"
      >
        <template #extra>
          <a-button style="margin-right: 8px" @click="VisitOffice = false">取消</a-button>
          <a-button type="primary" @click="FunUpdateFile">保存</a-button>
        </template>
        <div class="officeBox" style="width: 100%;height: 100%;padding: 0;margin: 0;overflow: hidden;">
          <DocumentEditor
              id="docEditor"
              :key="documentKey"
              :config="editorConfig"
              :documentServerUrl="documentSite"
              :events_onDocumentReady="onDocumentReady"
              :style="{
                width: '100%',
                height: '100%',
                margin: '0',
                padding: '0',
            }"
          />
        </div>
      </a-drawer>

    <div v-if="LoadPage" class="UserInfo" style="height: 88vh;">
      <a-skeleton v-for="i in 10" :paragraph="{ rows: 3 }" active avatar class="UserInfo-card" style="width: 300px"/>
    </div>
    <div v-else class="UserInfo">
      <a-upload-dragger
          v-model:fileList="fileList"
          :customRequest="FunUpdateLocalFileCheck"
          :multiple="false"
          :showUploadList="false"
          class="UserInfo-card-first UserInfo-card"
          name="file"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">上传简历文件</p>
        <p class="ant-upload-hint">
          可将已有的简历上传保存,即可在线编辑.
        </p>
      </a-upload-dragger>
      <a-card v-for="fileInfo in dataFiles" class="UserInfo-card" hoverable style="width: 300px">
        <template #actions>
          <DownloadOutlined :key="'setting'+fileInfo?.id" @click="FunDownFile(fileInfo)"/>
          <edit-outlined :key="'edit'+fileInfo?.id" @click="FunEditFile(fileInfo)"/>
          <DeleteOutlined :key="'ellipsis'+fileInfo?.id" @click="fileDel=fileInfo;VisitFileDel=true"/>
        </template>
        <a-card-meta :key="fileInfo?.id || 'test'" :description="fileInfo?.remark || '测试'"
                     :title="fileInfo?.fileName || '测试'">
          <template #avatar>
            <FileWordTwoTone style="font-size: 40px"/>
          </template>
        </a-card-meta>
      </a-card>
      <!--      <FolderCard v-for="fileInfo in dataFiles" :file-info="fileInfo" />-->
    </div>

    <a-modal v-model:open="VisitFileDel"
             :closable="false" :confirm-loading="LoadFileDel" :maskClosable="false" style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;height: auto"
             @ok="FunDeleteFile()">
      <h2 style="background: rgba(240,248,255,0)">是否删除该文件?</h2>
    </a-modal>

    <a-modal v-model:open="VisitFileUp"
             :closable="false" :confirm-loading="LoadFileUp" :maskClosable="false" style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;height: auto"
             @ok="FunUpdateLocalFileOk">
      <h3 style="background: rgba(240,248,255,0)">补充上传信息</h3>
      <a-form
          :model="dataLocalFile"
          layout="horizontal"
          style="margin-top: 24px"
      >
        <a-form-item>
          <a-input v-model:value="dataLocalFile.name" placeholder=" 比如 xxx简历">
            <template #prefix>文件名称</template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input v-model:value="dataLocalFile.remark" placeholder=" 比如 软件工程师">
            <template #prefix>文件备注</template>
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-spin>
</template>

<script setup>
import FileUtil from "@/utils/exportDocx";
import FolderCard from "@/components/FolderCard.vue";
import {ref, inject, reactive} from "vue";
import {DocumentEditor} from '@onlyoffice/document-editor-vue';
import {DeleteOutlined, DownloadOutlined, EditOutlined, FileWordTwoTone, InboxOutlined} from "@ant-design/icons-vue"
import thatLocal from "@/utils/thatLocal";
import StorageSign from "@/types/storageSign";
import axios from "axios";
import {message} from "ant-design-vue";
import router from "@/router";

const api = require('@/api/controller');
const msgger = require("@/utils/ThatMsg")

const info = {
  // 个人信息
  user: {
    name: '钟意',
    age: 24,
    birthday: '1998-01-01',
    mz: '汉族',
    gender: '男',
    education: '本科',
    profession: '软件工程',
    politics: '中共党员',
    hometown: '广东广州',
    phone: '13305374721',
    email: 'thatcoder@163.com',
    address: '广东省广州市天河区',
    blog: 'https://blog.thatcoder.cn',
    avatar: 'https://blog.thatcoder.cn/favicon.ico',
  },

  // 技能
  skills: [
    "熟悉Java后端开发与java基础知识，如常用包、集合框架(ArrayList、HashMap、HashSet等)、反射等，了解常见的数据结构及算法，熟悉常见的设计模式，如单例模式、工厂模式、适配器模式等。",
    "熟悉Spring Cloud中的核心组件，包括Nacos、Seata、Open Feign、Gateway，了解Nacos的服务注册和服务发现机制。熟练掌握SpringMVC、MyBatis、My Batis Plus等主流开发框架，深谙Spring的IOC和AOP设计理念。了解CI/CD管道流程与简单使用Jenkins工具。",
    "熟悉前端开发与多种预处理技术如Vue、Webpack、Node、Bun、Next、TypeScript、HTML三件套、Element UI、Arco Design、Ant Design、微信小程序等，了解多种渲染方式如BSR、SSG、SSR。",
    "熟悉使用Git版本控制，积极参与开源项目维护, 有良好的编码规范, 能较好的协作开发。",
    "掌握Apache、NginX、Tomcat等Web服务器和应用服务器的使用，了解负载均衡的配置。",
    "掌握Docker容器化技术及多年Linux系统的操作经验。",
    "具备基本的UI设计理解，能够简单运用即时设计工具，与产品经理有相关对接合作。",
    "具备Python的基本知识，积累了Python爬虫项目的实际经验。"
  ],

  // 项目经历
  project: [
    {
      name: '个人博客',
      start: '2020-01-01',
      end: '2022-01-01',
      desc: '个人博客，记录学习、生活、工作等',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      link: 'https://blog.thatcoder.cn',
    }
  ],
  // 工作经历
  job: [
    {
      name: '广州华多网络科技有限公司',
      start: '2022-01-01',
      end: '2022-07-01',
      desc: '负责公司官网、内部系统的前端开发',
      tech: 'Vue、Nuxt、TypeScript、VueUse、Pinia、TailwindCSS、Markdown',
      link: 'https://www.huawei.com/cn/',
    }
  ],
}

const LoadPage = ref(true)

let VisitOffice = ref(false);
const FunUpdateFile = async () => {
  VisitOffice.value = false;
};
const FSaveFile = async (fileName, remark, userInfo) => {
  await FileUtil.upload(fileName, remark, userInfo, "001")
}
const dataFiles = ref([])
const refFiles = async () => {
  LoadPage.value = true
  const fileRes = await api.storageController.resume.getFiles()
  if (fileRes && fileRes.data) {
    // 过滤fileType是"img"的
    // dataFiles.value = fileRes.data
    dataFiles.value = fileRes.data.filter(item => item.fileType !== "img");
  } else {
    console.log("没有数据")
    dataFiles.value = []
  }
  LoadPage.value = false
}
const fileList = ref([]);
const modalText = ref('Content of the modal');
const dataLocalFile = reactive({
  name: "",
  remark: ""
})
const dataLocalFileStream = ref(null)
const VisitFileUp = ref(false);
const LoadFileUp = ref(false);
const VisitFileDel = ref(false);
const LoadFileDel = ref(false);
const fileDel = ref(null)
// 监听文件上传
const FunUpdateLocalFileCheck = async fileInfo => {
  if (fileInfo && fileInfo.file) {
    VisitFileUp.value = true
    const file = fileInfo.file
    modalText.value = file.name
    dataLocalFileStream.value = file
    // const upRes = await FileUtil.upload(file.name)
  }
}

// 文件上传
const FunUpdateLocalFileOk = async () => {
  LoadPage.value = true;
  LoadFileUp.value = true;
  let fileName = dataLocalFile.name === "" ? dataLocalFileStream.value.name : dataLocalFile.name;
  let remark = dataLocalFile.remark;
  const upLocalRes = await FileUtil.upload(fileName, remark, false, dataLocalFileStream.value);
  const refRes = await refFiles();
  VisitFileUp.value = false;
  LoadPage.value = false;
  LoadFileUp.value = false;
};
// const handleChange = info => {
//   const status = info.file.status;
//   if (status !== 'uploading') {
//     console.log(info.file, info.fileList);
//   }
//   if (status === 'done') {
//     message.success(`${info.file.name} file uploaded successfully.`);
//   } else if (status === 'error') {
//     message.error(`${info.file.name} file upload failed.`);
//   }
// };
// function handleDrop(e) {
//   console.log(e);
// }
const FunDownFile = async (fileInfo) => {
  msgger.success({msg: "正在下载,注意查收!"})
  const downUrl = "https://resumes.thatcdn.cn" + fileInfo.fileUri;
  // 创建新的隐藏a标签
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = downUrl;
  link.download = fileInfo.fileName || 'download.docx';
  // 附加到 body
  document.body.appendChild(link);
  // 点击 link
  link.click();
  // 清理 DOM
  document.body.removeChild(link);
};
const FunEditFile = async (fileInfo) => {
  await router.push({
    path: '/mode/edit',
    query: {from: 'folder', active: 'edit', fileUri: fileInfo.fileUri, id: fileInfo.id, fileName: fileInfo.fileName, fileType: fileInfo.fileType}
  });
  // documentUrl.value = "https://resumes.thatcdn.cn" + fileInfo.fileUri;
  // editorConfig.value.document.url = "https://resumes.thatcdn.cn" + fileInfo.fileUri
  // documentKey.value =  thatLocal.getItem("userName") + "-document-" +fileInfo.id
  // VisitOffice.value = true

}
const FunDeleteFile = async () => {
  LoadFileDel.value = true;
  let delRes = {code: 0}
  if (fileDel.value?.id){
    delRes = await api.storageController.resume.deleteFile(fileDel.value.id)
  }
  if (delRes.code === 200) {
    msgger.success({msg: "文件删除成功!"})
    await refFiles()
  } else {
    msgger.error({msg: "文件删除失败!"})
  }
  LoadFileDel.value = false;
  VisitFileDel.value = false;
}

(async () => {
  await refFiles()
})();


const documentUrl = ref();
const documentType = ref('docx');
const documentKey = ref('your-document-key');
const documentSite = 'http://office.resume.thatcdn.cn/'
const isMobile = inject('isMobile');
const onDocumentReady = () => {
  console.log('Document is ready');
};

const onDocumentStateChange = (event) => {
  if (event.data === 'onDocumentStateChange') {
    saveDocument();
  }
};

const onRequestSaveAs = (event) => {
  // 处理文档另存为的回调事件
  console.log('Document save as requested');
  // 执行文档另存为的逻辑,例如弹出保存对话框等
  // ...
};

const onRequestInsertImage = (event) => {
  // 处理插入图片的回调事件
  console.log('Insert image requested');
  // 执行插入图片的逻辑,例如打开图片选择器等
  // ...
};

const saveDocument = () => {
  axios.post('https://resume.app.thatcoder.cn/save-document', {
    documentUrl: documentUrl.value,
    documentType: documentType.value,
    documentKey: documentKey.value,
    type: isMobile.value ? 'mobile' : 'desktop',  // mobile embedded or desktop
  })
      .then(response => {
        console.log('Document saved successfully');
      })
      .catch(error => {
        console.error('Document save error:', error);
      });
};
const editorConfig = ref({
  editorConfig: {
    mode: 'edit',
    lang: 'zh',
    customization: {
      anonymous: {
        request: true,
        label: "Guest"
      },
      autosave: false,
      comments: true,
      compactHeader: true,
      compactToolbar: false,
      compatibleFeatures: true,
      customer: {
        address: "A small corner of China",
        info: "A member of the open source spirit of the Internet",
        logo: "https://resume.app.thatcoder.cn/favicon.ico",
        logoDark: "https://resume.app.thatcoder.cn/favicon.ico",
        mail: "thatcoder@163.com",
        name: "钟意",
        phone: 13305374721,
        www: "https://blog.thatcoder.cn"
      },
      forcesave: false,
      goback: {
        blank: true,
        requestClose: false,
        text: "回到原站",
        url: "https://resume.app.thatcoder.cn"
      },
      help: false,
      hideRightMenu: false,
      hideRulers: true,
      integrationMode: "embed",
      logo: {
        image: "https://resume.app.thatcoder.cn/favicon.ico",
        imageDark: "https://resume.app.thatcoder.cn/favicon.ico",
        imageEmbedded: "https://resume.app.thatcoder.cn/favicon.ico",
        url: "https://resume.app.thatcoder.cn"
      },
      macros: true,
      macrosMode: "禁用",
      mentionShare: false,
      mobileForceView: true,
      plugins: false,
      toolbarHideFileName: true,
      toolbarNoTabs: true,
      // uiTheme: "theme-dark",
      unit: "厘米",
      zoom: 100
    },
    // callbackUrl: 'https://resume.app.thatcoder.cn/onlyoffice-callback',
    callbackUrl: 'http://localhost:59001/document/callback',
  },
  document: {
    title: '论Vue如何使用OnlyOffice',
    url: documentUrl,
    fileType: documentType,
    key: documentKey,
    info: {
      favorite: false,
      folder: "" + thatLocal.getItem('userId'),
      owner: "" + thatLocal.getItem('userName'),
      sharingSettings: [
        {
          permissions: "Full Access",
          user: "" + thatLocal.getItem('userName')
        }
      ],
      uploaded: "2010-07-07 3:46 PM"
    }
  },
  events: {
    onReady: onDocumentReady,
    // onDocumentStateChange: onDocumentStateChange,
    onRequestSaveAs: onRequestSaveAs,
    // onRequestInsertImage: onRequestInsertImage,
    // 添加其他需要处理的回调事件
  },
  // documentServerUrl: 'https://office.thatcoder.cn', // 指定 ONLYOFFICE 服务器的网址
});


// 方法区
// const exportDocx = async (info) => {
//   return await ExportDocx.Template(info, '001')
// }

</script>

<script>
export default {
  name: "Folder"
}
</script>
<style scoped>
.UserInfo {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px; /* 控制间距 */
  padding: 3vw;
  justify-items: center;
}

.UserInfo-card-first {
  height: 144px !important;
}

.UserInfo-card {
  width: 300px !important;
  backdrop-filter: blur(10px);
  margin: 10px;
}

.UserInfo-card:hover {
  outline: none;
  backdrop-filter: none;
}

@media (max-width: 768px) {
  .UserInfo-card {
    width: 40vw;
  }
}

@media (max-width: 480px) {
  .UserInfo-card {
    width: 90vw;
  }
}
</style>