const {proAxios} = require('@/api/MyAxios')
const proxyObject = require("@/api/proxy/ProxyObject");

const proxyUrl = 'https://resume.thatapi.cn/storage';

const storageUrlBase = {
    sign: '/auth/sign',
    signCallback: '/auth/sign/callback',
    userFiles: '/file/user',
    delFile: '/file'
};

const storageUrl = proxyObject(storageUrlBase, proxyUrl);

const sign = {
    /**
     * 获取上传凭证
     * @param type 上传类型
     * @param suffix 文件后缀
     * @returns {Promise<Object|boolean>}
     */
    putFile: async (type, suffix) => {
        const signRes = (await proAxios({
            url: storageUrl.sign,
            method: 'GET',
            params: {
                method: 'POST',
                type,
                suffix
            }
        }));
        if (signRes.Then) {
            return signRes.Sources
        } else {
            return false
        }
    }
}

const callback = {
    /**
     * 上传回调
     * @returns {Promise<Object|boolean>}
     * @param storageSign 上传回执数据
     */
    putFile: async (storageSign) => {
        const callbackRes = (await proAxios({
            url: storageUrl.signCallback,
            method: 'POST',
            data: storageSign
        }));
        if (callbackRes.Then) {
            return callbackRes.Sources
        } else {
            return false
        }
    }
}

const resume = {
    getFiles: async () => {
        const userFilesRes = (await proAxios({
            url: storageUrl.userFiles,
            method: 'GET',
        }));
        if (userFilesRes.Then) {
            return userFilesRes.Sources
        } else {
            return false
        }
    },
    deleteFile: async (fileId) => {
        const deleteRes = await proAxios({
            url: storageUrl.delFile + `/${fileId}`,
            method: 'DELETE'
        })
        if (deleteRes.Then) {
            return deleteRes.Sources
        }else{
            return false
        }
    }
}

module.exports = storageController = {
    sign,
    callback,
    resume
}
