const {proAxios} = require('@/api/MyAxios')
const proxyObject = require("@/api/proxy/ProxyObject");

const proxyUrl = 'https://resume.thatapi.cn';

const userUrlBase = {
    login: '/login',
    getUserInfo: '/getInfo',
    putUserInfo: '/system/user/profile',
    register: '/register',
    userResumeInfo: '/api/comment/user/profile',
};

const userUrl = proxyObject(userUrlBase, proxyUrl);

const login = async (data) => {
    const loginRes = (await proAxios({
        url: userUrl.login,
        method: 'post',
        data
    }));
    if (loginRes.Then) {
        return loginRes.Sources
    } else {
        return false
    }
}

const getUserInfo = async () => {
    const loginRes = (await proAxios({
        url: userUrl.getUserInfo,
        method: 'get',
    }));
    if (loginRes.Then) {
        return loginRes.Sources
    } else {
        return false
    }
}

const putUserInfo = async (data) => {
    const putRes = (await proAxios({
        url: userUrl.putUserInfo,
        method: 'put',
        data
    }))
    if (putRes.Then) {
        return putRes.Sources
    } else {
        return false
    }
}

const register = async (data) => {
    const registerRes = (await proAxios({
        url: userUrl.register,
        method: 'post',
        token: false,
        data
    }))
    if (registerRes.Then) {
        return registerRes.Sources
    } else {
        return false
    }
}

const userResumeInfo = async (data) => {
    const resumeInfoRes = (await proAxios({
        url: userUrl.userResumeInfo,
        method: 'get',
        data
    }))
    if (resumeInfoRes.Then) {
        return resumeInfoRes.Sources
    } else {
        return false
    }
}


module.exports = userController = {
    userUrl,
    login,
    getUserInfo,
    putUserInfo,
    register,
    userResumeInfo
}
