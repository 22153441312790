import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from '@/router';
import store from '@/store';
import 'notyf/notyf.min.css';
import Antd from 'ant-design-vue';
import {createPinia} from 'pinia'
import 'ant-design-vue/dist/reset.css';
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
import ContextMenuGlobal, {
    ContextMenu,
    ContextMenuGroup,
    ContextMenuSeparator,
    ContextMenuItem
} from '@imengyu/vue3-context-menu';
import app from "@/main";

export function createMyApp() {
    const pinia = createPinia();
    // const notfy = new Notyf()
    const app = createApp(App);

    app.use(store).use(router).use(Antd).use(pinia);

    app.component('my-context-menu', ContextMenu)
        .component('my-context-menu-group', ContextMenuGroup)
        .component('my-context-menu-separator', ContextMenuSeparator)
        .component('my-context-menu-item', ContextMenuItem);

    // 重新注册挂载到 Vue 全局上的 $contextmenu 函数。
    app.config.globalProperties.$mycontextmenu = ContextMenuGlobal.showContextMenu;

    return app;
}