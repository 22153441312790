const {Notyf} = require("notyf");
const notfy = new Notyf();

const msgCfg = (msg, duration, xy, dismissible) => {
    return {
        message: msg,
        duration,
        ripple: true,
        position: {
            x: xy[0],
            y: xy[1]
        },
        dismissible
    }
}

const msgger = {
    error: ({
                msg, duration = 8000, xy = ["right", "top"], dismissible = false, func = ["click", () => {
        }]
            }) => {
        const error = notfy.error(msgCfg(msg, duration, xy, dismissible))
        error.on(func[0], func[1])
        return error
    },
    success: ({
                  msg, duration = 5000, xy = ["right", "top"], dismissible = false, func = ["click", () => {
        }]
              }) => {
        const success = notfy.success(msgCfg(msg, duration, xy, dismissible))
        success.on(func[0], func[1])
        return success
    },
}

module.exports = msgger