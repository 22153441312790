import {App} from 'ant-design-vue';
import {defineStore} from "pinia";
import {computed, ref} from "vue";

export const GlobalReceipt = defineStore('global', () => {
    let messageApi = ref();
    let api = ref();

    (() => {
        // 这里的App.useApp()方法是从ant-design-vue包中获取的，是全局的，可以直接使用
        const staticFunction = App.useApp();
        messageApi = staticFunction.message;
        api = staticFunction.notification;
    })();

    const notify = computed(() =>
        ({
            success: (title, message, placement = 'topRight', duration = 4.5, style = {}, key = String(Date.now())) => {
                api.success({
                    message: title,
                    description: message,
                    placement,
                    style,
                    duration,
                    key,
                })
                return key;
            },
            error: (title, message, placement = 'topRight', duration = 4.5, style = {}, key = String(Date.now())) => {
                api.error({
                    message: title,
                    description: message,
                    placement,
                    style,
                    duration,
                    key,
                })
                return key;
            },
            info: (title, message, placement = 'topRight', duration = 4.5, style = {}, key = String(Date.now())) => {
                api.info({
                    message: title,
                    description: message,
                    placement,
                    style,
                    duration,
                    key,
                })
                return key;
            },
            warning: (title, message, placement = 'topRight', duration = 4.5, style = {}, key = String(Date.now())) => {
                api.warning({
                    message: title,
                    description: message,
                    placement,
                    style,
                    duration,
                    key,
                })
                return key;
            },
        })
    );
    const msg = computed(() => (
        {
            success: (content, duration = 3, style = {}, key = String(Date.now())) => {
                messageApi.success({
                    content,
                    duration,
                    style,
                    key,
                }).then(r => {
                })
                return key;
            },
            error: (content, duration = 3, style = {}, key = String(Date.now())) => {
                messageApi.error({
                    content,
                    duration,
                    style,
                    key,
                }).then(r => {
                })
                return key;
            },
            info: (content, duration = 3, style = {}, key = String(Date.now())) => {
                messageApi.info({
                    content,
                    duration,
                    style,
                    key,
                }).then(r => {
                })
                return key;
            },
            warning: (content, duration = 3, style = {}, key = String(Date.now())) => {
                messageApi.warning({
                    content,
                    duration,
                    style,
                    key,
                }).then(r => {
                })
                return key;
            },
            loading: (content, duration = 0, style = {}, key = String(Date.now())) => {
                messageApi.loading({
                    content,
                    duration,
                    style,
                    key,
                }).then(r => {
                })
                return key;
            },
            /**
             * 加载回执
             * @param status true:成功, false:失败
             * @param key 回执标识
             * @param newContent 加载后的提示信息
             */
            destroy: (status, key, newContent="完成") => {
                if (status){
                    messageApi.success({
                        content: newContent,
                        key,
                    });
                }
                else{

                    messageApi.error({
                        content: newContent,
                        key,
                    });
                }
                setTimeout(() => {
                    messageApi.destroy(key);
                }, 1000);
            },
        }
    ));

    return {messageApi, api, notify, msg};
});