/* eslint-disable no-console */

import {register} from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('通知：简历说，正在注册 Service Worker')
        },
        registered() {
            console.log('通知：简历说，Service Worker 注册成功')
        },
        cached() {
            console.log('通知：简历说，Service Worker 缓存成功')
        },
        updatefound() {
            console.log('通知：简历说，发现新版本的 Service Worker')
            window.location.reload();
        },
        updated() {
            console.log('通知：简历说，Service Worker 更新成功')
            console.log('简历说发现新版本，是否刷新页面？')
            if (confirm('通知：简历说发现新版本，是否刷新页面？')) {
                window.location.reload();
            }
        },
        offline() {
            console.log('通知：简历说，离线状态')
        },
        error(error) {
            console.error('通知：简历说，Service Worker 出错', error)
        }
    })
}
