<template>
  <!--移动端菜单-->
  <a-float-button-group :style="{ right: '24px'}" shape="square">
    <a-tooltip placement="left" title="智能专家">
    <a-float-button @click="showAIChat = true">
      <template #icon>
        <RobotOutlined/>
      </template>
    </a-float-button>
    </a-tooltip>
    <a-tooltip placement="left" title="全屏编辑">
    <a-float-button v-if="RMenuCurrent[RMenuCurrent.length - 1] === 'edit'" @click="toggleFullscreen">
      <template #icon>
        <FullscreenOutlined/>
      </template>
    </a-float-button>
    </a-tooltip>
<!--    <a-float-button v-if="RMenuCurrent[RMenuCurrent.length - 1] === 'edit' && toggleFullscreenStatus" @click="toggleFullscreen">-->
<!--      <template #icon>-->
<!--        <FullscreenExitOutlined/>-->
<!--      </template>-->
<!--    </a-float-button>-->
    <a-float-button v-if="isMobile" @click="showMobileMenu = true">
      <template #icon>
        <appstore-two-tone/>
      </template>
    </a-float-button>
    <a-tooltip placement="left" title="回到顶部">
    <a-back-top :visibility-height="100"/>
    </a-tooltip>
    <a-tooltip placement="left" title="帮助文档">
      <a-float-button @click="router.push({path: '/more/help'})">
        <template #icon>
          <QuestionCircleTwoTone/>
        </template>
      </a-float-button>
    </a-tooltip>
  </a-float-button-group>
  <div class="welcome-bg"></div>
  <a-layout class="main-layout" style="height: 100vh;">
    <a-layout-header class="main-header">
      <a-flex align="center">
        <a-avatar src="/favicon.ico" style="margin: 10px" @click="router.push({path: '/'})"></a-avatar>
        <a-typography-title id="home-title" :level="4" @click="router.push({path: '/'})">简历说</a-typography-title>
        <!--PC端菜单-->
        <a-menu v-if="!isMobile" v-model:selectedKeys="RMenuCurrent" :items="CMenuItems" mode="horizontal"
                style="background: #e6e3e3;min-width: 60vw"
                @click="FMenuClick"></a-menu>
        <!-- PC用户信息 -->
        <div v-if="!isMobile" class="main-header-right">
          <a-popover arrow-point-at-center placement="bottomLeft"
                     style="display:flex;align-items:center;justify-content:center;">
            <template #title>
              <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
                <a-avatar
                    :size="48"
                    :src="thatLocal.getItem('avatar')?.length > 0? thatLocal.getItem('avatar') : '/favicon.ico'"
                    shape="square"
                ></a-avatar>
              </div>
            </template>
            <template #content>
              <div
                  style="width: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                <p>👋您好: {{
                    thatLocal.getItem('nickName')?.length > 0 ? thatLocal.getItem('nickName') : thatLocal.getItem('userName')
                  }}</p>
                <p> 信息档案: {{ CommentCount?.infoNum || 0 }} 份 </p>
                <p> 文件数量: {{ CommentCount?.storageNum || 0 }} 份 </p>
                <p> 在线简历: {{ CommentCount?.onlineNum || 0 }} 份 </p>
                <p> 话题数量: {{ CommentCount?.discussNum || 0 }} 份 </p>
                <p> 评论数量: {{ CommentCount?.commentNum || 0 }} 份 </p>
                <div>
                  <a-button type="primary" value="large" @click="router.push({path: '/mine/index'})">个人中心</a-button>
                  <a-button danger style="margin-left: 10px" value="default" @click="VisitLogout=true">退出登录
                  </a-button>
                  <a-modal v-model:open="VisitLogout" :confirm-loading="confirmLoading" cancel-text="取消" centered
                           ok-text="退出" style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;height: auto" @ok="handleOk">
                    <p>{{ modalText }}</p>
                  </a-modal>
                </div>
                <p></p>
              </div>
            </template>

            <a-avatar
                :src="thatLocal.getItem('avatar')?.length > 0? thatLocal.getItem('avatar') : '/favicon.ico'"
                style="margin: 10px"></a-avatar>
            <a-typography-text keyboard>{{
                thatLocal.getItem('nickName')?.length > 0 ? thatLocal.getItem('nickName') : thatLocal.getItem('userName')
              }}
            </a-typography-text>
          </a-popover>
        </div>
        <a-drawer width="60vw" v-if="isMobile" v-model:open="showMobileMenu" :closable="false" placement="right">
          <a-skeleton active avatar :paragraph="{ rows: 2 }"/>
          <a-divider/>
          <a-menu v-model:selectedKeys="RMenuCurrent" @click="FMenuClick" mode="inline" :items="CMenuItems"></a-menu>
        </a-drawer>
        <!--移动端菜单结束-->
        <!--AI聊天机器人-->
        <a-modal
            v-model:open="showAIChat"
            :closable="false"
            :close-icon="CloseCircleTwoTone"
            :confirm-loading="submitting"
            :mask-closable="false"
            :ok-text="submitting? '咨询小意中...' : '发送'"
            cancel-text="暂时离开"
            centered
            style="backdrop-filter: blur(15px);background: #f8f8f8bf!important;border-radius: 20px;"
            @cancel="showAIChat=false"
            @close="showAIChat=false"
            @ok="handleSubmit"
        >
          <a-list
              :data-source="comments"
              :header="`${comments.length} 条互动`"
              :style="isMobile? styleAiChatListM : styleAiChatList"
              class="AIChatList"
              item-layout="horizontal"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                <a-comment
                    :author="item.author"
                    :avatar="item.avatar"
                    :datetime="item.datetime"
                >
                  <template #content>
                    <a-typography-paragraph copyable>{{ item.content }}</a-typography-paragraph>
                  </template>
                </a-comment>
              </a-list-item>
            </template>
          </a-list>
          <a-comment style="border-radius: 6px;padding-bottom: 0;padding-top: 5px;">
            <template #avatar>
              <a-avatar :alt="thatLocal.getItem('nickName') || '您'" :src=" thatLocal.getItem('avatar') ||  'https://blog.thatcoder.cn/favicon.ico'"/>
            </template>
            <template #content>
              <a-spin :spinning="submitting" class="welcome-loading" size="large">
                <a-form-item>
                  <a-mentions v-model:value="value"
                              :options="AITips" :prefix="['/']" :rows="3" placeholder="输入 / 获取快速提升"
                              placement="top" style="background: #f8f8f8bf!important;border-radius: 20px;">
                  </a-mentions>
                </a-form-item>
              </a-spin>
            </template>
          </a-comment>

        </a-modal>
        <!--AI聊天机器人结束-->
      </a-flex>
    </a-layout-header>
    <!--    <AboutView v-show="RIsIndex" />-->
    <!--    background: #efeeee;-->
    <a-layout style="background: rgb(245 239 239 / 30%);height: 88vh;overflow-y: auto;scrollbar-color: #bbbbbbc2 #00000000;" @contextmenu.prevent="onContextMenu">
      <router-view/>
    </a-layout>
    <div class="main-footer">
      <p>Say Resumes ©2024 Powered by
        <a-tooltip placement="topLeft" title="点击前往作者博客" color="purple" arrow-point-at-center>
          <a class="welcome-footer-link" href="https://blog.thatcoder.cn" target="_blank">钟意</a>
        </a-tooltip>
        <a-tooltip arrow-point-at-center color="purple" placement="topLeft" title="点击前往简历说国内镜像">
          <a class="welcome-footer-link" href="https://resume.app.thatcoder.cn" style="margin-left: 10px" target="_blank">🧧国内加速</a>
        </a-tooltip>
      </p>
    </div>
  </a-layout>
</template>

<script setup>
import {h, onMounted, onUnmounted, ref, provide} from 'vue';
import router from "@/router";
import {
  ThunderboltTwoTone,
  BulbTwoTone,
  CodeTwoTone,
  SkinTwoTone,
  MessageTwoTone,
  FolderOpenTwoTone, ContactsTwoTone,
  AppstoreTwoTone, QuestionCircleTwoTone, RobotOutlined, CloseCircleTwoTone, FullscreenOutlined, FullscreenExitOutlined
} from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CM from "@/utils/contextmenu";
import AboutView from "@/views/AboutView.vue";
import {useRoute} from "vue-router";
import {GlobalReceipt} from "@/utils/thatRe";
import api from "@/api/controller";
import msgger from "@/utils/ThatMsg";

const thatRe = GlobalReceipt();
const thatLocal = require('@/utils/thatLocal');

const onContextMenu = (e) => {
  CM.CreateCM(e, CM.data.testItems(e))
}
// 是否首页
// const RIsIndex = ref(window.location.pathname === '/');
// const MSelect = () => {
//   console.log(window.getSelection())
// }
// 激活菜单
const RMenuCurrent = ref(['userInfo']);
// 菜单数据
const CMenuItems = ref([
  {
    key: 'userInfo',
    path: '/mine/user',
    icon: () => h(ContactsTwoTone),
    label: '简历档案',
    title: '简历档案',
  },
  {
    key: 'folder',
    path: '/mine/folder',
    icon: () => h(FolderOpenTwoTone),
    label: '简历文件',
    title: '简历文件',
  },
  // {
  //   key: 'minimalist',
  //   path: '/mode/minimalist',
  //   icon: () => h(ThunderboltTwoTone),
  //   label: '极简模式',
  //   title: '极简模式',
  // },
  // {
  //   key: 'plentiful',
  //   path: '/mode/plentiful',
  //   icon: () => h(BulbTwoTone),
  //   label: '创作模式',
  //   title: '创作模式',
  // },
  // {
  //   key: 'edit',
  //   path: '/mode/edit',
  //   icon: () => h(CodeTwoTone),
  //   label: '写作模式',
  //   title: '写作模式',
  // },
  {
    key: 'templates',
    path: '/templates',
    icon: () => h(SkinTwoTone),
    label: '模板工坊',
    title: '模板工坊',
  },
  {
    key: 'discuss',
    path: '/discuss',
    icon: () => h(MessageTwoTone),
    label: '求职讨论',
    title: '求职讨论',
  },
]);
/**
 * 路由跳转
 * @param event
 * @constructor
 */
const FMenuClick = (event) => {
  // if (!event.item.path){
  //   console.log(event.item.path)
  //   router.push({name: 'home'})
  //   // RIsIndex.value = true;
  // }
  // if (event.item.path !== router.options.history.location){
  //   console.log(event.item.path)
  //   router.push({name: event.key || 'home'})
  //   // RIsIndex.value = false;
  // }
  router.push({name: event.key || 'userInfo'})
  showMobileMenu.value = false; // 关闭移动端菜单抽屉
}
const CommentCount = ref({})
const isMobile = ref(false);
provide('isMobile', isMobile);
provide('RMenuCurrent', RMenuCurrent);
const showMobileMenu = ref(false);
const checkIsMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  isMobile.value = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}
const uRoute = useRoute()
onMounted(() => {
  const query = uRoute.query
  if (query.from === 'welcome') {
    RMenuCurrent.value = [query.active]
  }
  checkIsMobile();
  window.addEventListener('resize', checkIsMobile);
})
onUnmounted(() => {
  window.removeEventListener('resize', checkIsMobile);
})
// 聊天机器人
const showAIChat = ref(false);
const styleAiChatListM = {
  maxHeight: '40vh',

}
const styleAiChatList = {
  maxHeight: '50vh',
}
dayjs.extend(relativeTime);
const AITips = [
  {
    value: '帮我润色以下内容',
    label: '帮我润色以下内容',
  },
  {
    value: '市场营销的工作经验怎么写',
    label: '市场营销的工作经验怎么写',
  },
  {
    value: '你有什么优秀的项目经验可以分享吗',
    label: '你有什么优秀的项目经验可以分享吗',
  },
  {
    value: '好的简历是怎样的',
    label: '好的简历是怎样的',
  },
]
const comments = ref([
  {
    author: '小意',
    avatar: '/favicon.ico',
    content: '你好，我是小意，很高兴为您服务。',
    datetime: dayjs().fromNow(),
  }
]);
const submitting = ref(false);
const value = ref('');
const handleSubmit = async () => {
  const questionKey = thatRe.msg.loading('正在咨询小意...')
  if (!value.value) {
    thatRe.msg.destroy(false, questionKey, '请输入内容再询问吧')
    return;
  }
  const question = "" + value.value;
  submitting.value = true;
  comments.value = [
    {
      author: thatLocal.getItem('nickName'),
      avatar: thatLocal.getItem('avatar') || 'https://blog.thatcoder.cn/favicon.ico',
      content: value.value,
      datetime: dayjs().fromNow(),
    },
    ...comments.value,
  ];
  value.value = '';
  const chatRes = await api.aiController.chat(question)
  if (chatRes && chatRes.data) {
    chatRes.data[0].replace('\n', '<br>')
    comments.value = [
      {
        author: '小意',
        avatar: '/favicon.ico',
        content: chatRes.data[0],
        datetime: dayjs().fromNow(),
      },
      ...comments.value,
    ];
    thatRe.msg.destroy(true, questionKey, '小意给您灵感啦！')
  } else {
    value.value = question;
    thatRe.msg.destroy(false, questionKey, '对话失败，小意被太多人问住了，请稍后再试！')
  }
  submitting.value = false;
};

const VisitLogout = ref(false)
const modalText = ref('请确认是否退出登录？');
const confirmLoading = ref(false);
const handleOk = () => {
  modalText.value = '正在清空缓存...';
  confirmLoading.value = true;
  thatLocal.clear();
  msgger.success({msg: '退出登录成功'})
  msgger.success({msg: '正在前往首页...'})
  setTimeout(() => {
    confirmLoading.value = false;
    VisitLogout.value = false
    router.push({path: '/'})
  }, 2000);
};

const resizeObserver = ref(null);

const toggleFullscreenStatus = ref(false);
const toggleFullscreen = () => {
  const officeBox = document.getElementById('officeBox');
  if (!document.fullscreenElement) {
    officeBox.requestFullscreen();
    toggleFullscreenStatus.value = true;
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      toggleFullscreenStatus.value = false;
    }
  }
}

const userResumeProfile = async () => {
  const res = await api.userController.userResumeInfo()
  if (res && res?.code === 200) {
    CommentCount.value = res
  }
}


onMounted(() => {
  const query = uRoute.query
  if (query?.from) {
    RMenuCurrent.value = [query.active];
  }
  checkIsMobile();
  resizeObserver.value = new ResizeObserver(checkIsMobile);
  resizeObserver.value.observe(document.body);
});

onUnmounted(() => {
  resizeObserver.value.disconnect();
  resizeObserver.value = null;
});

(async ()=>{
  await userResumeProfile();
})()

const showHelp = ref(false);
</script>

<script>
export default {
  name: 'HomeView',
}
</script>

<style scoped>
#home-title {
  margin: 0 40px 0 0;
}

.main-layout {
  backdrop-filter: blur(15px);
  background: #e6e3e3;
  min-height: 100vh;
  padding: 0;
}

.main-header {
  background: #e6e3e3;
  height: 6vh;
  line-height: 6vh;
  padding-inline: 50px;
  padding: 0;
  z-index: 1;
}

.main-footer {
  background: rgba(66, 185, 131, 0);
  font-size: smaller;
  color: #999;
  position: page;
  bottom: 0;
  height: 6vh;
  line-height: 6vh;
  text-align: center;
  z-index: 1;
}

.welcome-footer-link {
  color: #888;
  text-decoration: none;
}

.welcome-footer-link:hover {
  color: #999;
}

.welcome-bg {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  margin-left: 50%;
  transform: translateX(-50%) scale(1.5);
  width: 50%;
  height: 400px;
  opacity: 0.06;
  filter: blur(100px);
  will-change: transform;
  background: linear-gradient(135deg, rgb(114, 46, 209) 0%, rgb(22, 119, 255) 30%, rgb(245, 34, 45) 70%, rgb(19, 194, 194) 100%) 0% 0% / 200% 200%;
  animation: 30s ease 0s infinite normal none running glow;
}

@keyframes glow {
  0% {
    background-position: 0 -100%;
  }
  50% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0 -100%;
  }
}

.AIChatList {
  overflow: hidden auto;
  scrollbar-color: #bbbbbbc2 #00000000;
}

.main-header-right {
  margin: 10px;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-items: center;
}
</style>